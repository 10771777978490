import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { MdAdd, MdDelete, MdPreview } from "react-icons/md";

import { deleteUrl, getUrl, postUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';


const SupportDoc = () => {
    const { t, i18n } = useTranslation();
    const [dialog, setDialog] = useState({
        open: false
    });
    const [fileType, setFileType] = useState({});
    const [state, setState] = useState({});
    const [files, setFiles] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    let { id } = useParams();
    let suportDocCounter = 0;

    const callApi = () => {
        getUrl(`/get-merchant-document/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setFiles(response.document);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    useEffect(() => {
        setLoading(true);
        getUrl(`/get-merchant-upload-document-type`).then(response => {
            setLoading(false);
            if (response.status) {
                setFileType(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        callApi();
        // eslint-disable-next-line
    }, []);

    const handleDialogClose = (action) => {
        if (action === 'open') {
            setDialog({ ...dialog, open: true })
        } else {
            setInputErrors({})
            setState({ ...state, fileType: '', newFile: null })
            setDialog({ ...dialog, open: false })
        }
    }

    const handleAddFile = ({ target }) => {
        let { files } = target;
        if (files[0]?.size > 5242880) {
            target.value = null;
            setState({ ...state, newFile: null })
            setInputErrors({ 'file': t('general.maxFileSize', { S: 5120 }) })
            addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
        } else {
            setInputErrors({})
            setState({ ...state, newFile: files[0] })
        }
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'fileType':
                setState({ ...state, [name]: value });
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleUploadFile = () => {
        if (state?.newFile !== null && state?.newFile) {
            setLoading(true);
            let params = {
                file_type: state.fileType,
                file: state.newFile,
            };
            const formData = new FormData();
            buildFormData(formData, params);
            postUrl(`/admin/upload/file/${id}`, formData).then(response => {
                setLoading(false);
                if (response.status === true) {
                    handleDialogClose('close')
                    addAlert('', t('success.upload'), 'success', '');
                    callApi();
                } else {
                    setInputErrors(response.errors)
                    addAlert('', t('error.contactSupport'), 'error', '');
                }

            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert("", t('general.noFileChosen'), 'error', '');
        }
    }

    const handleDeleteFile = (fileId) => {
        deleteUrl(`/admin/${id}/delete/file/${fileId}`).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.deleteSuccess'), 'success', '');
                callApi();
            } else {
                addAlert('', response?.message || t('error.deleteError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={8} md={10}>
                <Typography> {t('merchant.supporDoc')}</Typography>
            </Grid>
            <Grid item xs={4} md={2}>
                <Typography style={{ textAlign: 'center' }}> {t('merchant.action')}</Typography>
            </Grid>
            {
                _.size(files) > 0 &&
                _.map(files, (file, key) => {
                    if (file.type === "support_doc") {
                        suportDocCounter += 1;
                    }
                    return (
                        <Grid item xs={12} md={12} key={key}>
                            <Grid container spacing={2}>
                                <Grid item xs={8} md={10} >
                                    {
                                        file.name === null ?
                                        <Typography>{t('merchant.invalidDocumentName')}</Typography>
                                        :
                                        (
                                            file.type === "support_doc" ?
                                            <Typography>{file?.name?.name[i18n.language]}{" "}{suportDocCounter}</Typography>
                                            :
                                            <Typography>{file?.name?.name[i18n.language]}</Typography>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={4} md={2} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} md={6}>
                                            <Tooltip title={t('merchant.delete')}>
                                                <Button variant='contained' onClick={() => handleDeleteFile(file.id)}>
                                                    <MdDelete fontSize={20} />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Tooltip title={t('merchant.preview')}>
                                                <Link target="_blank" href={file.file_name} underline='none' >
                                                    <Button variant='contained' >
                                                        <MdPreview fontSize={20} />
                                                    </Button>
                                                </Link>

                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Grid item xs={8} md={10}>
                <Typography> {t('merchant.newDoc')}</Typography>
            </Grid>
            <Grid item xs={4} md={2}>
                <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={6} md={6}>
                        <Button variant='contained' onClick={() => handleDialogClose('open')}>
                            <MdAdd fontSize={20} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={dialog.open} onClose={() => handleDialogClose('close')} fullWidth maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={2}>
                        {
                            _.size(fileType) > 0 &&
                            <Grid item xs={12} md={6}>
                                <Box sx={{ '& > :not(style)': { width: '100%' } }} >
                                    <FormControl fullWidth error={inputErrors && inputErrors.file_type ? true : false}>
                                        <InputLabel>{t(`merchant.fileType`)}</InputLabel>
                                        <Select
                                            value={state?.fileType || ''}
                                            label={t(`merchant.fileType`)}
                                            name="fileType"
                                            onChange={handleChange}
                                            displayEmpty
                                        >
                                            <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`merchant.fileType`)} `} --- </MenuItem>
                                            {
                                                _.map(fileType, (data, key) => {
                                                    return <MenuItem key={key} value={key}>{data.name[i18n.language] ? data.name[i18n.language] : data.name.en}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        {
                                            inputErrors && inputErrors?.file_type &&
                                            <FormHelperText style={{ color: 'red' }}>{inputErrors?.file_type}</FormHelperText>
                                        }
                                    </FormControl>
                                </Box>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('merchant.file')}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.file ? inputErrors.file : ''}
                                error={inputErrors && inputErrors.file ? true : false}
                                name={`file`}
                                inputProps={{ accept: ["application/pdf", "image/*"] }}
                                onChange={handleAddFile}
                                type='file'
                                disabled={state.fileType ? false : true}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose('close')}>{t('button.cancel')}</Button>
                    <Button onClick={() => handleUploadFile()}>{t('button.upload')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default SupportDoc