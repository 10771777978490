import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';


const BasicInfo = (props) => {
    let { merchantInfo, statusList, setState, handleChange, submitData, inputErrors, userList,merchantType, mobileCodeList, userMobile } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [fieldLang, setFieldLang] = useState({ title: 'en' });

    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {
                            merchantInfo?.logo &&
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{'Logo Preview'}</Typography>
                                <img src={merchantInfo.logo.file_name} style={{ width: 300, aspectRatio: '1/1', objectFit: 'cover', borderRadius: 10 }} alt="logo" />
                            </Box>
                        }
                        <TextField
                            label={t('merchant.logo')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.new_logo ? inputErrors.new_logo : t('general.maxFileSize', { S: 5120 })}
                            error={inputErrors && inputErrors.new_logo ? true : false}
                            name={`new_logo`}
                            inputProps={{ accept: ["image/*"] }}
                            onChange={handleChange}
                            type='file'
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {
                            merchantInfo?.banner &&
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{'Banner Preview'}</Typography>
                                <img src={merchantInfo.banner.file_name} style={{ width: "100%", borderRadius: 10 }} alt="banner" />
                            </Box>
                        }
                        <TextField
                            label={t('merchant.banner')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.new_banner ? inputErrors.new_banner : t('general.maxFileSize', { S: 5120 })}
                            error={inputErrors && inputErrors.new_banner ? true : false}
                            name={`new_banner`}
                            inputProps={{ accept: ["image/*"] }}
                            onChange={handleChange}
                            type='file'
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="standard-basic"
                            label={t('merchant.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...merchantInfo, name: { ...merchantInfo.name, [fieldLang.title]: target.value } })}
                            value={merchantInfo.name && merchantInfo.name[fieldLang.title] ? merchantInfo.name[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.code`)}
                            variant="outlined"
                            value={merchantInfo?.code||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.crn`)}
                            variant="outlined"
                            value={merchantInfo?.company_register_number||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.company_register_number ? inputErrors.company_register_number : ''}
                            error={inputErrors && inputErrors.company_register_number ? true : false}
                            name="company_register_number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                            <InputLabel>{t(`user.country`)}</InputLabel>
                            <Select
                                value={userMobile.country || ''}
                                label={t(`user.country`)}
                                name="country"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0} disabled>
                                    --- {`${t('general.pleaseSelect')} ${t(`user.country`)} `} ---
                                </MenuItem>
                                {
                                    _.map(mobileCodeList.country, (data, key) => {
                                        const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                        return (
                                            <MenuItem key={key} value={data.code}>
                                                {countryName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors['country'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                            }
                            <Typography variant="caption" style={{ display: 'flex', textAlign: 'left' , color: theme.palette.noticeText.main}}>{t('general.pleaseSelectCountry')}</Typography>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                            <InputLabel>{t(`user.code`)}</InputLabel> 
                            <Select
                                value={userMobile.code || ''}
                                label={t(`user.code`)}
                                name="mobileCode"
                                displayEmpty
                            >
                                {/* <MenuItem value="">
                                    {``}
                                </MenuItem> */}
                                {_.size(mobileCodeList?.code) > 0 ? (
                                    _.map(mobileCodeList?.code, (option, key) => (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" style={{ color: '#222' }}>
                                        {``}
                                    </MenuItem>
                                )}
                            </Select>
                            {
                                inputErrors && inputErrors['mobile_code'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.mobile`)}
                            variant="outlined"
                            value={userMobile.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(statusList) > 0 && merchantInfo?.status &&
                    <Grid item xs={12} sm={6} md={6} >
                        <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                            <InputLabel id="status-label">{t(`merchant.status`)}</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                value={merchantInfo?.status||''}
                                label={t(`merchant.status`)}
                                onChange={handleChange}
                                name="status"
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                {
                                    _.map(statusList, (status, key) => {
                                        return (
                                            <MenuItem value={key} key={key}>{status[i18n.language]}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.email`)}
                            variant="outlined"
                            value={merchantInfo?.email||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.website`)}
                            variant="outlined"
                            value={merchantInfo?.website||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.website ? inputErrors.website : ''}
                            error={inputErrors && inputErrors.website ? true : false}
                            name="website"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.bn`)}
                            variant="outlined"
                            value={merchantInfo?.bussiness_nature||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bussiness_nature ? inputErrors.bussiness_nature : ''}
                            error={inputErrors && inputErrors.bussiness_nature ? true : false}
                            name="bussiness_nature"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.aboutShop`)}
                            variant="outlined"
                            value={merchantInfo?.about_shop||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.about_shop ? inputErrors.about_shop : ''}
                            error={inputErrors && inputErrors.about_shop ? true : false}
                            name="about_shop"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.productSell`)}
                            variant="outlined"
                            value={merchantInfo?.product_sell||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.product_sell ? inputErrors.product_sell : ''}
                            error={inputErrors && inputErrors.product_sell ? true : false}
                            name="product_sell"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.desc`)}
                            variant="outlined"
                            value={merchantInfo?.description||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                            error={inputErrors && inputErrors.description ? true : false}
                            name="description"
                            onChange={({ target }) => setState({ ...merchantInfo, description: target.value })}
                        />
                    </Box>
                </Grid>
                {
                    // _.size(userList) > 0 && 
                    <Grid item xs={12} sm={6} md={6} >
                        <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }} error={inputErrors && inputErrors.merchant_type ? true : false}>
                            <InputLabel id="status-label">{t(`merchant.merchantType`)}</InputLabel>
                            <Select
                                labelId="status-label"
                                value={merchantInfo?.merchant_type||'0'}
                                label={t(`merchant.merchantType`)}
                                onChange={handleChange}
                                name="merchant_type"
                                error={inputErrors && inputErrors.merchant_type ? true : false}
                            >
                                <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`merchant.merchantType`)} `} --- </MenuItem>
                                 {
                                    _.map(merchantType, (type, key) => {
                                        return (
                                            <MenuItem value={key} key={key}>{type.name?type.name[i18n.language]?type.name[i18n.language]:type.name['en']:''}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                            {
                                inputErrors && inputErrors.merchant_type &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors.merchant_type}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.username`)}
                            variant="outlined"
                            value={merchantInfo?.user_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_id ? inputErrors.user_id : ''}
                            error={inputErrors && inputErrors.user_id ? true : false}
                            name="user_id"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    // _.size(userList) > 0 && 
                    // <Grid item xs={12} sm={6} md={6} >
                    //     <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }} error={inputErrors && inputErrors.user_id ? true : false}>
                    //         <InputLabel id="status-label">{t(`merchant.ownerId`)}</InputLabel>
                    //         <Select
                    //             labelId="status-label"
                    //             value={merchantInfo?.user_id||''}
                    //             label={t(`merchant.ownerId`)}
                    //             onChange={handleChange}
                    //             name="user_id"
                    //             error={inputErrors && inputErrors.user_id ? true : false}
                    //         >
                    //             {
                    //                 _.map(userList, (user, key) => {
                    //                     return (
                    //                         <MenuItem value={user.id} key={key}>{user.username}</MenuItem>
                    //                     )
                    //                 })
                    //             }

                    //         </Select>
                    //         {
                    //             inputErrors && inputErrors.user_id &&
                    //             <FormHelperText style={{ color: 'red' }}>{inputErrors.user_id}</FormHelperText>
                    //         }
                    //     </FormControl>
                    // </Grid>
                }
                {
                    (merchantInfo?.status == 35 || merchantInfo?.status == 40) &&
                    <Grid item xs={12} sm={12} md={12}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`merchant.remark`)}
                                variant="outlined"
                                value={merchantInfo?.remark_log?.['remark'] || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.remark_log ? inputErrors.remark_log['remark'] : ''}
                                error={inputErrors && inputErrors.remark_log ? true : false}
                                name="remark_log"
                                onChange={({ target }) => setState({ ...merchantInfo, remark_log: { ...merchantInfo?.remark_log, remark: target.value, date_time: moment().format('YYYY-MM-DD, HH:mm:ss')} })}
                            />
                        </Box>
                    </Grid>
                }
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/ranks`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default BasicInfo