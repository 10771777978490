import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import _ from 'lodash';

import { getUrl, postUrl, putUrl, deleteUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { Breadcrumbs, Button, Grid, ImageList, ImageListItem, Link, Typography, LinearProgress, ImageListItemBar } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { IoAdd, IoTrashOutline , IoAttach } from "react-icons/io5";
// import ArticleIcon from '@mui/icons-material/Article';

import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';
import ImageShowModal from '@layouts/ImageShowModal';

export default function NoticeImageDetail(props) {
    const { language } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const [state, setState] = useState({
        images: [],
        newImages: [],
    });
    const [updateList, setUpdateList] = useState({
		images: state.images
	});
    const [inputErrors, setInputErrors] = useState({});
    const [uploadImages, setUploadImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState({ imageId: '', open: false });
    const [imgDialog, setImgDialog] = useState({ image: '', id: '', open: false });
    const onDrop = useCallback(acceptedFiles => {
        setUploadImages(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    //useEffect
    useEffect(() => {
        // setLoading(true);
        getApi();
        // setLoading(false);
    }, [language]);

    useEffect(() => {
        if (uploadImages) {
            if (FileReader) {
                _.map(uploadImages, (img, index) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        setDisplayImages(prevState => (
                            [...prevState, fr.result]
                        ))
                    }
                    fr.readAsDataURL(img);
                    setState(prevState => ({
                        ...prevState,
                        newImages: [...prevState.newImages, img]
                    }))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadImages]);


    //function 
    const removeImage = (index) => {
        let copyImages = [...state.newImages];
        let duplicateDisplayImages = [...displayImages];
        copyImages.splice(index, 1);
        duplicateDisplayImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            newImages: copyImages
        }))
        setDisplayImages(duplicateDisplayImages)
    };

    const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

    function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const list = reorder(
			state.images,
			result.source.index,
			result.destination.index
		);

		setState(prevState => ({
            ...prevState,
            images: list
        }));
        setUpdateList(prevState => ({ ...prevState, images: list }));
	}

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        boxShadow: '0 0 6px 0 #0002',
        backgroundColor: 'white',
        width: 312,
      
        // styles we need to apply on draggables
        ...draggableStyle,
    });
      
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });

    // const copyUrsl = ({valueData}) => (

    // )
    const copyUrl = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('success.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('error.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('success.copied'), "success");
            }).catch((error) => {
                addAlert('', t('error.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }

        // eslint-disable-next-line
    }, []);

    // url 
    const getApi = () => {
        setLoading(true);
        getUrl(`/get-notice-images`).then(response => {
            if (response.status) {
                setState(prevState => ({ ...prevState, newImages: [], images: response?.data?.listing }))
            }
        })
        .catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    const updatePriority = () => {
        setLoading(true);
		let postData = {
			data: updateList,
		};
		putUrl(`/notice-update-priority`, postData).then(response => {
			addAlert("", response.message, response.status ? 'success' : "error", '');
            setLoading(false);
			if (response.status) {
				setState(prevState => ({ ...prevState, images: response.data }))
                setDisplayImages([]);
                setUpdateList({
                    images: response.data
                });
			}
		}).catch(error => {
            setLoading(false);
			addAlert('', error.message || t('error.contactSupport'), 'error', '');
		});
	}

    const removeExistingImage = () => {
        setLoading(true)
        putUrl(`/delete-notice-image/${deleteDialog.imageId}`, {language: language}).then(response => {
            
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.deleteSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, images: data }))
                setDeleteDialog({ imageId: '', open: false })
            } else {
                setInputErrors(errors);
                addAlert('', t('error.deleteError'), 'error', '');
            }
        })
        .catch(error => {
            
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    const submitData = () => {
        const formData = new FormData();
        _.map(state, (stateItem, stateKey) => {
            switch (stateKey) {
                case 'newImages':
                    if (_.size(state.newImages) > 0) {
                        for (let i = 0; i < state.newImages.length; i++) {
                            formData.append(`images[]`, state.newImages[i]);
                        }
                    }
                    break;
                case 'images':
                    break;
                default:
                    formData.append(stateKey, stateItem);
                    break;
            }
        });
        formData.append("_method", "post");
        // formData.append("language", language);
        setLoading(true);
        setInputErrors();
        postUrl(`/upload-notice-images`, formData).then(response => {
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, newImages: [], images: data }))
                setDisplayImages([]);
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        })
        .catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    return (
        <div>
            <Grid container spacing={3} justifyContent="stretch" style={{ paddingBottom: '5%' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} style={{ paddingRight: 20 }}>
                            <Typography variant="caption">{t("general.uploadImgMinQty")}</Typography>
                            <label htmlFor="product-images-upload" className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                                <input accept="image/*" id="product-images-upload" multiple type="file" style={{ display: "none" }} onChange={({ target }) => setUploadImages(target.files)} {...getInputProps()} />
                                <IoAdd style={{ fontSize: "3rem" }} />
                                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                            </label>
                            {/* <Typography style={{ paddingTop: 10 }}>{t(`general.recommendedSize`, { width: '312px', height: '182px' })}</Typography> */}

                            {_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>}
                            {_.size(inputErrors) > 0 && (Object.keys(state.newImages).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError', {size: '2MB'})}</Typography>}
                        </Grid>
                        {
                            _.size(displayImages) > 0 ?
                                <Grid item xs={12} sm={6} md={8}>
                                    <ImageList 
                                        sx={{ transform: "translateZ(0)", width: '100%', height: 300 }}
                                        cols={4} 
                                        rowHeight={200}
                                        gap={5} 
                                    >
                                        {_.map(displayImages, (item, index) => {
                                            return (
                                                <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative" }} >
                                                    <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" />
                                                    <ImageListItemBar
                                                        sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top"
                                                        actionPosition="left"
                                                        actionIcon={
                                                            <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                                        }
                                                    />
                                                </ImageListItem>
                                            );
                                        })}
                                    </ImageList>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <div style={{ marginTop: 15, justifyContent: 'center', display: 'flex' }}>
                                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.uploadImages')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        {
                            loading
                                ? <div><LinearProgress /></div>
                                : _.size(state.images) > 0 ?
                                    <div>
                                        {/* <Typography variant="h6"><b>{t("general.displayPreview")}</b></Typography> */}

                                        <ImageList variant="masonry" cols={3} gap={8}>
                                            {state.images.map((item) => (
                                                <ImageListItem 
                                                    key={item.id}
                                                >
                                                {/* <img
                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                /> */}
                                                <img
                                                    src={`${item.file_name}?w=164&h=164&fit=crop&auto=format`}
                                                    srcSet={`${item.file_name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={`gallery_${item.id}`}
                                                    loading="lazy"
                                                    style={{ cursor: "pointer", width: '100ew' }}
                                                    onClick={() => setImgDialog({ ...imgDialog, image: item.file_name, id: item.id, open: true })}
                                                />
                                                <IoAttach size="1.7em" onClick={()=>copyUrl(item?.file_name)} className={classes.leftIcon}/>
                                                <IoTrashOutline className={classes.deleteIconStyle} size="1.7em" onClick={() => setDeleteDialog({ imageId: item.id, open: true })} />

                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                        
                                    </div>
                                    :
                                    null
                        }
                    </div>
                </Grid>
            </Grid>
            <DeleteConfirmationModal open={deleteDialog.open} closeDeleteDialog={() => setDeleteDialog({ imageId: '', open: false })} submitData={() => removeExistingImage()} />
            <ImageShowModal open={imgDialog.open} closeDialog={() => setImgDialog({ ...imgDialog , image:'' , id:'' , open:false})}  info={imgDialog} />
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: theme.palette.background.main,
        // boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed",
        borderColor: theme.palette.primary.main,
        width: "100%",
        height: 170,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    leftIcon: {
        position: "absolute",
        left: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}));