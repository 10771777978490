import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const INITIAL_Plan_Histories = {
    plan_name: '',
    bv: '',
    price: '',
    profit_limit: '',
    profit_limit_balance: '',
    trade_limit: '',
    trade_limit_balance: '',
    status: '',
    roi: {
        ROI_day: '',
        percent: '',
        received_times: '',
        total_distributions: '',
    },
    e_share_value: '',
    wallet_id: '',
    point_reward: [],
}

export default function Edit() {
    const [inputErrors, setInputErrors] = useState({});
    const [planHistories, setPlanHistories] = useState(INITIAL_Plan_Histories);
    const [statusList, setStatusList] = useState({});
    const [walletList, setWalletList] = useState([]);
    const [redeemCodeLoading, setRedeemCodeLoading] = useState(false);
    const [reassignedDialogOpen, setReassignedDialogOpen] = useState(false);

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();

    useEffect(() => {
        getUrl(`/plan-histories/${id}`).then(response => {
            const { data } = response;
            if (response.status) {
                setPlanHistories(data);
                setWalletList(response.wallets);
                setStatusList(response.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setPlanHistories({ ...planHistories, [name]: value });
    }

    const handleReassignDialogOpen = () => {
        setReassignedDialogOpen(true);
    }

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            const updatedPlanHistories = {
                ...planHistories,
                audit_remark: confirmation.note
            };
            putUrl(`/plan-histories/${id}`, updatedPlanHistories).then(response => {
                setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    const assignRedeemCode = () => {
        setRedeemCodeLoading(true);
        if (reassignedDialogOpen) {
            setReassignedDialogOpen(false);
        }
        postUrl(`/redeem-code/assign-to-ph/${id}`).then(response => {
            setRedeemCodeLoading(false);
            const { status, errors, message, redeem_code } = response;
            if (status) {
                setPlanHistories({ ...planHistories, redeem_code: redeem_code });
                addAlert('', message, 'success', '');
            } else {
                setPlanHistories({ ...planHistories, redeem_code: null });
                setInputErrors(errors);
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setRedeemCodeLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.planHistoryEdit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/plan-histories`}>
                            {t(`title.planHistory`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.planHistoryEdit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container style={{marginLeft: 10, marginBottom: 20}}>
                <Grid item xs={3}>
                    <Typography>{t(`general.ulid`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.ulid}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`user.username`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories?.plan_historable?.username}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.name`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.plan_name[i18n.language] || ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.merchant`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.merchant?.name[i18n.language] || ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`general.creator`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories?.creator?.username}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`general.createdAt`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.created_at}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.payment`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.payment_display}</Typography>
                </Grid>
                {
                    (planHistories.bv > 0) && planHistories.details?.redeem_code
                        ?
                        <>
                            <Grid item xs={3}>
                                <Typography>{t(`redeemCode.code`)}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>:
                                    {
                                        redeemCodeLoading
                                            ?
                                            <>{" " + t('button.loading')}</>
                                            :
                                            <>
                                                {planHistories.redeem_code?.code ? (' ' + planHistories.redeem_code.code) : ''}
                                                <Button variant="outlined" size="small" style={{ marginLeft: 5, padding: 0 }} onClick={planHistories.redeem_code?.code ? handleReassignDialogOpen : assignRedeemCode}>{planHistories.redeem_code?.code ? t('redeemCode.reGetCode') : t('redeemCode.getCode')}</Button>
                                            </>
                                    }
                                </Typography>
                            </Grid>
                        </>
                        :
                        null
                }
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '2%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.bv`)}
                            variant="outlined"
                            value={planHistories.bv || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.bv ? inputErrors.bv : ''}
                            error={inputErrors && inputErrors.bv ? true : false}
                            name="bv"
                            type="number"
                            className='custom-readonly-field'
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.price`)}
                            variant="outlined"
                            value={planHistories.price || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                            error={inputErrors && inputErrors.price ? true : false}
                            name="price"
                            type="number"
                            className='custom-readonly-field'
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={planHistories.profit_limit || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profit_limit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimitBalance`)}
                            variant="outlined"
                            value={planHistories.profit_limit_balance || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit_balance ? inputErrors.profit_limit_balance : ''}
                            error={inputErrors && inputErrors.profit_limit_balance ? true : false}
                            name="profit_limit_balance"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.tradeLimit`)}
                            variant="outlined"
                            value={planHistories.trade_limit || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.trade_limit ? inputErrors.trade_limit : ''}
                            error={inputErrors && inputErrors.trade_limit ? true : false}
                            name="trade_limit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.tradeLimitBalance`)}
                            variant="outlined"
                            value={planHistories.trade_limit_balance || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.trade_limit_balance ? inputErrors.trade_limit_balance : ''}
                            error={inputErrors && inputErrors.trade_limit_balance ? true : false}
                            name="trade_limit_balance"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.percent`)}
                            variant="outlined"
                            value={planHistories.roi?.percent }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.percent ? inputErrors.roi?.percent : ''}
                            error={inputErrors && inputErrors.roi?.percent ? true : false}
                            name="percent"
                            className='custom-readonly-field'
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.total_distributions`)}
                            variant="outlined"
                            value={planHistories.roi?.total_distributions }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.total_distributions ? inputErrors.roi?.total_distributions : ''}
                            error={inputErrors && inputErrors.roi?.total_distributions ? true : false}
                            name="total_distributions"
                            className='custom-readonly-field'
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.received_times`)}
                            variant="outlined"
                            value={planHistories.roi?.received_times }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.received_times ? inputErrors.roi?.received_times : ''}
                            error={inputErrors && inputErrors.roi?.received_times ? true : false}
                            name="received_times"
                            className='custom-readonly-field'
                            type="text"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.ROI_day`)}
                            variant="outlined"
                            value={planHistories.roi?.ROI_day || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.ROI_day ? inputErrors.roi?.ROI_day : ''}
                            error={inputErrors && inputErrors.roi?.ROI_day ? true : false}
                            name="ROI_day"
                            className='custom-readonly-field'
                            type="datetime"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.eShareValue`)}
                            variant="outlined"
                            value={planHistories.e_share_value||''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.e_share_value ? inputErrors.e_share_value : ''}
                            error={inputErrors && inputErrors.e_share_value ? true : false}
                            className='custom-readonly-field'
                            name="e_share_value"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: '#404040',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`planHistories.status`)}</InputLabel>
                            <Select
                                value={planHistories.status}
                                label={t(`planHistories.status`)}
                                name="status"
                                onChange={({ target }) => setPlanHistories({ ...planHistories, status: target.value })}
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                {_.map(statusList, (display, skey) => {
                                    return <MenuItem key={skey} value={skey}>{display?.[i18n.language]?display?.[i18n.language]:display?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                })}
                            </Select>
                            {
                                inputErrors && inputErrors.status
                                ?
                                <Typography variant="caption" style={{color: theme.palette.red.main}}>{inputErrors.status}</Typography>
                                : null
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`general.admin_remark`)}
                            variant="outlined"
                            value={planHistories.admin_remark || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.admin_remark ? inputErrors.admin_remark : ''}
                            error={inputErrors && inputErrors.admin_remark ? true : false}
                            name="admin_remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.gray.main }}><b>{t('plans.pointReward')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} margin={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <Typography>
                                <b>{t('plans.index')}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography>
                                <b>{t('plans.walletName')}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                <b>{t('plans.pointRewardType')}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                <b>{t('plans.pointRewardAmount')}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        _.size(walletList) > 0 && _.size(planHistories?.point_reward) > 0 ?
                            _.map(planHistories?.point_reward, (list, index) => {
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={1}>
                                            <Typography>
                                                {parseInt(index) + 1 + ' .'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography>
                                                {_.size(walletList) > 0 ? _.filter(walletList, { id: parseInt(list.wallet_id) })[0].name[i18n.language] : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                {list.type === 'fixed' ? t(`plans.typeFixed`) : list.type === 'percent' ? t(`plans.typePercent`) : 'Error!'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                {list.amount}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : <Grid container>
                                <Grid item xs={12}>
                                    <Typography><i>{t('plans.nothingShow')}</i></Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Grid>

            <Divider />

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/plan-histories`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>

            <Dialog open={reassignedDialogOpen} onClose={() => setReassignedDialogOpen(false)}>
                <DialogContent>
                    <Typography>{t('redeemCode.reGetCodeNote')}</Typography>
                </DialogContent>
                <DialogActions style={{ padding: 20 }}>
                    <Button variant="outlined" onClick={() => setReassignedDialogOpen(false)} style={{ marginRight: 5 }}>
                        <Typography variant="button">{t('button.cancel')}</Typography>
                    </Button>
                    <Button variant="contained">
                        <Typography onClick={assignRedeemCode} variant="button" style={{ color: '#000' }}>{t('button.confirm')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));