import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { 
    Box, 
    Breadcrumbs, 
    Grid, 
    IconButton, 
    Link, 
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import TradeSystemStatus from '@components/TradeSystemStatus';

const MODULE = 'trades';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [optionList, setOptionList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleList, setModuleList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }

        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setOptionList(newStatusList);
        // eslint-disable-next-line
    }, [moduleList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `trade-edit`) ?
                    <Link underline='none' to={`/trade-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit  />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            header: `${t(`${MODULE}.price`)}`,
            accessorKey: 'price',
        },
        {
            header: `${t(`${MODULE}.basic_trade`)}`,
            accessorKey: 'trade_unit.system_trade_unit',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`${MODULE}.auto_sell_trade`)}`,
            accessorKey: 'trade_unit.member_trade_unit',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`${MODULE}.totalTrade`)}`,
            accessorKey: 'trade_unit.total_trade_unit',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`${MODULE}.soldTrade`)}`,
            accessorKey: 'sold_unit',
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`${MODULE}.remainTrade`)}`,
            // accessorKey: 'remainTrade',
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({cell}) => (
                <> {cell?.row?.original?.trade_unit.total_trade_unit  - cell?.row?.original?.sold_unit}</>
             )
        },
        {
            header: `${t(`${MODULE}.max_unit`)}`,
            accessorKey: 'max_unit',
        },
        {
            accessorKey: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: optionList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>
                {
                    optionList.find(item => item.value == cell.row?.original?.status)?.text
                }
                </>
            ),
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, optionList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }} ><b>{t(`title.trades`)}</b></Typography>
                </Box>
              
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard" className="text-gold">
                        {t('title.dashboard')}
                    </Link>
                    <Typography className="text-white" >{t(`title.trades`)}</Typography>
                </Breadcrumbs>
            </Grid>
            {
                _.includes(permissions, `trade-create`) 
                ?
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingBottom: 5 }}>
                        <Box display="flex" alignItems="center" >
                            <Link underline='none' to={`/trade-add`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main }}>
                                    <AddBoxOutlined />
                                </IconButton>
                            </Link>
                            <Typography className="text-gold">{t(`button.add`)}</Typography>
                        </Box>
                        <Typography variant="h6" style={{marginLeft: 20, marginRight: 10}}>/</Typography>
                        <Box display="flex" alignItems="center">
                            <Link underline='none' to={`/trade-add2`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main }}>
                                    <AddBoxOutlined />
                                </IconButton>
                            </Link>
                            <Typography className="text-gold">{t(`button.bulkAdd`)}</Typography>
                        </Box>
                    </Grid>

                : 
                    null
            }

            {
                _.includes(permissions, `trade-status`)
                    ?
                    <div style={{ padding: 20, marginBottom: 20, borderRadius: 10, boxShadow: "-4px -4px 4px rgba(0, 0, 0, 0.1), 4px 4px 4px rgba(0, 0, 0, 0.1)" }} className="gradient-border-box">
                        <TradeSystemStatus callApi={() => callApi()} />
                    </div>
                    :
                    null
            }

            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'NoticeList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['title.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing