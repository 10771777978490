import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import CustomizeMaterialReactTable from '@layouts/CustomizeMaterialReactTable';

export default function Index() {
    const { t } = useTranslation();
    const [state, setState] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const { addAlert } = useNotificationLoading();
    const theme = useTheme();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        getUrl('/permissions').then(response => {
            setTableLoading(false);
            if (response.status) {
                setState(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `permission-edit`) ?
                    <Link underline='none' to={`/permission-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit  />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50
        },
        {
            header: `${t('permissions.name')}`,
            accessorKey: 'name',
            minSize: 400
        },
        {
            header: `${t('permissions.parent')}`,
            accessorKey: 'parent_name',
            minSize: 200
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
            minSize: 200
        },
        // eslint-disable-next-line
    ]), [permissions, t]);

    return (
        <div>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t('title.permissions')}</b></Typography>
                        {
                            _.includes(permissions, "permission-create") &&
                            <Link underline='none' to={`/permission-add`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main }}>
                                    <AddBoxOutlined />
                                </IconButton>
                            </Link>
                        }
                    </div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Typography color="text.primary">{t('title.permissions')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
            </div>
            <CustomizeMaterialReactTable
                columns={columns}
                data={state}
                isLoading={tableLoading}
                exportFile={false}
            />
        </div>
    );
}