import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl, getUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, FormControl, InputLabel, Select, FormHelperText, MenuItem, InputAdornment, Typography } from '@mui/material';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    mobile: '',
    email: '',
    rank: '',
    crank: '',
    mrank: '',
    cmrank: '',
    subsidy_percent: 0,
    extra_subsidy_percent: 0,
    status: 0,
    referralName: '',
    placementName: '',
    placementPosition: '',
}

const BasicInfo = (props) => {
    let { userInfo, statusList } = props;
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];
    const [option, setOption] = useState();
    const [allRanks, setAllRanks] = useState([]);
    const [allMRanks, setAllMRanks] = useState([]);
    const [validRanks, setValidRanks] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [caUnblockTime, setCAUnblockTime] = useState(null);
    const [twoFAUnblockTime, setTwoFAUnblockTime] = useState(null);

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    useEffect(() => {
        if (userInfo) {
            setState({ ...state, 
                username: userInfo?.username, 
                name: userInfo?.name, 
                email: userInfo?.email,
                rank: userInfo?.rank_id, 
                crank: userInfo?.crank_id, 
                mrank: userInfo?.mrank_id, 
                cmrank: userInfo?.cmrank_id, 
                subsidy_percent: userInfo?.subsidy_percent,
                extra_subsidy_percent: userInfo?.extra_subsidy_percent,
                status: userInfo?.status, 
                referralName: userInfo?.referral_name, 
                placementName: userInfo?.placement_name, 
                placementPosition: userInfo?.placement_position 
            });
            setUserMobile({
                ...userMobile,
                country: '',
                code: userInfo?.mobile_country_code,
                mobile: userInfo?.mobile,
            });
            setWallets(userInfo?.wallets);
            setCAUnblockTime(userInfo?.data?.['CA_unblockTime'] ? moment(userInfo?.data?.['CA_unblockTime']) : null);
            setTwoFAUnblockTime(userInfo?.data?.['2FA_unblockTime'] ? moment(userInfo?.data?.['2FA_unblockTime']) : null);
        }
        // eslint-disable-next-line
    }, [userInfo])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = /^\s*$/.test(value) || isNaN(value) ? "" : value;
            setUserMobile(prevState => ({ ...prevState, [name]: value }));
        }
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        // Fetch the data and update both valid and all ranks
        setLoading(true);
        getUrl(`/member-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setAllRanks(response.data.rank_list);
                setAllMRanks(response.data.mrank_list);
                // const validRanks = response.data.rankList.filter(rank => rank.status === 1);
                // setValidRanks(validRanks);
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);
    
    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            username: state.username, 
            name: state.name,
            email: state.email,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
            rank_id: state.rank || null,
            crank_id: state.crank || null,
            mrank_id: state.mrank || null,
            cmrank_id: state.cmrank || null,
            subsidy_percent: state.subsidy_percent,
            extra_subsidy_percent: state.extra_subsidy_percent,
            status: state.status,
            referral_name:state?.referralName,
            placement_name:state?.placementName,
            placement_position:state?.placementPosition,
            ca_unblock_time: caUnblockTime,
            twofa_unblock_time: twoFAUnblockTime, 
        }
        console.log(params)
        putUrl(`/members/${id}`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message??t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }
    
    const rankSelect = (column, disabled=false, RankList) => {
        if(_.size(RankList) > 0){
            return(
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.rank ? true : false}>
                                <InputLabel>{t(`${MODULE}.${column}`)}</InputLabel>
                                <Select
                                    value={state[column] || 0}
                                    label={t(`${MODULE}.${column}`)}
                                    name={column}
                                    onChange={handleChange}
                                    displayEmpty
                                    disabled={disabled}
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.rank`)} `} --- </MenuItem>
                                    {
                                        _.map(RankList, (data) => (
                                            <MenuItem key={data.id} value={data.id} disabled={data.status === 0}>{i18n.language === 'cn' ? data?.name[i18n.language] : data?.name?.en}</MenuItem>
                                        ))
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.rank &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.rank}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
            )
        }else{
            return null;
        }
    } 

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                            <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                            <Select
                                value={userMobile.country || ''}
                                label={t(`${MODULE}.country`)}
                                name="country"
                                onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0} disabled>
                                    --- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} ---
                                </MenuItem>
                                {
                                    _.map(mobileCodeList.country, (data, key) => {
                                        const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                        return (
                                            <MenuItem key={key} value={data.code}>
                                                {countryName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors['country'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                            }
                            <Typography variant="caption"  style={{ display: 'flex', textAlign: 'left' , color: theme.palette.noticeText.main }}>{t('general.pleaseSelectCountry')}</Typography>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                            <InputLabel>{t(`${MODULE}.mobileCode`)}</InputLabel> 
                            <Select
                                value={userMobile.code || ''}
                                label={t(`${MODULE}.mobileCode`)}
                                name="mobileCode"
                                onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                displayEmpty
                            >
                                {/* <MenuItem value="">
                                    {``}
                                </MenuItem> */}
                                {_.size(mobileCodeList?.code) > 0 ? (
                                    _.map(mobileCodeList?.code, (option, key) => (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" style={{ color: '#222' }}>
                                        {``}
                                    </MenuItem>
                                )}
                            </Select>
                            {
                                inputErrors && inputErrors['mobile_code'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={userMobile.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            type="email"
                            value={state.email || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            variant="outlined"
                            name='referralName'
                            label={t(`${MODULE}.referralName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                            error={inputErrors && inputErrors.referral_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.referralName || ''}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            variant="outlined"
                            name='placementName'
                            label={t(`${MODULE}.placementName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                            error={inputErrors && inputErrors.placement_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.placementName || ''}
                        />
                    </Box>
                </Grid>
                {
                    _.size(POSITION) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                                <InputLabel>{t(`${MODULE}.placementPosition`)}</InputLabel>
                                <Select
                                    value={state?.placementPosition || ''}
                                    label={t(`${MODULE}.placementPosition`)}
                                    name="placementPosition"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.placementPosition`)} `} --- </MenuItem>
                                    {
                                        _.map(POSITION, (data, key) => {
                                            return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.placement_position &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.placement_position}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {rankSelect("rank", true, allRanks)}
                {rankSelect("crank", false, allRanks)}
                {rankSelect("mrank", true, allMRanks)}
                {rankSelect("cmrank", false, allMRanks)}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.subsidyPercent`)}
                            variant="outlined"
                            type="number"
                            value={state.subsidy_percent || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.subsidy_percent ? inputErrors.subsidy_percent : ''}
                            error={inputErrors && inputErrors.subsidy_percent ? true : false}
                            name="subsidy_percent"
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><p>%</p></InputAdornment>,
                                inputProps: { min: 0, max: 100 }, // Optional: set min and max values
                            }}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.extraSubsidyPercent`)}
                            variant="outlined"
                            type="number"
                            value={state.extra_subsidy_percent || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.extra_subsidy_percent ? inputErrors.extra_subsidy_percent : ''}
                            error={inputErrors && inputErrors.extra_subsidy_percent ? true : false}
                            name="extra_subsidy_percent"
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><p>%</p></InputAdornment>,
                                inputProps: { min: 0, max: 100 }, // Optional: set min and max values
                            }}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(statusList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                <Select
                                    value={state?.status || ''}
                                    label={t(`${MODULE}.status`)}
                                    name="status"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.status`)} `} --- </MenuItem>
                                    {
                                        _.map(statusList, (data, key) => {
                                            return <MenuItem key={key} value={key}>{i18n.language === 'cn' ? data[i18n.language] : data?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.status &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.status}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                 <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={t(`${MODULE}.caUnblockTime`)}
                                name="caUnblockTime"
                                value={ caUnblockTime || null }
                                onChange={(newValue) => setCAUnblockTime(moment(newValue))}
                                slotProps={{
                                    textField: {
                                        helperText: inputErrors && inputErrors.ca_unblock_time ? inputErrors.ca_unblock_time : '',
                                        error: inputErrors && inputErrors.ca_unblock_time ? true : false,
                                    },
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                sx={{
                                    padding: 0,
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DateTimePicker
                                label={t(`${MODULE}.twoFAUnblockTime`)}
                                name="twoFAUnblockTime"
                                value={ twoFAUnblockTime || null } 
                                onChange={(newValue) => setTwoFAUnblockTime(moment(newValue))}
                                slotProps={{
                                    textField: {
                                        helperText: inputErrors && inputErrors.twofa_unblock_time ? inputErrors.twofa_unblock_time : '',
                                        error: inputErrors && inputErrors.twofa_unblock_time ? true : false,
                                    },
                                }}
                                sx={{
                                    padding: 0,
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {
                        _.map(wallets, (data, network) => {
                            if (network !== 'general') {
                                return (
                                    <div key={network} style={{ lineHeight: 0.6 }}>
                                        <p style={{ wordBreak: 'break-all', fontWeight: 600, color: theme.palette.primary.main }}>{' [' + network.substring(0, 3).toUpperCase() + '] : '}</p><p style={{ color: theme.palette.primary.main }}>{data?.address}</p>
                                    </div>
                                )
                            }
                        })
                    }
                    {
                        wallets?.general ?
                            <div style={{ lineHeight: 0.6 }}>
                                <p style={{ wordBreak: 'break-all', fontWeight: 600, color: theme.palette.primary.main }}>{t('user.withdrawal') + ' [' + wallets.general.network.toUpperCase() + '] :'}</p>
                                <p style={{ color: theme.palette.primary.main }}>{wallets.general.address}</p>
                            </div>
                            : null
                    }
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/members`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default BasicInfo;