import {
    STORE_PROFILE,
    RESET_PROFILE,
} from '@types';

const INITIAL_STATE = {
    id: null,
    username: '',
    name: '',
    email: '',
    mobile_country_code: '',
    mobile: '',
    status: '',
    two_factor_confirmed_at: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return { 
                ...state,
                id: actions.payload.id,
                username: actions.payload.username,
                name: actions.payload.name,
                email: actions.payload.email,
                mobile_country_code: actions.payload.mobile_country_code,
                mobile: actions.payload.mobile,
                status: actions.payload.status,
                two_factor_confirmed_at: actions.payload.two_factor_confirmed_at,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}