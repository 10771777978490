import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/styles';
import { Box, Button, LinearProgress } from '@mui/material';
import { MdDownload } from "react-icons/md";

import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

// 'material-react-table': https://www.material-react-table.com/
// 'react-csv': https://www.npmjs.com/package/react-csv
// columns must have accessorKey or accessorFn,id pair

/**
 * data with translation: need two columns, one for export, one for display 
 * for display: use cell, hide for export - hideExport: true at columns
 * for export : hide table column - columnVisibility: { firstName: false }
 */

const PaginationMaterialReactTable = ({ data, columns, isLoading, exportFile, exportFileName, rowCount, pagination, setPagination, columnFilters, setColumnFilters, sorting, setSorting, searchData, setSearchData, enableRowSelection, enableSelectAll, onRowSelectionChange, rowSelection, getRowId, topActions, columnVisibility }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [excelHeaders, setExcelHeaders] = useState([]);

    useEffect(() => {
        let headers = [];
        _.map(columns, value => {
            if (!value.hideExport) {
                headers.push({ label: value.header, key: value.exportKey ? value.exportKey : (value.accessorKey ? value.accessorKey : value.id) });
            }
        })
        setExcelHeaders(headers);
        // eslint-disable-next-line
    }, [columns]);

    const today = new Date();
    const datetime_now = today.getFullYear() +
        ((today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`) +
        ((today.getDate()) < 10 ? `0${(today.getDate())}` : `${(today.getDate())}`);

    const handleApplyFilter = () => {
        setPagination({ ...pagination, pageIndex: 0 });
        setSearchData(!searchData);
    }
    const handleClearFilter = () => {
        setColumnFilters([]);
        setPagination({ ...pagination, pageIndex: 0 });
        setSearchData(!searchData);
    }

    return (
        <>
            {
                isLoading
                    ?
                    <Box style={{ margin: 20, textAlign: "center" }}>
                        <LinearProgress />
                    </Box>
                    :
                    <MaterialReactTable
                        columns={columns}
                        data={data}

                        initialState={{
                            showColumnFilters: true,
                        }}
                        state={{ isLoading, columnFilters, pagination, sorting, rowSelection: rowSelection ? rowSelection : [], columnVisibility }}
                        localization={i18n.language === 'cn' ? MRT_Localization_ZH_HANS : MRT_Localization_EN}

                        // row selection
                        enableSelectAll={enableSelectAll ? true : false}
                        enableRowSelection={enableRowSelection ? enableRowSelection : false}
                        onRowSelectionChange={onRowSelectionChange ? onRowSelectionChange : null}
                        rowSelection={rowSelection}
                        getRowId={getRowId ? getRowId : ((originalRow) => originalRow.index)}

                        // pagination
                        rowCount={rowCount}
                        manualFiltering
                        manualPagination
                        manualSorting
                        onColumnFiltersChange={setColumnFilters}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 30, 50, 100, 500, 1000],
                            sx:{ color: theme.palette.secondary.main}
                        }}

                        // disabled functions
                        enableFullScreenToggle={false}
                        enableGlobalFilter={false}
                        enableHiding={false}
                        enableDensityToggle={false}

                        //CSS
                        muiTablePaperProps={{
                            sx: { 
                                backgroundSize:'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'top 47px center',
                                border:'2px solid transparent' , 
                                background:'linear-gradient(#111115,#111115) padding-box,linear-gradient(90deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f)' 
                            }
                        }}

                        muiTableContainerProps={{
                            sx:{ 
                                backgroundColor: theme.palette.background.main,
                                '& .MuiTypography-root':{
                                    color: theme.palette.secondary.main,
                                }
                            }
                        }}
                        
                        muiTableHeadCellProps={{
                            sx:{ 
                                color: theme.palette.secondary.main ,
                                backgroundColor: theme.palette.background.main,
                                '.MuiTableSortLabel-icon': {
                                    color: `${theme.palette.secondary.main} !important`, 
                                },
                            }
                        }}

                        muiTopToolbarProps={{
                            sx:{ 
                                backgroundColor: theme.palette.background.main
                            }
                        }}

                        muiTableBodyCellProps={{
                            sx:{ 
                                color: theme.palette.secondary.main , 
                                backgroundColor: theme.palette.background.main
                            }
                        }}

                        muiTableBodyRowProps={{
                            sx:{
                                ":hover td":{
                                    backgroundColor:theme.palette.noticeText.main,
                                }
                            }
                        }}

                        muiBottomToolbarProps={{
                            sx:{ 
                                backgroundColor: theme.palette.background.main,
                                '& .MuiSvgIcon-root': {
                                    color: 'unset',
                                },
                                '& .MuiButtonBase-root': {
                                    color: theme.palette.primary.main
                                },
                                '& .MuiButtonBase-root.Mui-disabled': {
                                    color: theme.palette.gray.tableHeaderIcon,
                                }
                            }
                        }}

                        muiTableHeadCellFilterTextFieldProps={{
                            sx: {
                                '& .MuiSvgIcon-root': {
                                    color: 'unset',
                                },
                                '& .MuiButtonBase-root': {
                                    color: theme.palette.primary.main
                                },
                                '& .MuiButtonBase-root.Mui-disabled': {
                                    color: theme.palette.gray.tableHeaderIcon,
                                }
                            }
                        }}

                        // EXPORT
                        positionToolbarAlertBanner="top"
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                {
                                    exportFile
                                        ?
                                        <CSVLink
                                            data={(table.getPrePaginationRowModel().rows).map((row) => row.original)}
                                            headers={excelHeaders}
                                            filename={exportFileName + `_` + datetime_now + `.csv`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                startIcon={<MdDownload />}
                                                variant="contained"
                                            >
                                                {t('button.exportData')}
                                            </Button>
                                        </CSVLink>
                                        :
                                        null
                                }
                                <Button variant="contained" onClick={() => handleApplyFilter()}>
                                    {t('button.applyFilter')}
                                </Button>
                                <Button variant="contained" onClick={() => handleClearFilter()}>
                                    {t('button.clearFilter')}
                                </Button>
                                {topActions ? topActions : null}
                            </Box>
                        )}
                    />
            }
        </>
    )
};

export default PaginationMaterialReactTable