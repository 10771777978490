import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { storeProfile } from '@actions';
import { putUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

//MUI
import { Box, Button, Grid, Link, TextField, MenuItem, FormHelperText, Typography, FormControl, InputLabel, Select } from '@mui/material/';

export default function BasicInfoProfile() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { username, name, email, mobile, mobile_country_code } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: name,
        email: email,
        mobile: mobile,
        username: username,
    });
    
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: mobile_country_code || "",
        mobile: mobile || "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'zip') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setUserMobile(prevState => ({ ...prevState, [name]: value }));
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            name: state.name,
            email: state.email,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
        }

        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Box  autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t('user.username')}
                                name="username"
                                variant="outlined"
                                type="text"
                                autoComplete="username"
                                value={state.username || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t('user.name')}
                                name="name"
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t('user.email')}
                                name="email"
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                                <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                                    <InputLabel>{t(`user.country`)}</InputLabel>
                                    <Select
                                        value={userMobile.country || ''}
                                        label={t(`user.country`)}
                                        name="country"
                                        onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                        displayEmpty
                                    >
                                        <MenuItem key={0} value={0} disabled>
                                            --- {`${t('general.pleaseSelect')} ${t(`user.country`)} `} ---
                                        </MenuItem>
                                        {
                                            _.map(mobileCodeList.country, (data, key) => {
                                                const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                                return (
                                                    <MenuItem key={key} value={data.code}>
                                                        {countryName}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                    {
                                        inputErrors && inputErrors['country'] &&
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                                    }
                                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', textAlign: 'left' }}>{t('general.pleaseSelectCountry')}</Typography>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                                <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                                    <InputLabel>{t(`user.mobileCode`)}</InputLabel> 
                                    <Select
                                        value={userMobile.code || ''}
                                        label={t(`user.mobileCode`)}
                                        name="mobileCode"
                                        onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                        displayEmpty
                                    >
                                        {/* <MenuItem value="">
                                            {``}
                                        </MenuItem> */}
                                        {_.size(mobileCodeList?.code) > 0 ? (
                                            _.map(mobileCodeList?.code, (option, key) => (
                                                <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="" style={{ color: '#222' }}>
                                                {``}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {
                                        inputErrors && inputErrors['mobile_code'] &&
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`user.mobile`)}
                                    variant="outlined"
                                    value={userMobile.mobile}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                    error={inputErrors && inputErrors.mobile ? true : false}
                                    name="mobile"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/dashboard`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} type="submit" onClick={submitData}>{t('button.save')}</Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));