import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BackToPrevious from '@layouts/BackToPrevious';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const Create = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        codes: '',
        plan_id: '',
    });
    const [planListing, setPlanListing] = useState([]);
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/redeem-code-add` ? "add" : "edit";

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        getUrl(`/redeem-code/plan-list`).then(response => {
            if (response.status) {
                setPlanListing(response.plan_list)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setState({
            codes: '',
            plan_id: '',
        });
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            codes: state.codes,
            plan_id: state.plan_id,
        }

        postUrl(`/redeem-code`, updateData).then(response => {
            setLoading(false);
            const { status, errors, message } = response;
            if (status) {
                addAlert('', message || t('success.createSuccess'), 'success', '');
                navigate(`/redeem-codes`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.redeemCode${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" component={RouterLink} to={`/redeem-codes`}>
                            {t(`title.redeemCode`)}
                        </Link>
                        <Typography >{t(`title.redeemCode${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={formType === 'edit' ? 4 : 6}>
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="status-label">{t(`plans.name`)}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="plan_id"
                            value={state.plan_id}
                            label={t(`plans.name`)}
                            onChange={handleChange}
                            name="plan_id"
                            error={inputErrors && inputErrors.plan_id ? true : false}
                        >
                            <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                            {
                                planListing &&
                                _.map(planListing, (plan) => {
                                    return (
                                        <MenuItem value={plan.id} key={plan.id}>{plan.name[i18n.language]}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                        {
                            inputErrors && inputErrors?.plan_id &&
                            <FormHelperText style={{ color: 'red' }}>{inputErrors?.plan_id}</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormHelperText style={{ marginLeft: 10 }}>{t('redeemCode.seperateBy')}</FormHelperText>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`redeemCode.code`)}
                            variant="outlined"
                            value={state.codes}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.codes ? inputErrors.codes : ''}
                            error={inputErrors && inputErrors.codes ? true : false}
                            name="codes"
                            multiline
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/redeem-code`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default Create