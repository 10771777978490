import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { 
    Box, 
    Breadcrumbs, 
    Button, 
    Grid, 
    Link, 
    TextField, 
    Typography, 
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

import { IoClose , IoAddOutline } from "react-icons/io5";

const MODULE = 'trades';

export default function Detail() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const INITIAL_DATA = {
        startPrice: '', 
        endPrice: '', 
        unit: '',
        increasePercent: '',
        maxUnit: '',
    }
    const [state, setState] = useState(INITIAL_DATA);
    const [inputErrors, setInputErrors] = useState({});

    const location = useLocation();
    const formType = location.pathname === `/trade-add2` ? "add" : "edit";

    const submitData = () => {
        setLoading(true);
        setInputErrors({});

        postUrl(`/${MODULE}-version2Add`, state).then(response => {
            if (response.status) {
                setState(INITIAL_DATA);
                addAlert('', response.message || t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    return (
        <>
            <BackToPrevious />

            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.startPrice`)}
                            variant="outlined"
                            value={state.startPrice}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.startPrice ? inputErrors.startPrice : ''}
                            error={inputErrors && inputErrors.startPrice ? true : false}
                            name="startPrice"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.endPrice`)}
                            variant="outlined"
                            value={state.endPrice}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.endPrice ? inputErrors.endPrice : ''}
                            error={inputErrors && inputErrors.endPrice ? true : false}
                            name="endPrice"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.increasePercent`)}
                            variant="outlined"
                            value={state.increasePercent}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.increasePercent ? inputErrors.increasePercent : ''}
                            error={inputErrors && inputErrors.increasePercent ? true : false}
                            name="increasePercent"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.unit`)}
                            variant="outlined"
                            value={state.unit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.unit ? inputErrors.unit : ''}
                            error={inputErrors && inputErrors.unit ? true : false}
                            name="unit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.max_unit`)}
                            variant="outlined"
                            value={state.maxUnit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.maxUnit ? inputErrors.maxUnit : ''}
                            error={inputErrors && inputErrors.maxUnit ? true : false}
                            name="maxUnit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained"  className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    rightIcon: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "green",
        cursor: 'pointer'
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    leftIcon: {
        position: "absolute",
        left: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    iconStyle: {
        fontSize: 20
    },
    inputStyle: {
        margin: 10
    }
}))