import React, { useState, useCallback, createContext } from 'react';

export const NotificationLoadingContext = createContext({
    alert: null,
    addAlert: () => {},
    removeAlert: () => {},
    loading: null,
    setLoading: () => {},
    showConfirm: null, 
    getConfirmation: () => {}, 
    note: "",
    setNote: () => {}, 
    closeDialog: () => {},
    resolver: null,
});

const createPromise = () => {
    let resolver;
    return [ new Promise(( resolve, reject ) => {
        resolver = resolve
    }), resolver]
}
  
export default function NotificationLoadingProvider({ children }) {
    const [alert, setAlert] = useState({ open: false, severity: '', title: '', message: "", position: "" });
    const [loading, setLoadingShow] = useState(false);
    const [note, setConfirmNote] = useState("");
    const [showConfirm, setConfirmDialogShow] = useState(false);
  
    const removeAlert = () => setAlert({ open: false });
  
    const addAlert = (title, message = "", severity = 'error', position = "") => {
        setAlert({ open: true, severity, title, message, position });
    }

    const setLoading = (isLoading = false) => {
        setLoadingShow(isLoading);
    }

    const setNote = (val) => {
        setConfirmNote(val);
    }

    const [ resolver, setResolver ] = useState({ resolver: null })

    const getConfirmation = async () => {
        setConfirmDialogShow(true);
        const [ promise, resolve ] = await createPromise()
        setResolver({ resolve })
        return promise;
    }

    const closeDialog = async (status) => {
        setConfirmDialogShow(false);
        setConfirmNote("");
    }
  
    const contextValue = {
        alert,
        addAlert: useCallback((severity, title, message, position) => addAlert(severity, title, message, position), []),
        removeAlert: useCallback(() => removeAlert(), []),
        loading,
        setLoading: useCallback((isLoading) => setLoading(isLoading), []),
        showConfirm,
        getConfirmation: useCallback(() => getConfirmation(), []),
        note,
        setNote: useCallback((value) => setNote(value), []),
        closeDialog: useCallback((value) => closeDialog(value), []),
        resolver,
    };
  
    return (
        <NotificationLoadingContext.Provider value={ contextValue }>
            { children }
        </NotificationLoadingContext.Provider>
    );
}