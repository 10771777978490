import React from 'react';
import { Snackbar, Box, Backdrop, CircularProgress, Alert, AlertTitle, Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';

const NotificationLoading = () => {
    const { alert, removeAlert, loading, setNote, note, closeDialog, showConfirm, resolver } = useNotificationLoading();
    const classes = useStyles();
    const { t } = useTranslation();

    let anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
    if (alert.position) {
        const newAnchorOrigin = _.split(alert.position, '-');
        anchorOrigin = { vertical: newAnchorOrigin[0], horizontal: newAnchorOrigin[1] };
    }

    return (
        <Box>
            {
                alert.open
                    ?
                    <Snackbar open={!!alert.open} autoHideDuration={alert.severity === 'error' ? 10000 : 3000} onClose={removeAlert} anchorOrigin={anchorOrigin}>
                        <Alert elevation={6} variant="filled" onClose={removeAlert} severity={alert.severity}>
                            <AlertTitle>{alert.title}</AlertTitle>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    : null
            }
            {
                loading ?
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="primary" />
                    </Backdrop>
                    : null
            }
            {   showConfirm?
                <Dialog open={showConfirm} fullWidth={true} maxWidth={'sm'}>
                    <DialogContent>
                        <TextField
                            id="note"
                            label={t('auditLog.note')}
                            type="text"
                            name="note"
                            value={note}
                            onChange={ ({target}) => setNote(target.value ) }
                            InputLabelProps={{ shrink: true }}
                            style={{ padding: 5 }}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => {resolver.resolve({status: false, note: note}); closeDialog(false); }}>{t('button.cancel')}</Button>
                        <Button onClick={ () => {resolver.resolve({status: true, note: note}); closeDialog(true); }}>{t('button.confirm')}</Button>
                    </DialogActions>
                </Dialog>
                : null
            }
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1, // zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default NotificationLoading;