import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography, Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import CustomizeMaterialReactTable from '@layouts/CustomizeMaterialReactTable';

import SearchComponent from '@components/SearchComponent';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [planListing, setPlanListing] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [reassignedDialogOpen, setReassignedDialogOpen] = useState(false);
    const [redeemCodeLoading, setRedeemCodeLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [selectedGenealogyType, setSelectedGenealogyType] = useState("individual");
    const [searchValue, setSearchValue] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const filterByDateRange = (rows, columnIds, filterValue) => {
        const [start, end] = filterValue;
        return rows.filter(row => {
            const rowDate = new Date(row.values[columnIds[0]]);
            return rowDate >= new Date(start) && rowDate <= new Date(end);
        });
    };

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType("individual");
        callApi();
    };
    
    const handleSearch = () => {
        callApi();
    };

    useEffect(() => {
        getUrl(`/redeem-code/plan-list`).then(response => {
            if (response.status) {
                setPlanListing(response.plan_list)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    useEffect(() => {
        const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        const newFilters = columnFilters.filter(filter => filter.id !== 'created_at');
        newFilters.push({ id: 'created_at', value: [formattedStartDate, formattedEndDate] });
        setColumnFilters(newFilters);
    }, [startDate, endDate]);

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue ? searchValue : '',
            genealogyType: searchValue ? selectedGenealogyType : ''
        }
        getUrl(`/redeem-code?status=all`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", response.error, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `redeem-code-edit`) ?
                    <Link underline='none' to={`/redeem-code-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            accessorKey: 'id',
            header: `${t('general.id')}`,
        },
        {
            accessorKey: 'plan.name',
            header: `${t('plans.name')}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.plan.name[i18n.language] ? cell.row.original.plan.name[i18n.language] : cell.row.original.plan.name.en}</>
            ),
            exportKey: 'plan.name.' + i18n.language,
            enableSorting: false,
        },
        {
            accessorKey: 'code',
            header: `${t('redeemCode.code')}`,
        },
        {
            accessorKey: 'plan_history_id',
            header: `${t('redeemCode.planHistoryId')}`,
        },
        {
            accessorFn: (row) => (row.plan_history?.plan_historable?.username ? row.plan_history.plan_historable.username : '-'),
            id: 'plan_history.plan_historable.username',
            header: `${t('planHistories.userId')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'status',
            header: `${t('redeemCode.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => {
                let statusDisplay = _.find(statusList, function(s){return parseInt(s.value) === cell.row.original.status;})
                return <>{statusDisplay?.text}</>
            },
            hideExport: true,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
            filterFn: filterByDateRange,
            Filter: ({ column }) => (
                <div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            slotProps={{
                                textField: {
                                  helperText: 'From',
                                  sx: { minWidth: '120px' },
                                  variant: 'standard',
                                },
                              }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            slotProps={{
                                textField: {
                                  helperText: 'To',
                                  sx: { minWidth: '120px' },
                                  variant: 'standard',
                                },
                              }}
                        />
                    </LocalizationProvider>
                </div>
            )
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, startDate, endDate]);

    const planListColumns = useMemo(() => ([
        {
            header: `${t('plans.name')}`,
            accessorKey: 'name',
            accessorFn: (row) => (row?.name?.[i18n.language] ? row?.name?.[i18n.language] : '-'),
        },
        {
            header: `${t('redeemCode.availableCount')}`,
            accessorKey: 'count',
            accessorFn: (row) => (row.redeem_code_count.not_assign + ' / ' + (row.redeem_code_count.assigned + row.redeem_code_count.not_assign)),
        },
        // eslint-disable-next-line
    ]), [t]);

    const assignRedeemCode = () => {
        setRedeemCodeLoading(true);
        if (reassignedDialogOpen) {
            setReassignedDialogOpen(false);
        }
        postUrl(`/redeem-code/assign-to-phs`).then(response => {
            setRedeemCodeLoading(false);
            const { status, message } = response;
            if (status) {
                addAlert('', message, 'success', '');
            } else {
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setRedeemCodeLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.redeemCode`)}</b></Typography>
                    {_.includes(permissions, `redeem-code-create`) ?
                        <Link underline='none' to={`/redeem-code-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.redeemCode`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Box style={{marginBottom: 10}}>
                <Button variant="contained" onClick={() => setDialogOpen(true)}>{t('redeemCode.planRedeemCodeCount')}</Button>
                <Button variant="contained" onClick={() => setReassignedDialogOpen(true)} style={{marginLeft: 20}} disabled={redeemCodeLoading}>{t('redeemCode.getCode')}</Button>
            </Box>
            {/* <SearchComponent
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClearSearch={handleClearSearch}
                handleSearch={handleSearch}
                selectedGenealogyType={selectedGenealogyType}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
            /> */}
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'RedeemCodeList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <Box style={{marginBottom: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography>{t('redeemCode.planRedeemCodeCount')}</Typography>
                    <Button variant="contained" onClick={() => setDialogOpen(false)}>
                        {t('button.close')}
                    </Button>
                    </Box>
                    <CustomizeMaterialReactTable
                        columns={planListColumns}
                        data={planListing}
                        exportFile={false}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={reassignedDialogOpen} onClose={() => setReassignedDialogOpen(false)}>
                <DialogContent>
                    <Typography>{t('redeemCode.assignRedeemCodeToEmptyPHNote')}</Typography>
                </DialogContent>
                <DialogActions style={{ padding: 20 }}>
                    <Button variant="outlined" onClick={() => setReassignedDialogOpen(false)} style={{ marginRight: 5 }}>
                        {t('button.cancel')}
                    </Button>
                    <Button variant="contained" onClick={assignRedeemCode}>
                        {t('button.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Listing