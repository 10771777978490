import _ from 'lodash';
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// MUI
import { Box, Container, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

// NOTIFICATON AND LOADING
import NotificationLoadingProvider from "@providers/NotificationLoadingProvider";
import NotificationLoading from "@layouts/NotificationLoading";

//ICONS
import { MdDashboard, MdAdminPanelSettings, MdWallet, MdArticle, MdPeopleAlt, MdSettings , MdHomeFilled} from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";

// PAGES
import Sidebar from '@layouts/Sidebar';
import Dashboard from "@pages/Dashboard";
import Login from "@pages/Auth/Login";
import ForgetPassword from "@pages/Auth/ForgetPassword";
import ResetPassword from "@pages/Auth/ResetPassword";
import Profile from "@pages/Profile";
import Role from "@pages/Role";
import RoleDetail from "@pages/Role/Detail";
import Permission from "@pages/Permission";
import PermissionDetail from "@pages/Permission/Detail";
import SponsorGenealogy from './Genealogy';
import GenealogyPlacement from './Genealogy/GenealogyPlacement';
import Banner from '@pages/Banner';
import Rank from "@pages/Rank";
import RankDetail from "@pages/Rank/Detail";
import Video from '@pages/Banner/VideoIndex';

// WALLETS
import Wallet from "@pages/Wallet";
import WalletDetail from "@pages/Wallet/Detail";
import WalletAdjustment from "@pages/Wallet/WalletAdjustment";
// import WalletTransferConvert from "@pages/Wallet/WalletTransferConvert";
// import WalletWithdraw from "@pages/Wallet/WalletWithdraw";
import WalletUserWithDraw from "@pages/Wallet/WalletUserWithDraw";

// REPORTS
import WalletActionList from "@pages/Reports/WalletActionList";
import WalletBalanceList from "@pages/Reports/WalletBalanceList";
import WalletTransactionList from "@pages/Reports/WalletTransactionList";
import WalletWithdrawalsList from "@pages/Reports/WalletWithdrawalsList";
import ReportTradeSellHistories from "@pages/Reports/TradeSellHistories";
import ReportTradeBuyHistories from "@pages/Reports/TradeBuyHistories";
import ReportTradeSell from "@pages/Reports/TradeSell";
import SalesPerformance from "@pages/SalesPerformance";
import PairingLog from "@pages/PairingLog";
import MonthlyReport from "@pages/Reports/MonthlyReport";
import AuditLog from "@pages/Reports/AuditLog";
import TradeTransactions from "@pages/Reports/TradeTransactions";
import TradeRedeemableTransactions from "@pages/Reports/TradeRedeemableTransactions";
import ThirdPartyConvertListing from "@pages/Reports/ThirdPartyConvertListing";
import AccountLinkListing from "@pages/Reports/AccountLink";
import ThirdPartyPaymentOrderListing from '@pages/Reports/ThirdPartyPaymentOrderListing';

// PLANS
import Plan from "@pages/Plan";
import PlanDetail from "@pages/Plan/Detail";
import PlanHistory from "@pages/PlanHistory";
import PlanHistoryCreate from "@pages/PlanHistory/Create";
import PlanHistoryEdit from "@pages/PlanHistory/Edit";
import RedeemCode from "@pages/RedeemCode";
import RedeemCodeCreate from "@pages/RedeemCode/Create";
import RedeemCodeEdit from "@pages/RedeemCode/Edit";

// MEMBERS
import Member from "@pages/Member";
import MemberCreate from "@pages/Member/Create";
import MemberEdit from "@pages/Member/Edit";

// MERCHANTS
import Merchant from "@pages/Merchant";
import MerchantDetail from "@pages/Merchant/Edit";
import MerchantCreate from "@pages/Merchant/Create";
import MerchantTransactionList from "@pages/Merchant/Trade/MerchantTransactionList";
import MerchantTradeProfile from '@pages/Merchant/Trade/MerchantTradeProfile';
import MerchantProfileAdjustment from '@pages/Merchant/Trade/MerchantProfileAdjustment';
// import MerchantWithdraw from '@pages/Merchant/MerchantWithdraw';
// import MerchantWithdrawalsList from '@pages/Merchant/MerchantWithdrawalsList';
// import MerchantWithdrawalSetting from '@pages/Merchant/MerchantWithdrawalSetting';
// import MerchantStrategiesWithdrawalSetting from '@pages/Merchant/MerchantStrategiesWithdrawalSetting';

// ADMINS
import Admin from "@pages/Admin";
import AdminCreate from "@pages/Admin/Create";
import AdminEdit from "@pages/Admin/Edit";

// STAFFS
// import Staff from "@pages/Staff";
// import StaffCreate from "@pages/Staff/Create";
// import StaffEdit from "@pages/Staff/Edit";

// SETTINGS
import Setting from "@pages/Setting";
import SettingDetail from "@pages/Setting/Detail";
import WalletSetting from "@pages/WalletSetting";
import WalletSettingDetail from "@pages/WalletSetting/Detail";

// NOTICES
import Notice from "@pages/Notice";
import NoticeDetail from "@pages/Notice/Detail";
import NoticeImage from '@pages/Notice/Image/index';

// COMMISSIONS
import Commission from '@pages/Commission';
import Bonus from '@pages/Bonus';
import BonusDetail from '@pages/Bonus/Detail';

// Trade 
import Trade from "@pages/Trade";
import TradesAdd from "@pages/Trade/Add";
import TradesAdd2 from "@pages/Trade/AddVersion2";
import TradeDetail from "@pages/Trade/Detail";
import TradeProfile from "@pages/Trade/TradeProfile";
import ProfileAdjustment from "@pages/Trade/ProfileAdjustment";
import TradeRedeemable from "@pages/Trade/TradeRedeemable";
import RedeemableAdjustment from "@pages/Trade/RedeemableAdjustment";

// Trade Setting
import TradeSystem from "@pages/Setting/TradeSystem";
// import TradeProfit from "@pages/Setting/TradeProfit";

const routes = [
    // category: true; if show as category, fill in category info, and state all category children
    // name in english, label in translate, children icon wont show if category
    // category: false; if show as self, put details at children

    // DASHBOARD
    {
        category: false,
        categoryName: '',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/dashboard",
                component: <Dashboard />,
                permission: [],
                sidebar: true,
                icon: <MdDashboard size="20px" />,
                title: 'title.dashboard',
            },
            {
                path: "/profile",
                component: <Profile />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.profile',
            },
        ]
    },
    // TRADE 
    {
        category: true,
        categoryName: 'trade',
        categoryLabel: 'title.trades',
        categoryIcon: <BsGraphUpArrow size="20px" />,
        categoryPermission: ['trade', 'trade-profile', 'trade-profile-adjust', 'trade-redeemable', 'trade-redeemable-adjust', 'trade-create', 'trade-edit', 'report-trade-sell-histories', 'report-trade-buy-histories', 'report-trade-sell', 'trade-setting'],
        categorySidebar: true,
        children: [
            {
                path: "/trades",
                component: <Trade />,
                permission: ['trade'],
                sidebar: true,
                icon: null,
                title: 'title.trades',
            },
            {
                path: "/trade-profile",
                component: <TradeProfile />,
                permission: ['trade-profile'],
                sidebar: true,
                icon: null,
                title: 'title.tradeProfile',
            },
            {
                path: "/profile-adjustment",
                component: <ProfileAdjustment />,
                permission: ['trade-profile-adjust'],
                sidebar: false,
                icon: null,
                title: 'title.profileAdjustment',
            },
            {
                path: "/trade-transactions",
                component: <TradeTransactions />,
                permission: ['report-trade-transactions'],
                sidebar: true,
                icon: null,
                title: 'title.reportTradeTransaction',
            },
            {
                path: "/trade-redeemable",
                component: <TradeRedeemable />,
                permission: ['trade-redeemable'],
                sidebar: true,
                icon: null,
                title: 'title.tradeRedeemable',
            },
            {
                path: "/redeemable-adjustment",
                component: <RedeemableAdjustment />,
                permission: ['trade-redeemable-adjust'],
                sidebar: false,
                icon: null,
                title: 'title.redeemableAdjustment',
            },
            {
                path: "/trade-redeemable-transactions",
                component: <TradeRedeemableTransactions />,
                permission: ['report-trade-redeemable-transactions'],
                sidebar: true,
                icon: null,
                title: 'title.reportRedeemableTransaction',
            },
            {
                path: "/trade-add",
                component: <TradesAdd />,
                permission: ['trade-create'],
                sidebar: false,
                icon: null,
                title: 'title.tradesAdd',
            },
            {
                path: "/trade-add2",
                component: <TradesAdd2 />,
                permission: ['trade-create'],
                sidebar: false,
                icon: null,
                title: 'title.tradesAdd',
            },
            {
                path: "/trade-edit/:id",
                component: <TradeDetail />,
                permission: ['trade-edit'],
                sidebar: false,
                icon: null,
                title: 'title.tradesEdit',
            },
            {
                path: "/report-trade-sell",
                component: <ReportTradeSell />,
                permission: ['report-trade-sell'],
                sidebar: true,
                icon: null,
                title: 'title.ReportTradeSell',
            },
            {
                path: "/report-trade-sell-histories",
                component: <ReportTradeSellHistories />,
                permission: ['report-trade-sell-histories'],
                sidebar: true,
                icon: null,
                title: 'title.ReportTradeSellHistories',
            },
            {
                path: "/report-trade-buy-histories",
                component: <ReportTradeBuyHistories />,
                permission: ['report-trade-buy-histories'],
                sidebar: true,
                icon: null,
                title: 'title.ReportTradeBuyHistories',
            },
            {
                path: "/edit-trade-system",
                component: <TradeSystem />,
                permission: ['trade-setting'],
                sidebar: true,
                icon: null,
                title: 'title.tradeSystem',
            },
        ],
    },
    // ADMIN 
    {
        category: true,
        categoryName: 'admin',
        categoryLabel: 'title.admin',
        categoryIcon: <MdAdminPanelSettings size="20px" />,
        categoryPermission: ['admin', 'permission', 'wallet-adjust', 'plan', 'notice', 'banner-gallery', 'notice-create', 'notice-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/admins",
                component: <Admin />,
                permission: ['admin'],
                sidebar: true,
                icon: null,
                title: 'title.admin',
            },
            {
                path: "/admin-add",
                component: <AdminCreate />,
                permission: ['admin-create'],
                sidebar: false,
                icon: null,
                title: 'title.adminAdd',
            },
            {
                path: "/admin-edit/:id",
                component: <AdminEdit />,
                permission: ['admin-edit'],
                sidebar: false,
                icon: null,
                title: 'title.adminEdit',
            },
            {
                path: "/permissions",
                component: <Permission />,
                permission: ['permission'],
                sidebar: true,
                icon: null,
                title: 'title.permissions',
            },
            {
                path: "/permission-add",
                component: <PermissionDetail />,
                permission: ['permission-create'],
                sidebar: false,
                icon: null,
                title: 'title.permissionCreate',
            },
            {
                path: "/permission-edit/:id",
                component: <PermissionDetail />,
                permission: ['permission-edit'],
                sidebar: false,
                icon: null,
                title: 'title.permissionEdit',
            },
            {
                path: "/wallet-adjustment",
                component: <WalletAdjustment />,
                permission: ['wallet-adjust'],
                sidebar: true,
                icon: null,
                title: 'title.walletAdjustment',
            },
            {
                path: "/plans",
                component: <Plan />,
                permission: ['plan'],
                sidebar: true,
                icon: null,
                title: 'title.plan',
            },
            {
                path: "/plan-add",
                component: <PlanDetail />,
                permission: ['plan-create'],
                sidebar: false,
                icon: null,
                title: 'title.planAdd',
            },
            {
                path: "/plan-edit/:id",
                component: <PlanDetail />,
                permission: ['plan-edit'],
                sidebar: false,
                icon: null,
                title: 'title.planEdit',
            },
            {
                path: "/redeem-codes",
                component: <RedeemCode />,
                permission: ['redeem-code'],
                sidebar: true,
                icon: null,
                title: 'title.redeemCode',
            },
            {
                path: "/redeem-code-add",
                component: <RedeemCodeCreate />,
                permission: ['redeem-code-create'],
                sidebar: false,
                icon: null,
                title: 'title.redeemCodeAdd',
            },
            {
                path: "/redeem-code-edit/:id",
                component: <RedeemCodeEdit />,
                permission: ['redeem-code-edit'],
                sidebar: false,
                icon: null,
                title: 'title.redeemCodeEdit',
            },
            {
                path: "/notices",
                component: <Notice />,
                permission: ['notice'],
                sidebar: true,
                icon: null,
                title: 'title.notice',
            },
            {
                path: "/notice-add",
                component: <NoticeDetail />,
                permission: ['notice-create'],
                sidebar: false,
                icon: null,
                title: 'title.noticeAdd',
            },
            {
                path: "/notice-edit/:id",
                component: <NoticeDetail />,
                permission: ['notice-edit'],
                sidebar: false,
                icon: null,
                title: 'title.noticeEdit',
            },
            {
                path: "/notice-gallery",
                component: <NoticeImage />,
                permission: ['notice-create', 'notice-edit'],
                sidebar: true,
                icon: null,
                title: 'title.noticeImage',
            },
            {
                path: "/banner",
                component: <Banner />,
                permission: ['banner-gallery'],
                sidebar: true,
                icon: null,
                title: 'title.banner',
            },
            {
                path: "/video",
                component: <Video />,
                permission: [],
                sidebar: true,
                icon: null,
                title: 'title.video',
            },
        ],
    },
    // WALLET
    {
        category: true,
        categoryName: 'wallet',
        categoryLabel: 'title.wallets',
        categoryIcon: <MdWallet size="20px" />,
        categoryPermission: ['wallet', 'wallet-withdraw', 'report-withdrawals'],
        categorySidebar: true,
        children: [
            {
                path: "/wallets",
                component: <Wallet />,
                permission: ['wallet'],
                sidebar: true,
                icon: null,
                title: 'title.wallets',
            },
            {
                path: "/wallet-add",
                component: <WalletDetail />,
                permission: ['wallet-create'],
                sidebar: false,
                icon: null,
                title: 'title.walletAdd',
            },
            {
                path: "/wallet-edit/:id",
                component: <WalletDetail />,
                permission: ['wallet-edit'],
                sidebar: false,
                icon: null,
                title: 'title.walletEdit',
            },
            {
                path: "/wallet-users-withdraw",
                component: <WalletUserWithDraw />,
                permission: ['wallet-withdraw'],
                sidebar: true,
                icon: null,
                title: 'title.walletUsersWithdraw',
            },
            {
                path: "/wallet-withdraw-reports",
                component: <WalletWithdrawalsList />,
                permission: ['report-withdrawals'],
                sidebar: true,
                icon: null,
                title: 'title.walletWithdrawalsReports',
            },
            // {
            //     path: "/wallet-transfer-convert",
            //     component: <WalletTransferConvert />,
            //     permission: ['wallet-adjust'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.walletTransferConvert',
            // },
            // {
            //     path: "/wallet-withdraw",
            //     component: <WalletWithdraw />,
            //     permission: ['wallet-withdraw'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.walletWithdraw',
            // },
        ],
    },
    // REPORTS
    {
        category: true,
        categoryName: 'reports',
        categoryLabel: 'title.reports',
        categoryIcon: <MdArticle size="20px" />,
        categoryPermission: ['report-wallet-action', 'report-wallet-balance', 'report-wallet-transaction', 'plan-history', 'commission', 'report-sales-performances', 'report-pairing-log', 'report-audit-log'],
        categorySidebar: true,
        children: [
            {
                path: "/wallet-action",
                component: <WalletActionList />,
                permission: ['report-wallet-action'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletAction',
            },
            {
                path: "/wallet-balance",
                component: <WalletBalanceList />,
                permission: ['report-wallet-balance'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletBalance',
            },
            {
                path: "/wallet-transaction",
                component: <WalletTransactionList />,
                permission: ['report-wallet-transaction'],
                sidebar: true,
                icon: null,
                title: 'title.reportWalletTransaction',
            },
            {
                path: "/plan-histories",
                component: <PlanHistory />,
                permission: ['plan-history'],
                sidebar: true,
                icon: null,
                title: 'title.planHistory',
            },
            {
                path: "/plan-history-add",
                component: <PlanHistoryCreate />,
                permission: ['plan-history-create'],
                sidebar: false,
                icon: null,
                title: 'title.planHistoryAdd',
            },
            {
                path: "/plan-history-edit/:id",
                component: <PlanHistoryEdit />,
                permission: ['plan-history-edit'],
                sidebar: false,
                icon: null,
                title: 'title.planHistoryEdit',
            },
            {
                path: "/commission",
                component: <Commission />,
                permission: ['commission'],
                sidebar: true,
                icon: null,
                title: 'title.commission',
            },
            {
                path: "/sales-performance",
                component: <SalesPerformance />,
                permission: ['report-sales-performances'],
                sidebar: true,
                icon: null,
                title: 'title.ReportSalesPerformances',
            },
            {
                path: "/pairing-log",
                component: <PairingLog />,
                permission: ['report-pairing-log'],
                sidebar: true,
                icon: null,
                title: 'title.ReportPairingLog',
            },
            {
                path: "/monthly-report",
                component: <MonthlyReport />,
                permission: ['report-monthly'],
                sidebar: true,
                icon: null,
                title: 'title.monthlyReports',
            },
            {
                path: "/audit-log",
                component: <AuditLog />,
                permission: ['report-audit-log'],
                sidebar: true,
                icon: null,
                title: 'title.auditLog',
            },
            /*{
                path: "/account-link",
                component: <AccountLinkListing />,
                permission: ['report-account-link'],
                sidebar: true,
                icon: null,
                title: 'title.reportAccountLink',
            }, */
           /*  {
                path: "/third-party-convert",
                component: <ThirdPartyConvertListing />,
                permission: [],
                sidebar: true,
                icon: null,
                title: 'title.thridPartyConvert',
            }, */
            /* {
                path: "/third-party-payment-order",
                component: <ThirdPartyPaymentOrderListing />,
                permission: [],
                sidebar: true,
                icon: null,
                title: 'title.paymentOrder',
            }, */
        ],
    },
    // MEMBER
    {
        category: true,
        categoryName: 'member',
        categoryLabel: 'title.member',
        categoryIcon: <MdPeopleAlt size="20px" />,
        categoryPermission: ['member', 'member-sponsor-genealogy'],
        categorySidebar: true,
        children: [
            {
                path: "/members",
                component: <Member />,
                permission: ['member'],
                sidebar: true,
                icon: null,
                title: 'title.member',
            },
            {
                path: "/member-add",
                component: <MemberCreate />,
                permission: ['member-create'],
                sidebar: false,
                icon: null,
                title: 'title.memberAdd',
            },
            {
                path: "/member-edit/:id",
                component: <MemberEdit />,
                permission: ['member-edit'],
                sidebar: false,
                icon: null,
                title: 'title.memberEdit',
            },
            {
                path: "/sponsor-genealogy",
                component: <SponsorGenealogy />,
                permission: ['member-sponsor-genealogy'],
                sidebar: true,
                icon: null,
                title: 'title.sponsor',
            },
            {
                path: "/placement-genealogy",
                component: <GenealogyPlacement />,
                permission: ['member-sponsor-genealogy'],
                sidebar: true,
                icon: null,
                title: 'title.placement',
            },
        ]
    },
    // MERCHANT
    {
        category: true,
        categoryName: 'merchant',
        categoryLabel: 'title.merchant',
        categoryIcon: <MdHomeFilled size="20px" />,
        categoryPermission: ['merchant','report-merchant-transaction','merchant-transaction-create','trade-merchant-profile','trade-merchant-profile-adjust', 'merchant-withdraw', 'merchant-trade-withdraw-list', 'setting'],
        categorySidebar: true,
        children: [
            {
                path: "/merchant",
                component: <Merchant />,
                permission: ['merchant'],
                sidebar: true,
                icon: null,
                title: 'title.merchant',
            },
            {
                path: "/merchant-add",
                component: <MerchantCreate />,
                permission: ['merchant-create'],
                sidebar: false,
                icon: null,
                title: 'title.merchantAdd',
            },
            {
                path: "/merchant-edit/:id",
                component: <MerchantDetail />,
                permission: ['merchant-edit'],
                sidebar: false,
                icon: null,
                title: 'title.merchantEdit',
            },
            {
                path: "/merchant-transaction",
                component: <MerchantTransactionList />,
                permission: ['report-merchant-transaction'],
                sidebar: true,
                icon: null,
                title: 'title.reportMerchantTransaction',
            },
            {
                path: "/trade-merchant-profile",
                component: <MerchantTradeProfile />,
                permission: ['trade-merchant-profile'],
                sidebar: true,
                icon: null,
                title: 'title.tradeMerchantProfile',
            },
            {
                path: "/trade-merchant-profile-adjustment",
                component: <MerchantProfileAdjustment />,
                permission: ['trade-merchant-profile-adjust'],
                sidebar: false,
                icon: null,
                title: 'title.tradeMerchantProfileAdjustment',
            },
            // {
            //     path: "/trade-merchant-withdraw",
            //     component: <MerchantWithdraw />,
            //     permission: ['merchant-withdraw'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.merchantWithdraw',
            // },
            // {
            //     path: "/trade-merchant-withdrawals-list",
            //     component: <MerchantWithdrawalsList />,
            //     permission: ['merchant-trade-withdraw-list'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.merchantWithdrawListTitle',
            // },
            // {
            //     path: "/trade-merchant-withdrawals-setting",
            //     component: <MerchantWithdrawalSetting />,
            //     permission: ['setting'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.merchantWithdrawalSettingTitle',
            // },
            // {
            //     path: "/trade-merchant-strategies-withdrawals-setting",
            //     component: <MerchantStrategiesWithdrawalSetting />,
            //     permission: ['setting'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.merchantStrategiesWithdrawalSettingTitle',
            // },
        ]
    },
    // SETTING
    {
        category: true,
        categoryName: 'setting',
        categoryLabel: 'title.setting',
        categoryIcon: <MdSettings size="20px" />,
        categoryPermission: ['rank', 'bonus', 'role', 'setting', 'wallet-setting', 'wallet-setting-create', 'wallet-setting-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/ranks",
                component: <Rank />,
                permission: ['rank'],
                sidebar: true,
                icon: null,
                title: 'title.ranks',
            },
            {
                path: "/rank-add",
                component: <RankDetail />,
                permission: ['rank-create'],
                sidebar: false,
                icon: null,
                title: 'title.rankAdd',
            },
            {
                path: "/rank-edit/:id",
                component: <RankDetail />,
                permission: ['rank-edit'],
                sidebar: false,
                icon: null,
                title: 'title.rankEdit',
            },
            {
                path: "/bonus",
                component: <Bonus />,
                permission: ['bonus'],
                sidebar: true,
                icon: null,
                title: 'title.bonus',
            },
            {
                path: "/bonus-add",
                component: <BonusDetail />,
                permission: ['bonus-create'],
                sidebar: false,
                icon: null,
                title: 'title.bonusAdd',
            },
            {
                path: "/bonus-edit/:id",
                component: <BonusDetail />,
                permission: ['bonus-edit'],
                sidebar: false,
                icon: null,
                title: 'title.bonusEdit',
            },
            {
                path: "/roles",
                component: <Role />,
                permission: ['role'],
                sidebar: true,
                icon: null,
                title: 'title.roles',
            },
            {
                path: "/role-add",
                component: <RoleDetail />,
                permission: ['role-create'],
                sidebar: false,
                icon: null,
                title: 'title.roleCreate',
            },
            {
                path: "/role-edit/:id",
                component: <RoleDetail />,
                permission: ['role-edit'],
                sidebar: false,
                icon: null,
                title: 'title.roleEdit',
            },
            {
                path: "/settings",
                component: <Setting />,
                permission: ['setting'],
                sidebar: true,
                icon: null,
                title: 'title.setting',
            },
            {
                path: "/setting-add",
                component: <SettingDetail />,
                permission: ['setting-create'],
                sidebar: false,
                icon: null,
                title: 'title.settingCreate',
            },
            {
                path: "/setting-edit/:id",
                component: <SettingDetail />,
                permission: ['setting-edit'],
                sidebar: false,
                icon: null,
                title: 'title.settingEdit',
            },
            {
                path: "/wallet-setting",
                component: <WalletSetting />,
                permission: ['wallet-setting'],
                sidebar: true,
                icon: null,
                title: 'title.walletSetting',
            },
            {
                path: "/wallet-setting-add",
                component: <WalletSettingDetail />,
                permission: ['wallet-setting-create'],
                sidebar: false,
                icon: null,
                title: 'title.walletSettingCreate',
            },
            {
                path: "/wallet-setting-edit/:id",
                component: <WalletSettingDetail />,
                permission: ['wallet-setting-edit'],
                sidebar: false,
                icon: null,
                title: 'title.walletSettingEdit',
            },
        ]
    },
    // STAFF
    /* {
        category: true,
        categoryName: 'staff',
        categoryLabel: 'title.staff',
        categoryIcon: null,
        categoryPermission: ['staff', 'staff-create', 'staff-edit'],
        categorySidebar: true,
        children: [
            {
                path: "/staffs",
                component: <Staff/>,
                permission: ['staff'],
                sidebar: true,
                icon: null,
                title: 'title.staff',
            },
            {
                path: "/staff-add",
                component: <StaffCreate />,
                permission: ['staff-create'],
                sidebar: false,
                icon: null,
                title: 'title.adminAdd',
            },
            {
                path: "/staff-edit/:id",
                component: <StaffEdit />,
                permission: ['staff-edit'],
                sidebar: false,
                icon: null,
                title: 'title.staffEdit',
            },
        ],
    }, */
];

function Copyright() {
    return (
        <Typography variant="body2" color="white" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/">
                {process.env.REACT_APP_APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

function PrivateRoute({ children, ...rest }) {
    let location = useLocation();
    const { accessToken } = useSelector(state => state.general);
    return (
        accessToken ? (
            children
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    );
}


export default function App(props) {
    const theme = useTheme();
    const { accessToken, permissions } = useSelector(state => state.general);

    const Wrapper = (children, sidebar) => (
        <div>
            <NotificationLoadingProvider>
                <div>
                    {
                        sidebar ?
                            <Sidebar routeProps={routes} content={
                                <>
                                    <Container maxWidth="lg">
                                        {children}
                                        <Box pt={2} pb={5}>
                                            <Copyright />
                                        </Box>
                                    </Container>


                                </>
                            } />
                            :
                            <>
                                {children}
                                <Box pt={2} pb={5}>
                                    <Copyright />
                                </Box>
                            </>
                    }
                </div>
                <NotificationLoading />
            </NotificationLoadingProvider>
        </div>
    );

    return (
        <Fragment>
            <div style={{ backgroundColor: theme.palette.background.main  }}>
                <Routes>
                    <Route path="/login" element={accessToken ? <Navigate replace to="/dashboard" /> : Wrapper(<Login />, false)} />
                    <Route path="/forget-password" element={Wrapper(<ForgetPassword />, false)} />
                    <Route path="/reset-password" element={Wrapper(<ResetPassword />, false)} />
                    {
                        routes.map(category => {
                            return (
                                _.map(category.children, (route) => {
                                    let fulfilledAllPermission = true;
                                    if (_.size(route.permission) > 0) {
                                        _.map(route.permission, (contentPermission) => {
                                            if (!_.includes(permissions, contentPermission)) {
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    }
                                    return <Route
                                        key={route.path}
                                        path={route.path}
                                        element={fulfilledAllPermission ? <PrivateRoute>
                                            {Wrapper(route.component, true)}
                                        </PrivateRoute> : <Navigate replace to="/dashboard" />} />
                                })
                            )
                        })
                    }
                    <Route path="*" element={accessToken ? <Navigate replace to="/dashboard" /> : <Navigate replace to="/login" />} />
                </Routes>
            </div>
        </Fragment>
    );
};