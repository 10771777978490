import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/plans?status=all`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `plan-edit`) ?
                    <Link underline='none' to={`/plan-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            // use for export, hide for display
            header: `${t(`plans.name`)}`,
            accessorKey: 'name.' + i18n.language,
        },
        {
            // use for display, hide for export
            accessorKey: 'name',
            header: `${t('plans.name')}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.name[i18n.language] ? cell.row.original.name[i18n.language] : cell.row.original.name.en}</>
            ),
            hideExport: true,
        },
        {
            accessorFn: (row) => (row.bv ? row.bv : '-'),
            id: 'bv',
            header: `${t('plans.bv')}`,
        },
        {
            accessorFn: (row) => (row.price ? row.price : '-'),
            id: 'price',
            header: `${t('plans.price')}`,
        },
        {
            accessorFn: (row) => (row.trade_limit ? row.trade_limit : '-'),
            id: 'trade_limit',
            header: `${t('plans.tradeLimit')}`,
        },
        {
            accessorFn: (row) => (row.profit_limit ? row.profit_limit : '-'),
            id: 'profit_limit',
            header: `${t('plans.profitLimit')}`,
        },
        {
            accessorKey: 'status',
            header: `${t('plans.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`plans.status_${cell.row.original.status}`)}</>
            ),
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.plan`)}</b></Typography>
                    {_.includes(permissions, `plan-create`) ?
                        <Link underline='none' to={`/plan-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.plan`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'PlanList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['name.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing