import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

const MAIN_COLOR = '#D4A964';
const SECONDARY_COLOR = '#ffffff';
const SHORT_GOLD = 'linear-gradient(280deg,#b58947,#a3732f,#ffeaa5,#cea253,#82561d,#a07a3f)';
const LONG_GOLD = 'linear-gradient(280deg, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f)';
const BLACK_COLOR= '#111115';

let theme = createTheme({
    palette: {
        primary: {
            main: MAIN_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        background: {
            main: BLACK_COLOR,
        },
        gray: {
            main: '#848484',
            secondary: '#b5b5b5',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
            tableHeaderIcon: '#ffffff45',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: '#ffffff',
            borderMain: '2px solid',
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        title: {
            main: SECONDARY_COLOR,
        },
        noticeText: {
            main: '#848484',
        }
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: SECONDARY_COLOR,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: SECONDARY_COLOR,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    // background: 'linear-gradient(280deg, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f)',
                    // WebkitBackgroundClip: 'text',
                    // WebkitTextFillColor: 'transparent',
                    // backgroundClip: 'text',
                    // textFillColor: 'transparent',
                    color: MAIN_COLOR,
                    '&.MuiDayCalendar-weekDayLabel': {
                        color: MAIN_COLOR,
                    },
                    '&.MuiTypography-root.MuiTypography-overline':{
                        color: MAIN_COLOR,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    background: BLACK_COLOR,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(280deg, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    '&.Mui-disabled': {
                        color: SECONDARY_COLOR,
                        background: 'unset',
                        WebkitBackgroundClip: 'unset',
                        WebkitTextFillColor: 'unset',
                        backgroundClip: 'unset',
                        textFillColor: 'unset',
                    },
                    '&:hover':{
                        background: MAIN_COLOR,
                        color: SECONDARY_COLOR,
                        WebkitBackgroundClip: 'unset',
                        WebkitTextFillColor: 'unset',
                        backgroundClip: 'unset',
                        textFillColor: 'unset',
                    },
                    '&.Mui-selected:hover': {
                        background: MAIN_COLOR,
                        color: SECONDARY_COLOR,
                        opacity: '.7',
                        WebkitBackgroundClip: 'unset',
                        WebkitTextFillColor: 'unset',
                        backgroundClip: 'unset',
                        textFillColor: 'unset',
                    },
                    '&.Mui-selected': {
                        background: MAIN_COLOR,
                        color: SECONDARY_COLOR,
                        opacity: '.3',
                        WebkitBackgroundClip: 'unset',
                        WebkitTextFillColor: 'unset',
                        backgroundClip: 'unset',
                        textFillColor: 'unset',
                    }
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: SECONDARY_COLOR,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:disabled': {
                        color: '#ffffff45',
                        background: '#ffffff17',
                    }
                },
            },
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        background: 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
                        border: '1px solid transparent',
                        backgroundOrigin: 'border-box',
                        borderWidth: 1,
                        borderColor: 'transparent',
                        boxShadow: 'none',
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        background: `linear-gradient(${BLACK_COLOR},${BLACK_COLOR}) padding-box,linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f)`,
                        // border: '2px solid transparent',
                        backgroundOrigin: 'border-box',
                        borderWidth: 1,
                        borderColor: 'transparent',
                    }
                }
            ]
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: '#3A3A3C', // Default border color
                    },
                    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
                        borderColor: MAIN_COLOR, // Hover border color
                        "@media (hover: none)": {
                            borderColor: MAIN_COLOR, // Same for devices that don't support hover
                        },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: MAIN_COLOR // Focused border color
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: MAIN_COLOR,   // Custom border color for disabled outline
                    },
                },
                input: {
                    "&.Mui-disabled": {
                        color: MAIN_COLOR,  // Custom text color when disabled
                        WebkitTextFillColor: MAIN_COLOR,
                    },
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Default state of text input field font.
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: MAIN_COLOR,
                    },
                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: MAIN_COLOR,
                    },
                    '& .MuiInputBase-root': {
                        color: MAIN_COLOR,
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: MAIN_COLOR,
                    },
                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: MAIN_COLOR,
                        WebkitTextFillColor: MAIN_COLOR,
                    },
                    '& .MuiSvgIcon-root': {
                        color: MAIN_COLOR,
                    },
                    '&.custom-readonly-field': {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: MAIN_COLOR,
                            },
                        },
                    },
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: MAIN_COLOR,
                        borderRadius: '0px !important',
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                    '&::placeholder': {
                        color: MAIN_COLOR,
                        opacity: 1
                    }
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                separator: {
                    color: MAIN_COLOR,
                },
                root: {
                    li: {
                        background: SHORT_GOLD,
                        // background: LONG_GOLD,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    },
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: `linear-gradient(${BLACK_COLOR},${BLACK_COLOR}) padding-box,linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f)`,
                    border: '2px solid transparent',
                    backgroundOrigin: 'border-box',
                    borderWidth: 1,
                    borderColor: 'transparent',
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: SECONDARY_COLOR,
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                },
                icon: {
                    color: 'unset',
                    '&.Mui-disabled': {
                        color: MAIN_COLOR,
                    }
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked': {
                        // color: '',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        // backgroundColor: '',
                    },
                },
                track: {
                    backgroundColor: SECONDARY_COLOR,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                option, optgroup {
                    background-color: ${BLACK_COLOR} !important; 
                }
            `,
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#848484',
                },
            },
        },
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    backgroundColor: BLACK_COLOR, 
                    border: `1px solid ${MAIN_COLOR}`,
                    '& .MuiDivider-root':{
                        borderColor: MAIN_COLOR,
                    },
                    color: MAIN_COLOR,
                },
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: MAIN_COLOR,
                    '&.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
                        color: '#ffffff45',
                    },
                    '&.MuiPickersDay-today': {
                        borderColor: MAIN_COLOR,
                        border: '1px solid',
                    },
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    color: SECONDARY_COLOR
                }
            }
        },
        MuiInput:{
            styleOverrides:{
                root:{
                    "&:before":{
                        borderBottom: '1px solid #ffffff45',
                    },
                    '&:hover:not(.Mui-disabled):before':{
                         borderBottom: `1px solid ${MAIN_COLOR}`,
                    },
                    '& .MuiInputBase-input.MuiInput-input':{
                        color: MAIN_COLOR,
                    },
                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: MAIN_COLOR,
                    },
                    '& .MuiInputBase-root': {
                        color: MAIN_COLOR,
                    },
                }
            }
        },
        MuiInputAdornment:{
            styleOverrides:{
                root:{
                    color: MAIN_COLOR,
                }
            }
        },
    },
})

theme = responsiveFontSizes(theme);

export default theme;