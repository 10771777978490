import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, InputAdornment, Chip, Select, MenuItem, FormControl,InputLabel, FormHelperText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

const Detail = () => {
    const { t, i18n} = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({});
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [state, setState] = useState({
        code: '',
        name: { en: "", cn: "" },
        status: '1',
        desc: '',
        type: ''
    });
    const [typeListing, setTypeListing] = useState([]);
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/rank-add` ? "add" : "edit";

    const handleChange = ({ target }) => {
        let { name, value } = target;
    
        if (name === 'code') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
    
        if (name === 'name.en' || name === 'name.cn') {
            const lang = name.split('.')[1]; // Extract language from the field name
            setState({ ...state, name: { ...state.name, [lang]: value } });
        } else {
            setState({ ...state, [name]: value });
        }
    };    
    
    useEffect(() => {
        getUrl(`/ranks-option`).then(response => {
            if (response.status) {
                setTypeListing(response.data.type_list)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (formType === 'edit' && id) {
            getUrl(`/ranks/${id}`).then(response => {
                if (response.status) {
                    setState({
                        code: response.data.code,
                        name: response.data.name,
                        status: response.data.status.toString(),
                        desc: response.data.desc,
                        type:response.data.type
                    });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setState({
                code: '',
                name: { en: "", cn: "" },
                status: '1',
                desc: '',
                type: ''
            });
        }
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            code: state.code,
            name: state.name,
            status: state.status,
            desc: state.desc,
            type:state.type
        }
        if (formType === 'edit' && id) {
            putUrl(`/ranks/${id}`, updateData).then(response => {
                setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/ranks`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/rank-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.rank${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" component={RouterLink} to={`/ranks`}>
                            {t(`title.ranks`)}
                        </Link>
                        <Typography >{t(`title.rank${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={formType === 'edit' ? 4 : 6}>
                    <Box  sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`ranks.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={formType === 'edit' ? 4 : 6}>
                    <FormControl fullWidth variant="outlined"  style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="status-label">{t(`ranks.type`)}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="type"
                            value={state.type}
                            label={t(`ranks.type`)}
                            onChange={handleChange}
                            name="type"
                            error={inputErrors && inputErrors.type ? true : false}
                           
                        >
                            <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                            {
                                typeListing && 
                                _.map(typeListing, (type , value) =>{
                                    return (
                                        <MenuItem value={value} key={value}>{type[i18n.language]}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                        {
                            inputErrors && inputErrors?.type &&
                            <FormHelperText style={{ color: 'red' }}>{inputErrors?.type}</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={formType === 'edit' ? 4 : 6}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="standard-basic"
                            label={t('ranks.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.title]: target.value } })}
                            value={state.name && state.name[fieldLang.title] ? state.name[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                {formType === 'edit' && (
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                            <InputLabel id="status-label">{t(`ranks.status`)}</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                value={state.status}
                                label={t(`ranks.status`)}
                                onChange={handleChange}
                                name="status"
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                <MenuItem value="1">{t('ranks.active')}</MenuItem>
                                <MenuItem value="0">{t('ranks.inactive')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`ranks.desc`)}
                            variant="outlined"
                            value={state.desc}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.desc ? inputErrors.desc : ''}
                            error={inputErrors && inputErrors.desc ? true : false}
                            name="desc"
                            onChange={({ target }) => setState({ ...state, desc: target.value })}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/ranks`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default Detail