import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const Bonuses = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [wallets, setWallets] = useState({});
    const [ranks, setRanks] = useState({});
    const [distribute, setDistribute] = useState([]);
    const [status, setStatus] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [distributeType, setDistributeType] = useState([
        {code: "real", label: t('bonus.distribute.real')}, 
        {code: "daily", label: t('bonus.distribute.daily')}, 
        {code: "monthly", label: t('bonus.distribute.monthly')}
    ]);

    const [statusList, setStatusList] = useState([
        {code: 0, label: t('bonus.status.0')},
        {code: 1, label: t('bonus.status.1')},
    ]);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/bonuses`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setWallets(response.data.wallets);
                setRanks(response.data.ranks);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newDistributeList = [];
        _.map(distributeType, (type, key) => {
            let textDisplay = type.label;
            newDistributeList.push({ text: textDisplay, value: type.code });
        })
        setDistribute(newDistributeList);

        let newStatusList = [];
        _.map(statusList, (status, key) => {
            let textDisplay = status.label;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatus(newStatusList);
        // eslint-disable-next-line
    }, [distributeType, statusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `bonus-edit`) ?
                    <Link underline='none' to={`/bonus-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            accessorKey: 'bonus_type',
            header: `${t('bonus.bonusType')}`,
        },
        {
            // use for export, hide for display
            header: `${t(`bonus.name`)}`,
            accessorKey: 'name.' + i18n.language,
        },
        {
            // use for display, hide for export
            accessorKey: 'name',
            header: `${t('bonus.name')}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.name[i18n.language] ? cell.row.original.name[i18n.language] : cell.row.original.name.cn}</>
            ),
            hideExport: true,
        },
        {
            accessorKey: 'ranks_display',
            header: `${t('bonus.rankPercent')}`,
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <>
                    {
                        _.map(cell.row.original.ranks_display, (detail, key) => {
                            let rank = _.find(ranks, {id: detail.id});
                            if (rank) { return (<Typography style={{ fontSize: 14 }} key={key}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.cn}: {detail.percent}%</Typography>) } 
                            else { return (<Typography style={{ fontSize: 14, textTransform: 'capitalize' }} key={key}>{detail.id}: {detail.percent}%</Typography>) }
                        })
                    }
                </>
            ),
        },
        {
            accessorKey: 'wallets_display',
            header: `${t('bonus.wallets')}`,
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <>
                    {
                        _.map(cell.row.original.wallets_display, (detail, key) => {
                            let wallet = _.find(wallets, {id: detail.id});
                            return (<Typography style={{ fontSize: 14 }} key={key}>{wallet?.name ? wallet?.name?.[i18n.language] : wallet?.name?.cn}: {detail.percent}%</Typography>)
                        })
                    }
                </>
            ),
        },
        {
            accessorFn: (row) => (row.level ? row.level : t('bonus.unlimited')),
            id: 'level',
            header: `${t('bonus.level')}`,
        },
        {
            accessorKey: 'distribute_type',
            header: `${t('bonus.distributeType')}`,
            filterFn: 'equals',
            filterSelectOptions: distribute,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`bonus.distribute.${cell.row.original.distribute_type}`)}</>
            ),
        },
        {
            accessorKey: 'limits_display',
            header: `${t('bonus.limit')}`,
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <>
                    {
                        _.size(cell.row.original.limits_display) > 0
                            ? _.map(cell.row.original.limits_display, (detail, key) => {
                                let rank = _.find(ranks, { id: detail.id });
                                if (rank) { return (<Typography style={{ fontSize: 14 }} key={key}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.cn}: {detail.value}</Typography>) }
                                else { return (<Typography style={{ fontSize: 14, textTransform: 'capitalize' }} key={key}>{detail.id}: {detail.value}</Typography>) }
                            })
                            : '-'
                    }
                </>
            ),
        },
        {
            accessorKey: 'status',
            header: `${t('bonus.status.title')}`,
            filterFn: 'equals',
            filterSelectOptions: status,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`bonus.status.${cell.row.original.status}`)}</>
            ),
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, ranks, wallets, distribute, status]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.bonus`)}</b></Typography>
                    {_.includes(permissions, `bonus-create`) ?
                        <Link underline='none' to={`/bonus-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.bonus`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'BonusList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['name.' + i18n.language]: false }}
            />
        </>
    )
}

export default Bonuses