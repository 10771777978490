import BackToPrevious from '@layouts/BackToPrevious';
import { Box, Breadcrumbs, Button, Chip, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography , FormGroup , FormControlLabel , Switch, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const INITIAL_STATE = {
    description: '',
    name: { en: "", cn: "" },
    status: ``,
    country: '',
    mobile_code: '',
    mobile: '',
    website: '',
    email: '',
    user_id: '',
    company_register_number: '',
    bussiness_nature: '',
    about_shop: '',
    product_sell: '',
    code: '',
    address: {
        user_name: '',
        user_mobile: '',
        address1: '',
        address2: '',
        zip: '',
        address_name: '',
        country: '',
        state: '',
        city: '',
    },
    ic_front: '',
    ic_back: '',
    support_doc: {},
    new_logo: {},
    new_banner: {},
    merchant_type: '',
    // extra_setting: { convert: { available: true } , display_setting:{show_member: true} }
    extra_setting: { display_setting:{show_member: true} }
}

const Create = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const [statusList, setStatusList] = useState([]);
    const [inputErrors, setInputErrors] = useState({});
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [addressOption, setAddressOption] = useState();
    const [filteredStateList, setFilteredStateList] = useState([]);
    const [filteredCityList, setFilteredCityList] = useState([]);
    const [userList, setUserList] = useState({});
    const [merchantType, setMerchantType] = useState({});
    const navigate = useNavigate();

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    useEffect(() => {
        getUrl(`/merchants-options`).then(response => {
            if (response.status) {
                setStatusList(response.data);
                // setUserList(response.users);
                setMerchantType(response.type);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/address-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setAddressOption(response.addressList)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        const mergedState = {
            ...state,
            country: userMobile.country,
            mobile: userMobile.mobile,
            mobile_code: userMobile.code,
        };
        const formData = new FormData();
        buildFormData(formData, mergedState);
        postUrl(`/merchants`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/merchant-edit/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value, files , checked } = target;
        switch (name) {
            case 'country':
                if (value !== 0) {
                    let tempStateList = _.filter(addressOption?.stateList, function (o) {
                        return o.country_code === value;
                    });
                    setFilteredStateList(tempStateList);
                    setState({ ...state, address: { ...state.address, country: value, city: '', state: '' } });
                } else {
                    setState({ ...state, address: { ...state.address, country: '', city: '', state: '' } });
                }
                break;

            case 'state':
                if (value !== 0) {
                    let tempCityList = _.filter(addressOption?.cityList, function (o) {
                        return parseInt(o.state_id) === parseInt(value);
                    });
                    setFilteredCityList(tempCityList);
                    setState({ ...state, address: { ...state.address, city: '', state: value.toString() } });
                } else {
                    setState({ ...state, address: { ...state.address, city: '', state: '' } });
                }
                break;

            case 'zip':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, address: { ...state.address, zip: value } });
                break;
            case 'addressUserName':
                setState({ ...state, address: { ...state.address, user_name: value } });
                break;
            case 'addressName':
                setState({ ...state, address: { ...state.address, address_name: value } });
                break;
            case 'address1':
                setState({ ...state, address: { ...state.address, address1: value } });
                break;
            case 'address2':
                setState({ ...state, address: { ...state.address, address2: value } });
                break;
            case 'city':
                setState({ ...state, address: { ...state.address, city: value } });
                break;
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setUserMobile({ ...userMobile, [name]: value });
                break;
            case 'name.en':
            case 'name.cn':
                const lang = name.split('.')[1]; // Extract language from the field name
                setState({ ...state, name: { ...state.name, [lang]: value } });
                break;
            case 'addressMobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, address: { ...state.address, user_mobile: value } });
                break;
            case 'new_logo':
                if (files[0]?.size > 5242880) {
                    target.value = null;
                    setState({ ...state, new_logo: null })
                    setInputErrors({ 'new_logo': t('general.maxFileSize', { S: 5120 }) })
                    addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
                } else {
                    setInputErrors({})
                    setState({ ...state, new_logo: files[0] })
                }
                break;
            case 'new_banner':
                if (files[0]?.size > 5242880) {
                    target.value = null;
                    setState({ ...state, new_banner: null })
                    setInputErrors({ 'new_banner': t('general.maxFileSize', { S: 5120 }) })
                    addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
                } else {
                    setInputErrors({})
                    setState({ ...state, new_banner: files[0] })
                }
                break;
            case 'ic_back':
            case 'ic_front':
                if (files[0]?.size > 5242880) {
                    target.value = null;
                    setState({ ...state, [name]: null })
                    setInputErrors({ [name]: t('general.maxFileSize', { S: 5120 }) })
                    addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
                } else {
                    setInputErrors({})
                    setState({ ...state, [name]: files[0] })
                }
                break;
            case 'convert':
                setState(preState => ({
                    ...preState,
                    extra_setting: { ...preState.extra_setting, convert: { available: checked } }
                }));
                break;
            case 'show_member':
                setState(preState => ({
                    ...preState,
                    extra_setting: {
                        ...preState.extra_setting,
                        display_setting: { [name]: checked },
                    }
                }));
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleDropZoneChange = (file) => {
        setState({
            ...state,
            support_doc: file
        })
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.merchantAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/merchant`}>
                            {t(`title.merchant`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.merchantAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('merchant.logo')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.new_logo ? inputErrors.new_logo : t('general.maxFileSize', { S: 5120 })}
                            error={inputErrors && inputErrors.new_logo ? true : false}
                            name={`new_logo`}
                            inputProps={{ accept: ["image/*"] }}
                            onChange={handleChange}
                            type='file'
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('merchant.banner')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.new_banner ? inputErrors.new_banner : t('general.maxFileSize', { S: 5120 })}
                            error={inputErrors && inputErrors.new_banner ? true : false}
                            name={`new_banner`}
                            inputProps={{ accept: ["image/*"] }}
                            onChange={handleChange}
                            type='file'
                        />
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="standard-basic"
                            label={t('merchant.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.title]: target.value } })}
                            value={state.name && state.name[fieldLang.title] ? state.name[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.crn`)}
                            variant="outlined"
                            value={state.company_register_number}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.company_register_number ? inputErrors.company_register_number : ''}
                            error={inputErrors && inputErrors.company_register_number ? true : false}
                            name="company_register_number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                            <InputLabel>{t(`user.country`)}</InputLabel>
                            <Select
                                value={userMobile.country || ''}
                                label={t(`user.country`)}
                                name="country"
                                onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0} disabled>
                                    --- {`${t('general.pleaseSelect')} ${t(`user.country`)} `} ---
                                </MenuItem>
                                {
                                    _.map(mobileCodeList.country, (data, key) => {
                                        const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                        return (
                                            <MenuItem key={key} value={data.code}>
                                                {countryName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors['country'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                            }
                            <Typography variant="caption" style={{ display: 'flex', textAlign: 'left' , color: theme.palette.noticeText.main }}>{t('general.pleaseSelectCountry')}</Typography>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                            <InputLabel>{t(`user.mobileCode`)}</InputLabel> 
                            <Select
                                value={userMobile.code || ''}
                                label={t(`user.mobileCode`)}
                                name="mobileCode"
                                onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                displayEmpty
                            >
                                {/* <MenuItem value="">
                                    {``}
                                </MenuItem> */}
                                {_.size(mobileCodeList?.code) > 0 ? (
                                    _.map(mobileCodeList?.code, (option, key) => (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" style={{ color: '#222' }}>
                                        {``}
                                    </MenuItem>
                                )}
                            </Select>
                            {
                                inputErrors && inputErrors['mobile_code'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('merchant.mobile')}
                            variant="outlined"
                            value={userMobile.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(statusList) > 0 &&
                    <Grid item xs={12} sm={6} md={6} >
                        <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                            <InputLabel id="status-label">{t(`merchant.status`)}</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                value={state.status}
                                label={t(`merchant.status`)}
                                onChange={handleChange}
                                name="status"
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                {
                                    _.map(statusList, (status, key) => {
                                        return (
                                            <MenuItem value={key} key={key}>{status[i18n.language]}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.email`)}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.website`)}
                            variant="outlined"
                            value={state.website ? state.website : ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.website ? inputErrors.website : ''}
                            error={inputErrors && inputErrors.website ? true : false}
                            name="website"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.bn`)}
                            variant="outlined"
                            value={state.bussiness_nature}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bussiness_nature ? inputErrors.bussiness_nature : ''}
                            error={inputErrors && inputErrors.bussiness_nature ? true : false}
                            name="bussiness_nature"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.aboutShop`)}
                            variant="outlined"
                            value={state.about_shop}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.about_shop ? inputErrors.about_shop : ''}
                            error={inputErrors && inputErrors.about_shop ? true : false}
                            name="about_shop"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.productSell`)}
                            variant="outlined"
                            value={state.product_sell}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.product_sell ? inputErrors.product_sell : ''}
                            error={inputErrors && inputErrors.product_sell ? true : false}
                            name="product_sell"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.username`)}
                            variant="outlined"
                            value={state.user_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_id ? inputErrors.user_id : ''}
                            error={inputErrors && inputErrors.user_id ? true : false}
                            name="user_id"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    // _.size(userList) > 0 &&
                    // <Grid item xs={12} sm={6} md={6} >
                    //     <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }} error={inputErrors && inputErrors.user_id ? true : false}>
                    //         <InputLabel id="status-label">{t(`merchant.ownerId`)}</InputLabel>
                    //         <Select
                    //             labelId="status-label"
                    //             value={state.user_id}
                    //             label={t(`merchant.ownerId`)}
                    //             onChange={handleChange}
                    //             name="user_id"
                    //             error={inputErrors && inputErrors.user_id ? true : false}
                    //         >
                    //             {
                    //                 _.map(userList, (user, key) => {
                    //                     return (
                    //                         <MenuItem value={user.id} key={key}>{user.username}</MenuItem>
                    //                     )
                    //                 })
                    //             }

                    //         </Select>
                    //         {
                    //             inputErrors && inputErrors.user_id &&
                    //             <FormHelperText style={{ color: 'red' }}>{inputErrors.user_id}</FormHelperText>
                    //         }
                    //     </FormControl>
                    // </Grid>
                }
                {
                    // _.size(userList) > 0 &&
                    <Grid item xs={12} sm={6} md={6} >
                        <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }} error={inputErrors && inputErrors.merchant_type ? true : false}>
                            <InputLabel id="status-label">{t(`merchant.merchantType`)}</InputLabel>
                            <Select
                                labelId="status-label"
                                value={state.merchant_type}
                                label={t(`merchant.merchantType`)}
                                onChange={handleChange}
                                name="merchant_type"
                                error={inputErrors && inputErrors.merchant_type ? true : false}
                            >
                                {
                                    _.map(merchantType, (type, key) => {
                                        return (
                                            <MenuItem value={key} key={key}>{type.name?type.name[i18n.language]?type.name[i18n.language]:type.name['en']:''}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                            {
                                inputErrors && inputErrors.merchant_type &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors.merchant_type}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={12}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.desc`)}
                            variant="outlined"
                            value={state.description}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                            error={inputErrors && inputErrors.description ? true : false}
                            name="description"
                            onChange={({ target }) => setState({ ...state, description: target.value })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('merchant.Address')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.name`)}
                            variant="outlined"
                            value={state.address.user_name || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.user_name'] ? inputErrors['address.user_name'] : ''}
                            error={inputErrors && inputErrors['address.user_name'] ? true : false}
                            name="addressUserName"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.mobile`)}
                            variant="outlined"
                            value={state.address.user_mobile || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.user_mobile'] ? inputErrors['address.user_mobile'] : ''}
                            error={inputErrors && inputErrors['address.user_mobile'] ? true : false}
                            name="addressMobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.addressName`)}
                            variant="outlined"
                            value={state.address.address_name || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.address_name'] ? inputErrors['address.address_name'] : ''}
                            error={inputErrors && inputErrors['address.address_name'] ? true : false}
                            name="addressName"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.address`)}
                            variant="outlined"
                            value={state.address.address1 || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.address1'] ? inputErrors['address.address1'] : ''}
                            error={inputErrors && inputErrors['address.address1'] ? true : false}
                            name="address1"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={state.country === 'CN' ? t(`user.province`) : t(`user.address2`)}
                            variant="outlined"
                            value={state.address.address2 || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.address2'] ? inputErrors['address.address2'] : ''}
                            error={inputErrors && inputErrors['address.address2'] ? true : false}
                            name="address2"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.zip`)}
                            variant="outlined"
                            value={state.address.zip || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['address.zip'] ? inputErrors['address.zip'] : ''}
                            error={inputErrors && inputErrors['address.zip'] ? true : false}
                            name="zip"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(addressOption?.countryList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors['address.country'] ? true : false}>
                                <InputLabel>{t(`user.country`)}</InputLabel>
                                <Select
                                    value={state.address?.country || ''}
                                    label={t(`user.country`)}
                                    name="country"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`user.country`)} `} --- </MenuItem>
                                    {
                                        _.map(addressOption?.countryList, (data, key) => {
                                            return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors['address.country'] &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors['address.country']}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    _.size(filteredStateList) > 0 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth error={inputErrors && inputErrors['address.state'] ? true : false}>
                                    <InputLabel>{t(`user.state`)}</InputLabel>
                                    <Select
                                        value={state.address?.state || ''}
                                        label={t(`user.state`)}
                                        name="state"
                                        onChange={handleChange}
                                        displayEmpty
                                    >
                                        <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`user.state`)} `} --- </MenuItem>
                                        {
                                            _.map(filteredStateList, (data, key) => {
                                                return <MenuItem key={key} value={data.id}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    {
                                        inputErrors && inputErrors['address.state'] &&
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors['address.state']}</FormHelperText>
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`user.state`)}
                                    variant="outlined"
                                    value={state.address.state || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors['address.state'] ? inputErrors['address.state'] : ''}
                                    error={inputErrors && inputErrors['address.state'] ? true : false}
                                    name="state"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                }
                {
                    _.size(filteredCityList) > 0 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth error={inputErrors && inputErrors['address.city'] ? true : false}>
                                    <InputLabel>{t(`user.city`)}</InputLabel>
                                    <Select
                                        value={state.address?.city || ''}
                                        label={t(`user.city`)}
                                        name="city"
                                        onChange={handleChange}
                                        displayEmpty
                                    >
                                        <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`user.city`)} `} --- </MenuItem>
                                        {
                                            _.map(filteredCityList, (data, key) => {
                                                return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    {
                                        inputErrors && inputErrors['address.city'] &&
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors['address.city']}</FormHelperText>
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`user.city`)}
                                    variant="outlined"
                                    value={state.address.city || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors['address.city'] ? inputErrors['address.city'] : ''}
                                    error={inputErrors && inputErrors['address.city'] ? true : false}
                                    name="city"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                }
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('merchant.extraSetting')}</Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormGroup>
                            <FormControlLabel required control={<Switch checked={state?.extra_setting?.convert?.available}  onChange={handleChange} name="convert" />} label={t('merchant.canConvert')} />
                            {
                                inputErrors && inputErrors['extra_setting.convert.available'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['extra_setting.convert.available']}</FormHelperText>
                            }
                        </FormGroup>
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" style={{ marginBottom: 10, fontSize: 15 }}>{t(`wallets.display`)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state?.extra_setting?.display_setting?.show_member ? true : false}
                                    onChange={handleChange}
                                    name="show_member"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showMember`)}</span>}
                            style={{ marginRight: 20 }}
                        />

                    </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main, fontWeight: 'bold' }}>{t("merchant.supporDoc")}</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('merchant.IcFront')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.ic_front ? inputErrors.ic_front : ''}
                            error={inputErrors && inputErrors.ic_front ? true : false}
                            name={`ic_front`}
                            inputProps={{ accept: ["application/pdf", "image/*"] }}
                            onChange={handleChange}
                            type='file'
                            fullWidth
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('merchant.IcBack')}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.ic_back ? inputErrors.ic_back : ''}
                            error={inputErrors && inputErrors.ic_back ? true : false}
                            name={`ic_back`}
                            inputProps={{ accept: ["application/pdf", "image/*"] }}
                            onChange={handleChange}
                            type='file'
                            fullWidth
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <DropzoneArea
                            acceptedFiles={["application/pdf", "image/*"]}
                            filesLimit={4}
                            maxFileSize={2097152}
                            onChange={(files) => handleDropZoneChange(files)}
                        />
                        <Typography variant="body2" style={{ margin: 5 }}>{t('general.maxFile', { max: 4 }) + t('general.maxFileSize', { S: '2MB', fileType: 'jpeg. jpg. png. pdf. ' })}</Typography>
                        {inputErrors?.support_doc ?
                            <FormHelperText style={{ color: "red" }}>{inputErrors?.support_doc}</FormHelperText>
                            : null
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/ranks`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default Create;