import React from 'react';
import { Dialog, DialogActions, Typography, DialogContent, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeleteConfirmationModal = ({ open, closeDeleteDialog, submitData }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open || false } onClose={closeDeleteDialog}  >
            <DialogContent>
                <Typography variant="h5" >{t('general.deleteDescription')}</Typography>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                <Button variant="outlined" onClick={closeDeleteDialog} style={{ marginRight: 5 }}>
                    <Typography variant="button">{t('button.cancel')}</Typography>
                </Button>
                <Button variant="contained" onClick={submitData} >
                    {t('button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmationModal;