import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import _ from 'lodash';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { Button, Grid, Typography, LinearProgress, Box, TextField, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { IoTrashOutline } from "react-icons/io5";

import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';
import DragHandleIcon from '@mui/icons-material/DragHandle';

export default function BannerDetail(props) {
    const { language } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const [state, setState] = useState({
        url:'',
        title:'',
        videoListing:[]
    });
    const [inputErrors, setInputErrors] = useState({});
    const [deleteDialog, setDeleteDialog] = useState({ imageId: '', open: false });

    useEffect(() => {
        // setLoading(true);
        getApi();
        // setLoading(false);
    }, [language]);

    const getApi = () => {
        setLoading(true);
        getUrl(`/video`, {languages: language}).then(response => {
            setLoading(false);
            if (response.status) {
                setState({... state, videoListing : response.data})
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    const removeExistingImage = () => {
        setLoading(true)
        putUrl(`/video/${deleteDialog.imageId}`, {languages: language}).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.deleteSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, videoListing: data }))
                setDeleteDialog({ imageId: '', open: false })
            } else {
                setInputErrors(errors);
                addAlert('', t('error.deleteError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const submitData = () => {
        const formData = new FormData();
        _.map(state, (stateItem, stateKey) => {
            formData.append(stateKey, stateItem);
        });
        formData.append("_method", "post");
        formData.append("type", language);
        setLoading(true);
        setInputErrors();
        postUrl(`/video`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, videoListing: data }))
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

    function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const list = reorder(
			state.videoListing,
			result.source.index,
			result.destination.index
		);
		setState(prevState => ({
            ...prevState,
            videoListing: list
        }));
	}

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        boxShadow: '0 0 6px 0 #0002',
        borderRadius:'',
        backgroundColor: theme.palette.background.main,
        width: '100%',
        display:'flex',
        direction:'row',
      
        // styles we need to apply on draggables
        ...draggableStyle,
    });
      
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        marginTop:10,
        padding: grid,
        overflow: 'auto',
    });

    const updatePriority = () => {
        setLoading(true);
		let postData = {
			data: state.videoListing,
		};
		putUrl(`/video-update-priority/${language}`, postData).then(response => {
			addAlert("", response.message, response.status ? 'success' : "error", '');
            setLoading(false);
			if (response.status) {
				setState(prevState => ({ ...prevState, videoListing: response.data }))
			}
		}).catch(error => {
            setLoading(false);
			addAlert('', error.message || t('error.contactSupport'), 'error', '');
		});
	}

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({...state, [name]: value});
	}

    return (
        <div>
            <Grid container spacing={3} justifyContent="stretch" style={{ paddingBottom: '5%',marginTop:15}}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`video.url`)}
                                    variant="outlined"
                                    value={state?.url}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.url ? inputErrors.url : ''}
                                    error={inputErrors && inputErrors.url ? true : false}
                                    name="url"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`video.title`)}
                                    variant="outlined"
                                    value={state?.title}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.title ? inputErrors.title : ''}
                                    error={inputErrors && inputErrors.title ? true : false}
                                    name="title"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        {/* {
                            _.size(displayImages) > 0 ?
                                <Grid item xs={12} sm={6} md={8}>
                                    <ImageList 
                                        sx={{ transform: "translateZ(0)", width: '100%', height: 300 }}
                                        cols={4} 
                                        rowHeight={200}
                                        gap={5} 
                                    >
                                        {_.map(displayImages, (item, index) => {
                                            return (
                                                <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative" }} >
                                                    <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" />
                                                    <ImageListItemBar
                                                        sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top"
                                                        actionPosition="left"
                                                        actionIcon={
                                                            <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                                        }
                                                    />
                                                </ImageListItem>
                                            );
                                        })}
                                    </ImageList>
                                </Grid>
                                : null
                        }*/}
                        <Grid item xs={12}>
                            <div style={{ marginTop: 15, justifyContent: 'center', display: 'flex' }}>
                                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle}  onClick={() => submitData()} >{t('button.addVideo')}</Button>
                            </div>
                            {/* onClick={() => submitData()} */}
                        </Grid> 
                    </Grid>
                </Grid>
                 <Grid item xs={12}>
                    <div>
                        {
                            loading
                                ? <div><LinearProgress /></div>
                                : _.size(state.videoListing) > 0 ?
                                    <div>
                                        <Typography variant="h6"><b>{t("general.displayPreview")}</b></Typography>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId='video_gallery' direction="vertical">
                                                {
                                                    (provided, snapshot) => (
                                                        <Grid container spacing={2} 
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {
                                                                _.map(state.videoListing , (detail, key)=>(
                                                                    <Draggable key={key} draggableId={`${key}`} index={key}>
                                                                    {
                                                                            (provided, snapshot) => (
                                                                                <Grid item xs={12}
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                >
                                                                                    <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                                                                        <div style={{maxWidth:'70%'}}>
                                                                                            <Typography>{key+1} . {detail.title}</Typography>
                                                                                        </div>
                                                                                        <div style={{maxWidth:'15%'}}>
                                                                                            <IconButton onClick={() => setDeleteDialog({ imageId: detail.id, open: true })} style={{ color: theme.palette.button.main }}>
                                                                                                <IoTrashOutline />
                                                                                            </IconButton>
                                                                                            <IconButton style={{ color: theme.palette.button.main }}>
                                                                                                <DragHandleIcon />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Draggable>
                                                                ))
                                                            }
                                                            {provided.placeholder}
                                                        </Grid>
                                                    )
                                                }
                                            </Droppable>
                                        </DragDropContext>
                                        <div style={{ marginTop: 15, justifyContent: 'center', display: 'flex' }}>
                                            <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => updatePriority()}>{t('button.savePreference')}</Button>
                                        </div>
                                    </div>
                                    :
                                    null
                        }
                    </div>
                </Grid> 
            </Grid>
          <DeleteConfirmationModal open={deleteDialog.open} closeDeleteDialog={() => setDeleteDialog({ imageId: '', open: false })} submitData={() => removeExistingImage()} />
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: theme.palette.background.main,
        // boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed",
        borderColor: theme.palette.primary.main,
        width: "100%",
        height: 170,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        // position: "absolute",
        // right: 0,
        color: "white",
        // top: 0,
        zIndex: 1,
        background: theme.palette.background.main,
        cursor: 'pointer'
    },
}));