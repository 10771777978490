import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import { deleteUrl, getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const MODULE = 'user';
const INITIAL_STATE = {
    name: '',
    mobile: '',
    addressName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    full_address: '',
    status: 1,
}

const EditAddress = (props) => {
    let { id } = useParams();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();
    const [addressOption, setAddressOption] = useState();
    const [filteredStateList, setFilteredStateList] = useState([]);
    const [filteredCityList, setFilteredCityList] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [addressList, setAddressList] = useState();

    // address list
    const addressData = () => {
        setLoading(true);

        getUrl(`/merchant/addressList/${id}`)
            .then(response => {
                setLoading(false);
                if (response.status) {
                    setAddressList(response.data);
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
    };

    useEffect(() => {
        addressData();
    }, [])

    // edit address
    const handleEdit = (selectedAddress) => {
        setIsAddFormOpen(false);

        setState({
            ...state,
            address1: selectedAddress.address1,
            address2: selectedAddress.address2,
            addressName: selectedAddress.address_name,
            city: selectedAddress.city,
            state: selectedAddress.state,
            zip: selectedAddress.zip,
            name: selectedAddress.user_name,
            mobile: selectedAddress.user_mobile,
            country: selectedAddress.country,
            status: selectedAddress.status,
        });

        // Use state.country instead of just country
        let tempStateList = _.filter(addressOption?.stateList, function (o) {
            if (o.country_code === selectedAddress?.country) {
                return true;
            }
        });

        if (_.size(tempStateList) > 0) {
            setFilteredStateList(tempStateList);
        }

        let tempCityList = _.filter(addressOption?.cityList, function (o) {
            if (o.state_id === selectedAddress?.state) {
                return true;
            }
        });

        if (_.size(tempCityList) > 0) {
            setFilteredCityList(tempCityList);
        }

        // Open the form for editing
        setIsFormOpen(true);
        setSelectedAddress(selectedAddress);
    };

    // delete address
    const handleDelete = (addressId) => {
        setLoading(true);

        // Make a DELETE request to the Laravel backend
        deleteUrl(`/merchant/${id}/address/${addressId}`).then(response => {
            setLoading(false);
            if (response.status) {
                // Check if the deleted address was the default address
                const defaultAddress = addressList.find(address => address.id === addressId && address.default === 1);

                if (defaultAddress) {
                    // Find another address and set it as default
                    const nonDefaultAddresses = addressList.filter(address => address.id !== addressId);
                    if (nonDefaultAddresses.length > 0) {
                        const newDefaultAddressId = nonDefaultAddresses[0].id;
                        addAlert('', t('success.deleteSuccess'), 'success', '');
                        setDefaultAddress(newDefaultAddressId, false);
                    }else{
                        // Only show success message if the default address was not deleted
                        addAlert('', t('success.deleteSuccess'), 'success', '');
                    }
                    addressData();
                } else {
                    // Only show success message if the default address was not deleted
                    addAlert('', t('success.deleteSuccess'), 'success', '');
                    addressData();
                }
            } else {
                addAlert('', response?.message || t('error.deleteError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };

    // set as default
    const setDefaultAddress = async (addressId, needNote = true) => {

        let note = '';
        if (needNote) {
            const confirmation = await getConfirmation();
            if (confirmation.status) {
                note= confirmation.note;
            }

            // Check if the selected address is already the default address
            const isAlreadyDefault = addressList.some(address => address.id === addressId && address.default === 1);

            if (isAlreadyDefault) {
                // Show an info message to the user
                addAlert('', t('address.addressDefault'), 'info', '');
                return;
            }

            let params = {
                audit_remark: note,
            }

            // Make a PUT request to set the specified address as default
            putUrl(`/merchant/${id}/set-default/${addressId}`, params).then(response => {
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    addressData();
                } else {
                    addAlert('', response?.message || t('error.editError'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    };

    const handleEditFormClose = () => {
        setIsFormOpen(false);
        setState({
            name: '',
            mobile: '',
            address1: '',
            address2: '',
            zip: '',
            addressName: '',
            country: '',
            state: '',
            city: '',
        });
        // Reset inputErrors as needed
        setInputErrors({});
    }

    // add address
    const handleAddClick = () => {
        setIsFormOpen(false);
        setIsAddFormOpen(true);

        setState({
            name: '',
            mobile: '',
            address1: '',
            address2: '',
            zip: '',
            addressName: '',
            country: '',
            state: '',
            city: '',
        });

        // Use state.country instead of just country
        let tempStateList = _.filter(addressOption?.stateList, function (o) {
            if (o.country_code === state?.country) {
                return true;
            }
        });

        if (_.size(tempStateList) > 0) {
            setFilteredStateList(tempStateList);
        }

        let tempCityList = _.filter(addressOption?.cityList, function (o) {
            if (o.state_id === state?.state) {
                return true;
            }
        });

        if (_.size(tempCityList) > 0) {
            setFilteredCityList(tempCityList);
        }

        setInputErrors({});
    };

    const handleAddFormClose = () => {
        setIsAddFormOpen(false);
        // Reset the new address form fields when closing the form
        setState({
            name: '',
            mobile: '',
            address1: '',
            address2: '',
            zip: '',
            addressName: '',
            country: '',
            state: '',
            city: '',
        });
        // Reset inputErrors as needed
        setInputErrors({});
    };

    // address options
    useEffect(() => {
        setLoading(true);

        getUrl(`/address-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setAddressOption(response.addressList)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    // filter the country and state
    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'country':
                if (value !== 0) {
                    let tempStateList = _.filter(addressOption?.stateList, function (o) {
                        return o.country_code === value;
                    });
                    setFilteredStateList(tempStateList);
                    setState({
                        ...state,
                        country: value,
                        city: '',
                        state: '',
                    });
                } else {
                    setState({
                        ...state,
                        country: '',
                        city: '',
                        state: '',
                    });
                }
                break;

            case 'state':
                if (value !== 0) {
                    let tempCityList = _.filter(addressOption?.cityList, function (o) {
                        return parseInt(o.state_id) === parseInt(value);
                    });
                    setFilteredCityList(tempCityList);
                    setState({
                        ...state,
                        city: '',
                        state: value.toString(),
                    });
                } else {
                    setState({
                        ...state,
                        city: '',
                        state: '',
                    });
                }
                break;

            case 'zip':
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, [name]: value });
                break;

            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    // update data
    const updateData = async () => {
        const confirmation = await getConfirmation();
        if (confirmation.status) {
            setLoading(true);
            setInputErrors();

            let params = {
                address1: state.address1,
                address2: state.address2,
                address_name: state.addressName,
                city: state.city,
                state: state.state,
                zip: state.zip,
                user_name: state.name,
                user_mobile: state.mobile,
                country: state.country,
                status: '1',
                audit_remark: confirmation.note
            };

            // Make sure to pass the correct property as the address ID
            putUrl(`/merchant/${id}/address/${selectedAddress.id}`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    setIsAddFormOpen(false);
                    addressData();
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response?.message || t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    };

    // submit data
    const submitData = async () => {
        const confirmation = await getConfirmation();
        if (confirmation.status) {
            setLoading(true);
            setInputErrors();
            let params = {
                address1: state.address1,
                address2: state.address2,
                address_name: state.addressName,
                city: state.city,
                state: state.state,
                zip: state.zip,
                user_name: state.name,
                user_mobile: state.mobile,
                country: state.country,
                status: '1',
                default: '1',
                audit_remark: confirmation.note
            }
            putUrl(`/merchant/${id}/address`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    setIsAddFormOpen(false);
                    addressData();
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response?.message || t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <div>
            <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="large"
                    variant="contained"
                    className={styles.buttonStyle}
                    onClick={handleAddClick}
                >
                    <AddCircleOutlineIcon style={{ marginRight: 10 }} />
                    {t('button.addAddress')}
                </Button>
            </Grid>

            {/* New Address Form */}
            {isAddFormOpen && (
                <div>
                    <Grid container spacing={3} style={{ paddingBottom: '5%', paddingTop: '3%' }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.name`)}
                                    variant="outlined"
                                    value={state.name || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.user_name ? inputErrors.user_name : ''}
                                    error={inputErrors && inputErrors.user_name ? true : false}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.mobile`)}
                                    variant="outlined"
                                    value={state.mobile || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.user_mobile ? inputErrors.user_mobile : ''}
                                    error={inputErrors && inputErrors.user_mobile ? true : false}
                                    name="mobile"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.addressName`)}
                                    variant="outlined"
                                    value={state.addressName || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.address_name ? inputErrors.address_name : ''}
                                    error={inputErrors && inputErrors.address_name ? true : false}
                                    name="addressName"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.address`)}
                                    variant="outlined"
                                    value={state.address1 || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                                    error={inputErrors && inputErrors.address1 ? true : false}
                                    name="address1"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={state.country === 'CN' ? t(`${MODULE}.province`) : t(`${MODULE}.address2`)}
                                    variant="outlined"
                                    value={state.address2 || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                    error={inputErrors && inputErrors.address2 ? true : false}
                                    name="address2"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.zip`)}
                                    variant="outlined"
                                    value={state.zip || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                    error={inputErrors && inputErrors.zip ? true : false}
                                    name="zip"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        {
                            _.size(addressOption?.countryList) > 0 &&
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <FormControl fullWidth error={inputErrors && inputErrors.country ? true : false}>
                                        <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                                        <Select
                                            value={state?.country || ''}
                                            label={t(`${MODULE}.country`)}
                                            name="country"
                                            onChange={handleChange}
                                            displayEmpty
                                        >
                                            <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} --- </MenuItem>
                                            {
                                                _.map(addressOption?.countryList, (data, key) => {
                                                    return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        {
                                            inputErrors && inputErrors?.country &&
                                            <FormHelperText style={{ color: 'red' }}>{inputErrors?.country}</FormHelperText>
                                        }
                                    </FormControl>
                                </Box>
                            </Grid>
                        }
                        {
                            _.size(filteredStateList) > 0 ?
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <FormControl fullWidth error={inputErrors && inputErrors.state ? true : false}>
                                            <InputLabel>{t(`${MODULE}.state`)}</InputLabel>
                                            <Select
                                                value={state?.state || ''}
                                                label={t(`${MODULE}.state`)}
                                                name="state"
                                                onChange={handleChange}
                                                displayEmpty
                                            >
                                                <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.state`)} `} --- </MenuItem>
                                                {
                                                    _.map(filteredStateList, (data, key) => {
                                                        return <MenuItem key={key} value={data.id}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            {
                                                inputErrors && inputErrors?.state &&
                                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.state}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.state`)}
                                            variant="outlined"
                                            value={state.state || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                            error={inputErrors && inputErrors.state ? true : false}
                                            name="state"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                        }
                        {
                            _.size(filteredCityList) > 0 ?
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                            <InputLabel>{t(`${MODULE}.city`)}</InputLabel>
                                            <Select
                                                value={state?.city || ''}
                                                label={t(`${MODULE}.city`)}
                                                name="city"
                                                onChange={handleChange}
                                                displayEmpty
                                            >
                                                <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.city`)} `} --- </MenuItem>
                                                {
                                                    _.map(filteredCityList, (data, key) => {
                                                        return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            {
                                                inputErrors && inputErrors?.city &&
                                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.city}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.city`)}
                                            variant="outlined"
                                            value={state.city || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                            error={inputErrors && inputErrors.city ? true : false}
                                            name="city"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                        }
                    </Grid>

                    <Box style={{ paddingTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button size="large" variant="outlined" className={styles.buttonStyle} onClick={handleAddFormClose} style={{ marginRight: 15 }}>
                            {t('button.cancel')}
                        </Button>
                        <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>
                            {t('button.save')}
                        </Button>
                    </Box>

                </div>
            )}

            {/* Edit address && Update Address */}
            {addressList && addressList.map(address => (
                <Box key={address.id} className={styles.addressBox}>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Typography variant="h6">
                                {address.user_name && address.user_mobile ? (
                                    <>
                                        {address.user_name} | {address.user_mobile}
                                    </>
                                ) : (
                                    address.user_name || address.user_mobile // Display the non-null value
                                )}
                            </Typography>
                            <Typography variant="subtitle1">{address.address_name}</Typography>
                            <Typography>{address.full_address}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignContent: 'flex-end', alignItems: 'flex-end', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                <Typography variant="body2" style={{ marginBottom: 8 }}>{t('user.default')}</Typography>
                                <Switch
                                    checked={address.default === 1}
                                    onChange={() => setDefaultAddress(address.id)}
                                    color="primary"
                                    name="defaultSwitch"
                                    inputProps={{ 'aria-label': 'default switch' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <IconButton onClick={() => handleEdit(address)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(address.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>

                    {isFormOpen && selectedAddress === address && (
                        <div>
                            <Grid container spacing={3} style={{ paddingBottom: '5%', paddingTop: '3%' }}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.name`)}
                                            variant="outlined"
                                            value={state.name || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.user_name ? inputErrors.user_name : ''}
                                            error={inputErrors && inputErrors.user_name ? true : false}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.mobile`)}
                                            variant="outlined"
                                            value={state.mobile || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.user_mobile ? inputErrors.user_mobile : ''}
                                            error={inputErrors && inputErrors.user_mobile ? true : false}
                                            name="mobile"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.addressName`)}
                                            variant="outlined"
                                            value={state.addressName || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.address_name ? inputErrors.address_name : ''}
                                            error={inputErrors && inputErrors.address_name ? true : false}
                                            name="addressName"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.address`)}
                                            variant="outlined"
                                            value={state.address1 || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                                            error={inputErrors && inputErrors.address1 ? true : false}
                                            name="address1"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={state.country === 'CN' ? t(`${MODULE}.province`) : t(`${MODULE}.address2`)}
                                            variant="outlined"
                                            value={state.address2 || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                            error={inputErrors && inputErrors.address2 ? true : false}
                                            name="address2"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={t(`${MODULE}.zip`)}
                                            variant="outlined"
                                            value={state.zip || ''}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                            error={inputErrors && inputErrors.zip ? true : false}
                                            name="zip"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                {
                                    _.size(addressOption?.countryList) > 0 &&
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                            <FormControl fullWidth error={inputErrors && inputErrors.country ? true : false}>
                                                <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                                                <Select
                                                    value={state?.country || ''}
                                                    label={t(`${MODULE}.country`)}
                                                    name="country"
                                                    onChange={handleChange}
                                                    displayEmpty
                                                >
                                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} --- </MenuItem>
                                                    {
                                                        _.map(addressOption?.countryList, (data, key) => {
                                                            return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                {
                                                    inputErrors && inputErrors?.country &&
                                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.country}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    _.size(filteredStateList) > 0 ?
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <FormControl fullWidth error={inputErrors && inputErrors.state ? true : false}>
                                                    <InputLabel>{t(`${MODULE}.state`)}</InputLabel>
                                                    <Select
                                                        value={state?.state || ''}
                                                        label={t(`${MODULE}.state`)}
                                                        name="state"
                                                        onChange={handleChange}
                                                        displayEmpty
                                                    >
                                                        <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.state`)} `} --- </MenuItem>
                                                        {
                                                            _.map(filteredStateList, (data, key) => {
                                                                return <MenuItem key={key} value={data.id}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    {
                                                        inputErrors && inputErrors?.state &&
                                                        <FormHelperText style={{ color: 'red' }}>{inputErrors?.state}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <TextField
                                                    label={t(`${MODULE}.state`)}
                                                    variant="outlined"
                                                    value={state.state || ''}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                                    error={inputErrors && inputErrors.state ? true : false}
                                                    name="state"
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                }
                                {
                                    _.size(filteredCityList) > 0 ?
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <FormControl fullWidth error={inputErrors && inputErrors.status ? true : false}>
                                                    <InputLabel>{t(`${MODULE}.city`)}</InputLabel>
                                                    <Select
                                                        value={state?.city || ''}
                                                        label={t(`${MODULE}.city`)}
                                                        name="city"
                                                        onChange={handleChange}
                                                        displayEmpty
                                                    >
                                                        <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.city`)} `} --- </MenuItem>
                                                        {
                                                            _.map(filteredCityList, (data, key) => {
                                                                return <MenuItem key={key} value={data.code}>{i18n.language === 'cn' ? data.name_cn : data.name}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    {
                                                        inputErrors && inputErrors?.city &&
                                                        <FormHelperText style={{ color: 'red' }}>{inputErrors?.city}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <TextField
                                                    label={t(`${MODULE}.city`)}
                                                    variant="outlined"
                                                    value={state.city || ''}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                                    error={inputErrors && inputErrors.city ? true : false}
                                                    name="city"
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                }
                            </Grid>

                            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button size="large" variant="outlined" className={styles.buttonStyle} onClick={handleEditFormClose} style={{ marginRight: 15 }}>
                                    {t('button.cancel')}
                                </Button>
                                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={updateData}>{t('button.edit')}</Button>
                            </Box>
                        </div>
                    )}
                </Box>
            ))}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    addressBox: {
        marginTop: theme.spacing(2),
        border: '1px solid #ccc',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export default EditAddress;