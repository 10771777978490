import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useNotificationLoading from '@utils/useNotificationLoading';
import { makeStyles, useTheme, withStyles } from "@mui/styles";

import { getUrl } from "@utils/ApiAction";

import { Grid, Typography, LinearProgress, Toolbar, FormControl, FormControlLabel, Input, InputAdornment, Tooltip, IconButton, Icon, Button, Radio, RadioGroup, Box } from '@mui/material';
import ClearIcon from '@material-ui/icons/Clear';
import { useNavigate, Route, Routes, useLocation  } from 'react-router-dom';

import  '@components/css/tree.css';
import { MdPersonSearch } from "react-icons/md";

import { OrgChartComponent } from '@components/OrgChart';

import { useQuery } from '@utils/Tools';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState({username: props.state.username, type: props.state.type});
    const searchInputRef = useRef(null);

    const handleClear = () => {
        setValue({username: '', type: 'downlines'});
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('genealogy.searchUsername')}
                        startAdornment={
                            <InputAdornment position="start">
                                <Tooltip title={t('table.search')}>
                                    <Icon fontSize="small" onClick={props.handleSubmit}>
                                    <MdPersonSearch />
                                    </Icon>
                                </Tooltip>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip title={t('table.clear')}>
                                    <IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search">
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value.username || ''}
                        onChange={e => setValue({...value, username: e.target.value})}
                    />
                </FormControl>
                <FormControl component="fieldset" style={{ marginLeft: 10 }}>
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={value.type || props.state.type}
                        onChange={e => setValue({...value, type: e.target.value})}
                        row
                    >
                        <FormControlLabel value="downlines" control={<Radio />} label={t('genealogy.downlines')} />
                        <FormControlLabel value="uplines" control={<Radio />} label={t('genealogy.uplines')} />
                    </RadioGroup>
                </FormControl>
				
				<Button variant="contained" style={{ marginLeft: 10 }} onClick={props.handleSubmit}>{t('table.search')}</Button>
            </form>
        </Toolbar>;
    return [value, setValue, toolbarSearch];
}

export default function GenealogyPlacement() {
    const [data, setData] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [state, setState] = useState({username: '', type: "downlines"});
    const [searchValue, setSearchValue, searchToolbar] = TableToolbar({handleSubmit, state});
	const theme = useTheme();
    
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    // const history = useHistory();
    const query = useQuery();
    const searchUsername = query.get('username');
    const searchType = query.get('type');
    const chartRef = useRef();
    let navigate = useNavigate();
    
    useEffect(() => {
        if(searchUsername && searchType) {
            setState({username: searchUsername, type: searchType});
            setSearchValue({username: searchUsername, type: searchType});
        } else {
            setState({username: '', type: 'downlines'});
            setSearchValue({username: '', type: 'downlines'});
        }
    }, [searchUsername, setSearchValue, searchType]);

    useEffect(() => {
        isMountedRef.current = true;
        if (_.size(state?.username) > 0 && state?.type !== '') {
            setTableLoading(true);
            getUrl(`genealogy2/placement`, { username: state?.username, type: state?.type })
                .then(response => {
                    let { status, data, message } = response;
                    if (status) {
                        setData(data.tree_data);
                    } else {
                        setData({});
                        addAlert('', message, 'error', '');
                    }
                    setTableLoading(false);
                }).catch((error) => {
                    if (isMountedRef.current) {
                        setTableLoading(false);
                        let msg = error + "\n" + t('error.contactSupport');
                        addAlert('', msg, 'error', '');
                    }
                })
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [state]);

    function handleSubmit(event) {
        event.preventDefault();
        navigate(`/placement-genealogy?username=${searchValue.username}&type=${searchValue.type}`);
    }

    function onNodeClick(nodeId) {
        const selectedNode = _.find(data, { nodeId });
        if(_.size(selectedNode)) {
            navigate(`/placement-genealogy?username=${selectedNode.username}&type=${state?.type}`);
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" alignItems="flex-end">
                    <Typography style={{ fontSize: 21, color: theme.palette.title.main , fontWeight: 'bold' }} > {t('title.placement')} </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                {searchToolbar}
            </Grid>
            <Grid item xs={12}>
                {
                    tableLoading
                        ?
                        <LinearProgress />
                        :
                        _.size(data)
                            ?
                            <Box
                                style={{ pointerEvents: 'none' , borderRadius:10 }}
                                className='gradient-border-box'
                            >
                                <OrgChartComponent
                                    data={data}
                                    displayType={state?.type}
                                    searchUsername={state?.username}
                                    ref={chartRef}
                                    onNodeClick={onNodeClick}
                                />
                            </Box>
                            :
                            null
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));