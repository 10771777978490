import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { postUrl, getUrl } from '@utils/ApiAction';

import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, InputAdornment, IconButton, FormControl, InputLabel, Select, MenuItem , FormHelperText } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import BackToPrevious from '@layouts/BackToPrevious';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    mobile: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    roleType: ['0'],
}

const Create = () => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState(INITIAL_STATE);
    const [option, setOption] = useState({});
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    useEffect(() => {
        setLoading(true);

        getUrl(`/admin-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setUserMobile({ ...userMobile, [name]: value });
            return;
        } 
        if(name  === 'roleType'){
            let temp_data =[];
            temp_data.push(value);
            value = temp_data;
        }
        if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase();
        }
        setState({ ...state, [name]: value });
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            let params = {
                username: state.username,
                name: state.name,
                email: state.email,
                country: userMobile?.country,
                mobile_code: userMobile?.code,
                mobile: userMobile?.mobile,
                password: state.password,
                password_confirmation: state.passwordConfirmation,
                role_type: state.roleType,
                audit_remark: confirmation.note,
            }
            postUrl(`/admins`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    setState(INITIAL_STATE);
                    setUserMobile({
                        country: "",
                        code: "",
                        mobile: "",
                        error: {}
                    });
                    addAlert('', t('success.createSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.adminAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/admins`}>
                            {t(`title.admin`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.adminAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                        <Typography variant="caption" style={{ display: 'flex', textAlign: 'left' , color: theme.palette.noticeText.main }}>
                            {t('user.usernameLowerCase')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                            <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                            <Select
                                value={userMobile.country || ''}
                                label={t(`${MODULE}.country`)}
                                name="country"
                                onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0} disabled>
                                    --- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} ---
                                </MenuItem>
                                {
                                    _.map(mobileCodeList.country, (data, key) => {
                                        const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                        return (
                                            <MenuItem key={key} value={data.code}>
                                                {countryName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors['country'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                            }
                            <Typography variant="caption" style={{ color: theme.palette.noticeText.main, display: 'flex', textAlign: 'left' }}>{t('general.pleaseSelectCountry')}</Typography>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                            <InputLabel>{t(`${MODULE}.mobileCode`)}</InputLabel> 
                            <Select
                                value={userMobile.code || ''}
                                label={t(`${MODULE}.mobileCode`)}
                                name="mobileCode"
                                onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                displayEmpty
                            >
                                {/* <MenuItem value="">
                                    {``}
                                </MenuItem> */}
                                {_.size(mobileCodeList?.code) > 0 ? (
                                    _.map(mobileCodeList?.code, (option, key) => (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" style={{ color: '#222' }}>
                                        {``}
                                    </MenuItem>
                                )}
                            </Select>
                            {
                                inputErrors && inputErrors['mobile_code'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={userMobile.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            type="email"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(option?.roleList) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.role_type ? true : false}>
                                <InputLabel>{t(`${MODULE}.roleType`)}</InputLabel>
                                <Select
                                    value={state?.roleType || ''}
                                    label={t(`${MODULE}.roleType`)}
                                    name="roleType"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.roleType`)} `} --- </MenuItem>
                                    {
                                        _.map(option?.roleList, (data, key) => {
                                            return <MenuItem key={key} value={data.name}>{i18n.language === 'cn' ? data?.display_name[i18n.language]: data?.display_name?.en}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.role_type &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors?.role_type}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="password"
                            label={t(`${MODULE}.password`)}
                            type={showPassword.password ? 'text' : 'password'}
                            value={state.password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('password')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="passwordConfirmation"
                            label={t(`${MODULE}.confirmPassword`)}
                            type={showPassword.passwordConfirmation ? 'text' : 'password'}
                            value={state.passwordConfirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.passwordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/admins`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default Create;