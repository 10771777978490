import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IoTrashOutline } from "react-icons/io5";

import { getUrl, deleteUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';

const MODULE = 'trades';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [bonusListForSelect, setBonusListForSelect] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        trade_sell_id: '',
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/trade-sell`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
                setBonusList(response.data.bonus_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    useEffect(() => {
        let newList = [];
        _.map(bonusList, (data, key) => {
            let textDisplay = data.name[i18n.language] ? data.name[i18n.language] : data.name.en;
            newList.push({ text: textDisplay, value: data.id.toString() });
        })
        setBonusListForSelect(newList);
        // eslint-disable-next-line
    }, [bonusList, i18n.language]);

    const handleDeleteDialogOpen = (trade_sell_id) => {
        setDeleteDialog({
            open: true,
            trade_sell_id: trade_sell_id,
        });
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialog({
            open: false,
            trade_sell_id: '',
        });
    }

    const handleDelete = () => {
        setDeleteDialog({ ...deleteDialog, open: false });
        setTableLoading(true);
        deleteUrl(`/trade-sell/${deleteDialog.trade_sell_id}`).then(response => {
            setTableLoading(false);
            if (response.status) {
                addAlert("", response.message, 'success', '');
                handleDeleteDialogClose();
                callApi();
            } else {
                addAlert("", response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const columns = useMemo(() => ([
        {
            accessorKey: 'id',
            header: `${t('general.id')}`,
        },
        {
            accessorKey: 'trade_id',
            header: `${t('trades.trade_id')}`,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.row.original.trade_id
                            ?
                            <Link underline='none' to={`/trade-edit/${cell.row.original.trade_id}`} component={RouterLink}>
                                {cell.row.original.trade_id}
                            </Link>

                            :
                            '-'
                    }
                </>
            )
        },
        {
            accessorKey: 'trade.price',
            header: `${t('trades.sell_price')}`,
            enableSorting: false,
        },
        // {
        //     accessorKey: 'trade_buy_history_id',
        //     header: `${t('trades.trade_buy_history_id')}`,
        //     Cell: ({ cell }) => (
        //         <>
        //             {
        //                 cell.row.original.trade_buy_history_id
        //                 ? 
        //                 cell.row.original.trade_buy_history_id
        //                 :
        //                 '-'
        //             }
        //         </>
        //     )
        // },
        {
            accessorKey: 'user.username',
            header: `${t('trades.user_id')}`,
            enableSorting: false,

            Cell: ({ cell }) => (
                <>
                    {
                        cell.row?.original?.user?.username
                            ?
                            cell.row?.original?.user?.username
                            :
                            '-'
                    }
                </>
            )
        },
        {
            accessorKey: 'trade_unit',
            header: `${t('trades.trade_unit')}`,
        },
        {
            accessorKey: 'sold_unit',
            header: `${t('trades.sold_unit')}`,
        },
        {
            accessorKey: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {statusList.find(item => item.value == cell.row?.original?.status)?.text}
                    {
                        _.includes(permissions, `trade-sell-delete`) && (cell.row?.original?.status === 10 || cell.row?.original?.status === 20) ?
                            <IconButton style={{ marginLeft: 5 }} onClick={() => handleDeleteDialogOpen(cell.row?.original?.id)}>
                                <IoTrashOutline style={{ color: theme.palette.secondary.main }} />
                            </IconButton>
                            : null
                    }
                </div>
            ),
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, bonusListForSelect]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.ReportTradeSell`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard" className="text-gold">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="secondary">{t(`title.ReportTradeSell`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'TradeSell'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
            <DeleteConfirmationModal open={deleteDialog.open} closeDeleteDialog={handleDeleteDialogClose} submitData={() => handleDelete()} />
        </>
    )
}

export default Listing