import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { putUrl, getUrl } from '@utils/ApiAction';
import { Link, TextField, Box, Button, Grid, InputAdornment, IconButton } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const MODULE = 'user';
const INITIAL_STATE = {
    password: '',
    passwordConfirmation: '',
}

const SecurityPassword = () => {
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirmation: false,
    });
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            let params = {
                password: state.password,
                password_confirmation: state.passwordConfirmation,
                audit_remark: confirmation.note
            }
            putUrl(`/admins/${id}/security-password`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    setState(INITIAL_STATE);
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response?.message ||  t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="password"
                            label={t(`${MODULE}.password`)}
                            type={showPassword.password ? 'text' : 'password'}
                            value={state.password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('password')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="passwordConfirmation"
                            label={t(`${MODULE}.confirmPassword`)}
                            type={showPassword.passwordConfirmation ? 'text' : 'password'}
                            value={state.passwordConfirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.passwordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/admins`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.edit')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default SecurityPassword;