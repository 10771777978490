import { Box, Collapse, FormControl, FormControlLabel, Button, Radio, RadioGroup, Grid, Icon, IconButton, Input, InputAdornment, Link, Paper, Toolbar, Tooltip, Typography } from "@mui/material";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import { TreeItem, TreeView } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getUrl } from "@utils/ApiAction";
import { stringToLang, useQuery } from "@utils/Tools";
import useNotificationLoading from "@utils/useNotificationLoading";

import { MdPersonSearch } from "react-icons/md";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ClearIcon from "@mui/icons-material/Clear";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from '@mui/icons-material/Search';
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';

function TableToolbar(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [value, setValue] = useState({username: props.state.username, type: props.state.type});
	const searchInputRef = useRef(null);

	const handleClear = () => {
		setValue({username: '', type: 'downlines'});
		searchInputRef.current.focus();
	};

	const toolbarSearch = (
		<Toolbar className={classes.toolbarRoot}>
			<form noValidate onSubmit={props.handleSubmit}>
				<FormControl>
					<Input
						id="search-input"
						placeholder={t("genealogy.searchUsername")}
						startAdornment={
							<InputAdornment position="start">
								<Tooltip title={t("table.search")}>
									<Icon
										fontSize="small"
										onClick={props.handleSubmit}
									>
										<MdPersonSearch/>
									</Icon>
								</Tooltip>
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="end">
								<Tooltip title={t("table.clear")}>
									<IconButton
										type="button"
										onClick={handleClear}
										className={classes.iconButton}
										aria-label="clear-search"
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						}
						aria-describedby="search"
						inputProps={{
							"aria-label": "search",
						}}
						inputRef={searchInputRef}
						value={value.username || ''}
						onChange={(e) => setValue({...value, username: e.target.value})}
					/>
				</FormControl>
				<FormControl component="fieldset" style={{ marginLeft: 10 }}>
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={value.type || props.state.type}
                        onChange={(e) => setValue({...value, type: e.target.value})}
                        row
                    >
                        <FormControlLabel value="downlines" control={<Radio />} label={t('genealogy.downlines')} />
                        <FormControlLabel value="uplines" control={<Radio />} label={t('genealogy.uplines')} />
                    </RadioGroup>
                </FormControl>
				
				<Button variant="contained" style={{ marginLeft: 10 }} onClick={props.handleSubmit}>{t('table.search')}</Button>
			</form>
		</Toolbar>
	);
	return [value, setValue, toolbarSearch];
}

function TransitionComponent(props) {
	const style = useSpring({
		from: { opacity: 0, transform: "translate3d(20px,0,0)" },
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

TransitionComponent.propTypes = {
	/**
	 * Show the component; triggers the enter or exit states
	 */
	in: PropTypes.bool,
};

const USER_STATUS_COLOR={
	10: ' #ffffff45',
	20: 'green',
	30: 'orange',
	40: 'red'
}

export default function SponsorGenealogy() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const query = useQuery();
	const isMountedRef = useRef(null);

	const { addAlert, setLoading, loading } = useNotificationLoading();
	const [sponsorData, setSponsorData] = useState([]);
	const [errMsg, setErrMsg] = useState([]);
	const [state, setState] = useState({username: '', type: "downlines"});
	const [searchValue, setSearchValue, searchToolbar] = TableToolbar({ handleSubmit, state });

	useEffect(() => {
		isMountedRef.current = true;
		setLoading(true);
		setErrMsg([]);

		getUrl(`admin/genealogy/sponsor`, { username: state?.username, type: state?.type })
			.then((response) => {
				let { status, message, error, data } = response;
				if (isMountedRef.current) {
					if (status == true) {
						setSponsorData(data.tree_data);
						setLoading(false);
					}else if(status == false){
						setSponsorData([]);
						setLoading(false);
					} else if (error) {
						setErrMsg(error);
						if (_.size(error) > 0) {
							_.map(error, (value, key) => {
								message += "\n " + value[0];
							});
						}
						setLoading(false);
						addAlert("", message, "error", "");
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				addAlert(
					"",
					error + "\n" + t("error.contactSupport"),
					"error",
					""
				);
			});

		return () => {
			isMountedRef.current = false;
		};
		// eslint-disable-next-line
	}, [state]);

	const viewMore = (value, type) => {
		setSearchValue({username: value, type: type});
		setState({username: value, type: type});
	};

	function handleSubmit(event) {
		event.preventDefault();
		setState({username: searchValue?.username, type: searchValue?.type});
	}

	const renderTree = (nodes) => (
		<StyledTreeItem
			key={nodes.id}
			nodeId={`${nodes.id}`} /* endIcon={<PersonIcon color={"primary"} />} */
			label={
				<div className={classes.labelRoot}>
					{state?.type === 'downlines' && !loading && _.size(nodes.children) === 0 && nodes.total_downline > 0 ? (
						<Link
							style={{ fontSize: "0.7rem" , marginLeft:10}}
							onClick={() => viewMore(nodes.username, state?.type)}
						>
							<Tooltip
								title={t("genealogy.loadMore")}
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<FindInPageIcon fontSize="small" />
							</Tooltip>
						</Link>
					) : null}
					<PersonIcon color="secondary" />
					<Typography
						variant="body2"
						className={classes.labelText}
						style={{ color: USER_STATUS_COLOR[nodes.status] , marginLeft:10}}
					>
						{nodes.username}
					</Typography>
					<Typography
						variant="caption"
						color="primary"
						className={classes.labelSubText}
						style={{ marginLeft:10}}
					>
						{new Date(
							nodes.user_created_at.replace(/-/g, "/")
						).toLocaleDateString()}
					</Typography>
					<Tooltip
						title={t("genealogy.downlines")}
						style={{ display: "flex", alignItems: "center" , marginLeft:10}}
					>
						<Typography
							variant="caption"
							color="primary"
							className={classes.labelSubText}
						>
							<AccountTreeIcon fontSize="small" style={{ marginRight: 5 }} color="secondary"  />
							{nodes.total_downline}
						</Typography>
					</Tooltip>
					<Tooltip
						title={t('genealogy.personalSales')}
						style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
					>
						<Typography
							variant="caption"
							color="primary"
							className={classes.labelSubText}
						>
							<PersonIcon fontSize="small" style={{ marginRight: 5 }} color="secondary" />
							{nodes.personal_sales ? parseFloat(nodes.personal_sales).toFixed(2) : '0.00'}
						</Typography>
					</Tooltip>
					{/* <Tooltip
						title={t('genealogy.smallGroupSales')}
						style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
					>
						<Typography
							variant="caption"
							color="inherit"
							className={classes.labelSubText}
						>
							<PeopleAltIcon fontSize="small" />
							{nodes.small_gs ? parseFloat(nodes.small_gs).toFixed(2) : '0.00'}
						</Typography>
					</Tooltip> */}
					<Tooltip
						title={t('genealogy.groupSales')}
						style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
					>
						<Typography
							variant="caption"
							color="primary"
							className={classes.labelSubText}
						>
							<GroupsIcon fontSize="small" style={{ marginRight: 5 }} color="secondary" />
							{nodes.sponsor_group_sales ? parseFloat(nodes.sponsor_group_sales).toFixed(2) : '0.00'}
						</Typography>
					</Tooltip>
				</div>
			}
		>
			{Array.isArray(nodes.children)
				? nodes.children.map((node) => renderTree(node))
				: null}
		</StyledTreeItem>
	);

	return (
		<Grid container spacing={3} >
			<Grid item xs={12}>
				<Typography style={{ fontSize: 21, color: theme.palette.title.main }}><b>{t("sponsor.sponsorTreeGenealogy")}</b></Typography>
			</Grid>
			<Grid item xs={12}>
				<Paper
					className={`${classes.paper} gradient-border-box`}
					style={{ ...theme.box1, ...theme.p20 , borderRadius:10 }}
				>
					{searchToolbar}
					<Box style={{display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: 20 , color: theme.palette.primary.main }}>
						<Typography
							variant="caption"
							color="inherit"
							className={classes.introText}
						>
							<AccountTreeIcon fontSize="small" style={{marginLeft: 10, marginRight: 10}} />
							{t("genealogy.downlines")}
						</Typography>
						<Typography
							variant="caption"
							color="inherit"
							className={classes.introText}
						>
							<PersonIcon fontSize="small" style={{marginLeft: 10, marginRight: 10}} />
							{t('genealogy.personalSales')}
						</Typography>
						{/* <Typography
							variant="caption"
							color="inherit"
							className={classes.introText}
						>
							<PeopleAltIcon fontSize="small" style={{marginLeft: 10, marginRight: 10}} />
							{t('genealogy.smallGroupSales')}
						</Typography> */}
						<Typography
							variant="caption"
							color="inherit"
							className={classes.introText}
						>
							<GroupsIcon fontSize="small" style={{marginLeft: 10, marginRight: 10}} />
							{t('genealogy.groupSales')}
						</Typography>
					</Box>
					{_.size(sponsorData) > 0 && sponsorData != null ? (
						<TreeView
							className={classes.root}
							defaultExpanded={["6"]}
							defaultCollapseIcon={
								<IndeterminateCheckBoxIcon color="primary" />
							}
							defaultExpandIcon={<AddBoxIcon color="primary" />}
							style={{padding:30}}
							// defaultEndIcon={<PersonIcon color="secondary"/>}
						>
							{renderTree(sponsorData)}
						</TreeView>
					) : (
						<Box className={classes.msgBox}>
							<Typography variant="body1" color="secondary" >
								{t("sponsor.noSponsor")}
							</Typography>
						</Box>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
}

const StyledTreeItem = withStyles((theme) => ({
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		// borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
	},
	label: {
		fontSize: 17,
	},
	iconContainer: {
		fontSize: 20,
	},
}))((props) => (
	<TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles((theme) => ({
	root: {
		height: "auto",
		flexGrow: 1,
		maxWidth: 1000,
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
		minHeight: 440,
	},
	toolbarRoot: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		flexDirection: "row-reverse",
	},
	msgBox: {
		textAlign: "center",
		// justifyContent:'center',
		// display:'flex',
		paddingBottom: 150,
		paddingTop: 150,
	},
	labelRoot: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	labelText: {
		fontWeight: "inherit",
		marginLeft: 10,
		textTransform: "lowercase",
	},
	labelSubText: {
		marginLeft: 10,
		fontStyle: "italic",
	},
	introText: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));