import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { EditNote } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const MODULE = 'tradeProfile';

const Listing = () => {
    const theme = useTheme();
    
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }

        getUrl(`/trade-profile`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const columns = useMemo(() => ([
        {
            header: `${t(`${MODULE}.userId`)}`,
            id: 'user.username',
            accessorFn: (row) => (row.user?.username ? row.user?.username : '-'),
            enableSorting: false,
            Cell: ({ cell }) => (
                <Link underline='none' to={`/member-edit/${cell.row.original.user.id}`} component={RouterLink}>
                    { cell.row?.original?.user?.username ? cell.row?.original?.user?.username : '-' }
                </Link>
            )
        },
        {
            header: `${t(`${MODULE}.activeUnit`)}`,
            accessorKey: 'active_unit',
        },
        {
            header: `${t(`${MODULE}.passiveUnit`)}`,
            accessorKey: 'passive_unit',
        },
        {
            header: `${t(`${MODULE}.lockValue`)}`,
            accessorKey: 'lock_value',
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.tradeProfile`)}</b></Typography>
                </Box>
              
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard" className="text-gold">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="secondary">{t(`title.tradeProfile`)}</Typography>
                </Breadcrumbs>
            </Grid>
            {
                _.includes(permissions, `trade-profile-adjust`) 
                ?
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingBottom: 5 }}>
                        <Link underline='none' to={`/profile-adjustment`} component={RouterLink}>
                            <Box display="flex" alignItems="center" >
                                <IconButton style={{ color: theme.palette.button.main, border: `2px solid ${theme.palette.button.main}`, borderRadius: 5, padding: 0, marginRight: 5 }}>
                                    <EditNote style={{ fontSize: 20 }} />
                                </IconButton>
                                <Typography className="text-gold">{t(`${MODULE}.profileAdjustment`)}</Typography>
                            </Box>
                        </Link>
                    </Grid>
                : null
            }

            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'TradeProfileList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['title.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing