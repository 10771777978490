import React from 'react';
import { TextField, Grid, IconButton, InputAdornment, Button, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const SearchComponent = ({ searchValue, setSearchValue, handleClearSearch, handleSearch, selectedGenealogyType, handleGenealogyTypeChange }) => {
    const { t } = useTranslation();
    return (
        <Grid container alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={12} md={4}>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchValue && (
                                    <IconButton onClick={handleClearSearch} size="large">
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                <Button variant="contained" onClick={handleSearch}>
                                    {t('table.search')}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={selectedGenealogyType}
                        onChange={handleGenealogyTypeChange}
                        row
                    >
                        <FormControlLabel value="individual" control={<Radio />} label={t(`button.individual`)} />
                        <FormControlLabel value="directSponsor" control={<Radio />} label={t(`button.directSponsor`)} />
                        <FormControlLabel value="sponsor" control={<Radio />} label={t(`button.sponsor`)} />
                        <FormControlLabel value="placement" control={<Radio />} label={t(`button.placement`)} />
                        {/* <FormControlLabel value="both" control={<Radio />} label="Sponsor & Placement" /> */}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default SearchComponent;