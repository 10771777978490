import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { Link as RouterLink , useParams } from 'react-router-dom';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Box, Button, FormHelperText, Grid, FormControlLabel , Switch , Link, Typography , FormGroup , TextField , FormControl , InputLabel , Select , MenuItem , Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';


const ExtraSetting = (props) => {
    let { merchantInfo , callApi } = props;
    let { id } = useParams();
    const { t } = useTranslation();
    const [state , setState] = useState({
        extra_setting: merchantInfo?.extra_setting,
    });
    const [ inputErrors , setInputErrors] =useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = ({ target }) => {
        let { name, value, checked } = target;
        switch (name) {
            case 'convert':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        convert: { available: checked } , 
                    }
                }));
                break;
            case 'service_fee_number':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : '';
                setState(preState => ({
                    ...preState,
                    extra_setting: {
                        ...preState.extra_setting,
                        withdrawal_setting: {
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}),
                            service_fee_number: value
                        }
                    }
                }));
                break;
            case 'withdraw_function':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            withdraw_function: value 
                        } 
                    }
                }));
                break;

            case 'withdraw_percent':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : '';
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            withdraw_percent: value 
                        } 
                    }
                }));
                break;

            case 'fee_param':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            fee_param: value 
                        } 
                    }
                }));
                break;

            case 'service_fee_method':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            service_fee_method: value 
                        } 
                    }
                }));                
                break;

            case 'withdraw_method':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            withdraw_method: value 
                        } 
                    }
                }));
                break;

            case 'fee_type':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        withdrawal_setting: { 
                            ...((preState.extra_setting && preState.extra_setting.withdrawal_setting) || {}), 
                            fee_type: value 
                        } 
                    }
                }));
                break;
            case 'show_member':
                setState(preState => ({
                    ...preState,
                    extra_setting: { 
                        ...preState.extra_setting, 
                        display_setting: { [name]: checked } , 
                    }
                }));
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const submitData = () =>{
        setLoading(true);
        setInputErrors({});

        let params = {
            extra_setting: state?.extra_setting,
        };

        // Make sure to pass the correct property as the address ID
        putUrl(`/merchant/adjust-extra-setting/${id}`, params).then(response => {
            setLoading(false);
            if (response.status) {
                callApi();
                addAlert('', response?.message || t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response?.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            {/* <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('merchant.convert')}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormGroup>
                            <FormControlLabel required control={<Switch checked={state?.extra_setting?.convert?.available} onChange={handleChange} name="convert" />} label={t('merchant.canConvert')} />
                            {
                                inputErrors && inputErrors['extra_setting.convert.available'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['extra_setting.convert.available']}</FormHelperText>
                            }
                        </FormGroup>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.withdraw_function ? true : false}>
                            <InputLabel>{t(`merchant.withdrawFunction`)}</InputLabel>
                            <Select
                                value={ state?.extra_setting?.withdrawal_setting?.withdraw_function || false }
                                label={t(`merchant.withdrawFunction`)}
                                name="withdraw_function"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                <MenuItem key={1} value={true}>{t(`merchant.on`)}</MenuItem>
                                <MenuItem key={2} value={false}>{t(`merchant.off`)}</MenuItem>
                                
                            </Select>
                            {
                                inputErrors && inputErrors?.withdraw_function &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.withdraw_function}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.serviceFeeNumber`)}
                            variant="outlined"
                            value={state?.extra_setting?.withdrawal_setting?.service_fee_number ||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['extra_setting.withdrawal_setting.service_fee_number'] ? inputErrors['extra_setting.withdrawal_setting.service_fee_number'] : ''}
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.service_fee_number'] ? true : false}
                            name="service_fee_number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.withdrawPercent`)}
                            variant="outlined"
                            value={state?.extra_setting?.withdrawal_setting?.withdraw_percent ||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors['extra_setting.withdrawal_setting.withdraw_percent'] ? inputErrors['extra_setting.withdrawal_setting.withdraw_percent'] : ''}
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.withdraw_percent'] ? true : false}
                            name="withdraw_percent"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="fee_param">{t(`merchant.feeParam`)}</InputLabel>
                        <Select
                            labelId="fee_param"
                            value={state?.extra_setting?.withdrawal_setting?.fee_param ||''}
                            label={t(`merchant.feeParam`)}
                            onChange={handleChange}
                            name="fee_param"
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.fee_param'] ? true : false}
                        >
                            <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                            <MenuItem value={'merchant'} key={'merchant'}>{t('merchant.merchant')}</MenuItem>
                            <MenuItem value={'system'} key={'system'}>{t('merchant.system')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="service_fee_method">{t(`merchant.serviceFeeMethod`)}</InputLabel>
                        <Select
                            labelId="service_fee_method"
                            value={state?.extra_setting?.withdrawal_setting?.service_fee_method ||''}
                            label={t(`merchant.serviceFeeMethod`)}
                            onChange={handleChange}
                            name="service_fee_method"
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.service_fee_method'] ? true : false}
                        >
                            <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                            <MenuItem value={'percent'} key={'percent'}>{t('merchant.percent')}</MenuItem>
                            <MenuItem value={'fixed'} key={'fixed'}>{t('merchant.fixed')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="withdraw_method">{t(`merchant.withdrawMethod`)}</InputLabel>
                        <Select
                            labelId="withdraw_method"
                            value={state?.extra_setting?.withdrawal_setting?.withdraw_method ||''}
                            label={t(`merchant.withdrawMethod`)}
                            onChange={handleChange}
                            name="withdraw_method"
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.withdraw_method'] ? true : false}
                        >
                            <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                            <MenuItem value={'bicpay'} key={'bicpay'}>{t('merchant.bicpay')}</MenuItem>
                            <MenuItem value={'local'} key={'local'}>{t('merchant.local')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="fee_type">{t(`merchant.feeType`)}</InputLabel>
                        <Select
                            labelId="fee_type"
                            value={state?.extra_setting?.withdrawal_setting?.fee_type ||''}
                            label={t(`merchant.feeType`)}
                            onChange={handleChange}
                            name="fee_type"
                            error={inputErrors && inputErrors['extra_setting.withdrawal_setting.feeType'] ? true : false}
                        >
                            <MenuItem value={0} disabled>--- {`${t('general.pleaseSelect')}`} --- </MenuItem>
                            <MenuItem value={'addition'} key={'addition'}>{t('merchant.addition')}</MenuItem>
                            <MenuItem value={'reduction'} key={'reduction'}>{t('merchant.reduction')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid> */}
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} md={12}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main, fontWeight: 'bold' }}>{t('merchant.displaySetting')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" style={{ marginBottom: 10, fontSize: 15 }}>{t(`wallets.display`)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state?.extra_setting?.display_setting?.show_member ? true : false}
                                    onChange={handleChange}
                                    name="show_member"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showMember`)}</span>}
                            style={{ marginRight: 20 }}
                        />

                    </div>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/ranks`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained"  className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default ExtraSetting;