import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { MenuItem, Box, Breadcrumbs, Button, Grid, Link, TextField, Typography , IconButton , FormControl } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Search } from '@mui/icons-material';

const WalletAdjustment = () => {
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        username: '',
        wallet_id: '',
        wallet_action: '',
        amount: '',
        remark: '',
        merchant_id: '',
    });
    const [merchant, setMerchant] = useState({});
    const [searchMerchant, setSearchMerchant] = useState('');

    const action = [{ code: "add", label: t(`wallets.add`) }, { code: "deduct", label: t(`wallets.deduct`) }];
    const [walletList, setWalletList] = useState([]);
    const isMountedRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();
    const selectedWallet = _.find(walletList, data => data.id === parseInt(state?.wallet_id))?.['extra_setting']?.['requirement_merchant_id'];
    let reqMerchant = (selectedWallet === 'request' || selectedWallet === 'both');

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`/wallets`).then(result => {
            if (isMountedRef.current && result.status) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        let newState = { ...state, [name]: value };
        if (name === 'merchant_id' && !reqMerchant) 
        {
            newState = { ...newState, merchant_id: '' };
        } 
        else if (name === 'wallet_id') 
        {
            newState.merchant_id = ''; 
        }
        setState(newState);
    }

    const handleSearchMerchartFunction = (event) =>{
        event.preventDefault();
        setState({...state , merchant_id: ''});
        setLoading(true);
        let params ={
            search_merchant : searchMerchant,
        }

        getUrl(`/search-merchant`, params).then(response => {
            setInputErrors([]);
            if (response.status) 
            {
                setMerchant(response.data);
            } 
            else 
            {
                addAlert("", response.message, 'error', '');
                setInputErrors(response.errors);
                setMerchant([]);
                addAlert("", t('error.userNotFound'), 'error', '');
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            let updateData = {
                username: state.username,
                wallet_id: state.wallet_id,
                wallet_action: state.wallet_action,
                amount: state.amount,
                remark: state.remark,
                merchant_id: state.merchant_id,
                audit_remark: confirmation.note,
            }
            postUrl(`wallets/admin-adjustment`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    setState({
                        username: '',
                        wallet_id: '',
                        wallet_action: '',
                        amount: '',
                        remark: ''
                    });
                    addAlert('', t('success.createSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.walletAdjustment`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/wallets`}>
                            {t(`title.wallets`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.walletAdjustment`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.name`)}
                            variant="outlined"
                            value={state.wallet_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_id ? inputErrors.wallet_id : ''}
                            error={inputErrors && inputErrors.wallet_id ? true : false}
                            name="wallet_id"
                            select
                            
                            onChange={handleChange}
                        >
                            <MenuItem key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </MenuItem>
                            {_.map(walletList, (value, key) => {
                                return (
                                    <MenuItem key={key} value={value.id}>
                                        {value.name[i18n.language] ? value.name[i18n.language] : value.name.en}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Box>
                </Grid>
                {/* MerchantId */}
                {
                    reqMerchant &&
                    (
                        <>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} component="form" onSubmit={handleSearchMerchartFunction} >
                                    <FormControl fullWidth  >
                                        <TextField
                                            label={t(`wallets.searchMerchant`)}
                                            variant="outlined"
                                            value={searchMerchant}
                                            onChange={(e) => setSearchMerchant(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.search_merchant ? inputErrors.search_merchant : t(`wallets.searchMerchantHelperMsg`)}
                                            error={inputErrors && inputErrors.search_merchant ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={handleSearchMerchartFunction} >
                                                        <Search />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={t(`wallets.merchantId`)}
                                        variant="outlined"
                                        value={state.merchant_id}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.merchant_id ? inputErrors.merchant_id : ''}
                                        error={inputErrors && inputErrors.merchant_id ? true : false}
                                        name="merchant_id"
                                        select
                                        
                                        onChange={handleChange}
                                        disabled={ _.size(merchant) > 0 ? false : true }
                                    >
                                        <MenuItem key='' value='' disabled>
                                            {t('general.pleaseSelect')}
                                        </MenuItem>
                                        {
                                            _.size(merchant) > 0 && 
                                            _.map(merchant, (value, key) => {
                                                return (
                                                    <MenuItem key={key} value={value.id}>
                                                        {value.name[i18n.language] ? value.name[i18n.language] : value.name.en}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Box>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state.wallet_action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_action ? inputErrors.wallet_action : ''}
                            error={inputErrors && inputErrors.wallet_action ? true : false}
                            name="wallet_action"
                            select
                            
                            onChange={handleChange}
                        >
                            <MenuItem key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </MenuItem>
                            {_.map(action, (value, key) => (
                                <MenuItem key={key} value={value.code}>
                                    {value.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.amount`)}
                            variant="outlined"
                            value={state.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            name="amount"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.remark`)}
                            variant="outlined"
                            value={state.remark}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            name="remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default WalletAdjustment