import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

import SearchComponent from '@components/SearchComponent';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [bonusListForSelect, setBonusListForSelect] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [selectedGenealogyType, setSelectedGenealogyType] = useState("individual");
    const [searchValue, setSearchValue] = useState('');

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType("individual");
        callApi()
        // handleSearchAndApiCall();
    };

    const handleSearchAndApiCall = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue ? searchValue : '',
            genealogyType: searchValue ? selectedGenealogyType : ''
        }
        getUrl(`/get-pairing-log`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", response.error, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };
    
    const handleSearch = () => {
        handleSearchAndApiCall();
    };
    
    useEffect(() => {
        handleSearchAndApiCall();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/get-pairing-log`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", response.error, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // useEffect(() => {
    //     setTableLoading(true);
    //     let apiData = {
    //         page: pagination.pageIndex + 1,
    //         per_page: pagination.pageSize,
    //         filters: columnFilters,
    //         sorting: sorting[0],
    //     }
    //     getUrl(`/get-pairing-log`, apiData).then(response => {
    //         setTableLoading(false);
    //         if (response.status) {
    //             setData(response.data.listing.data);
    //             setRowCount(response.data.listing.total);
    //         } else {
    //             addAlert("", t('error.contactSupport'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setTableLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    //     // eslint-disable-next-line
    // }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const columns = useMemo(() => ([
        {
            header: `${t(`commission.bonusDate`)}`,
            accessorKey: 'bdate',
        },
        {
            accessorKey: 'username',
            header: `${t('user.username')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => (row.vol1 ? row.vol1 : '-'),
            id: 'vol1',
            header: `${t('pairingLog.vol1')}`,
        },
        {
            accessorFn: (row) => (row.vol2 ? row.vol2 : '-'),
            id: 'vol2',
            header: `${t('pairingLog.vol2')}`,
        },
        {
            accessorFn: (row) => (row.paired_bv ? row.paired_bv : '-'),
            id: 'paired_bv',
            header: `${t('pairingLog.pairedBv')}`,
        },
        {
            accessorFn: (row) => (row.vol1_bef ? row.vol1_bef : '-'),
            id: 'vol1_bef',
            header: `${t('pairingLog.vol1Bef')}`,
        },
        {
            accessorFn: (row) => (row.vol2_bef ? row.vol2_bef : '-'),
            id: 'vol2_bef',
            header: `${t('pairingLog.vol2Bef')}`,
        },
        {
            accessorFn: (row) => (row.vol1_aft ? row.vol1_aft : '-'),
            id: 'vol1_aft',
            header: `${t('pairingLog.vol1Aft')}`,
        },
        {
            accessorFn: (row) => (row.vol2_aft ? row.vol2_aft : '-'),
            id: 'vol2_aft',
            header: `${t('pairingLog.vol2Aft')}`,
        },
        {
            accessorFn: (row) => (row.flushed ? row.flushed : '-'),
            id: 'flushed',
            header: `${t('commission.flushed')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.title.main }}><b>{t(`title.ReportPairingLog`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.ReportPairingLog`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <SearchComponent
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClearSearch={handleClearSearch}
                handleSearch={handleSearch}
                selectedGenealogyType={selectedGenealogyType}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
            />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'PairingLogList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing