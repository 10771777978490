import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

import { MenuItem, Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, FormControl, FormControlLabel, Switch, InputAdornment, Chip, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

const Detail = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({});
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [state, setState] = useState({
        code: '',
        name: { en: "", cn: "" },
        decimal: '',
        allowNegative: 0,
        rate: '',
        display_setting: { "showAdmin": 1, "showMember": 1, "showDashboard": 1, "showMerchant": 1 },
        requirement_merchant_id: ''
    });
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/wallet-add` ? "add" : "edit";

    const handleChange = ({ target }) => {
        const { name, value, checked } = target;
        if (name === 'allowNegative') {
            setState({ ...state, allowNegative: target.checked ? 1 : 0 });
        } else if (name === 'showAdmin' || name === 'showMember' || name === 'showDashboard' || name === 'showMerchant') {
            setState({
                ...state,
                display_setting: {
                    ...state.display_setting,
                    [name]: checked ? 1 : 0
                }
            });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    useEffect(() => {
        if (formType === 'edit' && id) {
            getUrl(`/wallets/${id}`).then(response => {
                if (response.status) {
                    setState({
                        code: response.data.code,
                        name: response.data.name,
                        decimal: response.data.decimal,
                        allowNegative: response.data.allow_negative,
                        rate: response.data.rate,
                        display_setting: response.data.display_setting || { "showAdmin": 0, "showMember": 0, "showDashboard": 0, "showMerchant": 0 },
                        requirement_merchant_id: response?.data?.extra_setting?.requirement_merchant_id
                    });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setState({
                code: '',
                name: { en: "", cn: "" },
                decimal: '',
                allowNegative: 0,
                rate: '',
                display_setting: { "showAdmin": 0, "showMember": 0, "showDashboard": 0, "showMerchant": 0 }
            });
        }
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            code: state.code,
            name: state.name,
            decimal: state.decimal,
            allow_negative: state.allowNegative ? 1 : 0,
            rate: state.rate,
            display_setting: {
                showAdmin: state.display_setting.showAdmin ? 1 : 0,
                showMember: state.display_setting.showMember ? 1 : 0,
                showDashboard: state.display_setting.showDashboard ? 1 : 0,
                showMerchant: state.display_setting.showMerchant ? 1 : 0,
            },
            requirement_merchant_id: state.requirement_merchant_id,
        }
        
        if (formType === 'edit' && id) {
            putUrl(`/wallets/${id}`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/wallets`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/wallet-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.wallet${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/wallets`}>
                            {t(`title.wallets`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.wallet${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="standard-basic"
                            label={t('wallets.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.title]: target.value } })}
                            value={state.name && state.name[fieldLang.title] ? state.name[fieldLang.title] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.decimal`)}
                            variant="outlined"
                            value={state.decimal}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.decimal ? inputErrors.decimal : ''}
                            error={inputErrors && inputErrors.decimal ? true : false}
                            name="decimal"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.rate`)}
                            variant="outlined"
                            value={state.rate}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.rate ? inputErrors.rate : ''}
                            error={inputErrors && inputErrors.rate ? true : false}
                            name="rate"
                            onChange={handleChange}
                        />
                        <Typography variant="caption" style={{ color: "gray", paddingLeft: 5 }}>{t('wallets.rateNote')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.requirementMerchantId`)}
                            variant="outlined"
                            value={state.requirement_merchant_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.requirement_merchant_id ? inputErrors.requirement_merchant_id : ''}
                            error={inputErrors && inputErrors.requirement_merchant_id ? true : false}
                            name="requirement_merchant_id"
                            select
                            
                            onChange={handleChange}
                        >
                            <MenuItem key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </MenuItem>
                            <MenuItem key='1' value='request' >
                                {t('general.request')}
                            </MenuItem>
                            <MenuItem key='2' value='noRequest' >
                                {t('general.noRequest')}
                            </MenuItem>
                            <MenuItem key='3' value='both' >
                                {t('general.both')}
                            </MenuItem>
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.allowNegative ? true : false}
                                        onChange={handleChange}
                                        name="allowNegative"
                                    />
                                }
                                labelPlacement="start"
                                label={t(`wallets.allowNegative`)}
                            />
                            {
                                inputErrors && inputErrors.allowNegative
                                    ?
                                    <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.allowNegative}</Typography>
                                    :
                                    null
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" style={{ marginBottom: 10, fontSize: 15 }}>{t(`wallets.display`)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.display_setting.showAdmin ? true : false}
                                    onChange={handleChange}
                                    name="showAdmin"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showAdmin`)}</span>}
                            style={{ marginRight: 20 }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.display_setting.showMember ? true : false}
                                    onChange={handleChange}
                                    name="showMember"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showMember`)}</span>}
                            style={{ marginRight: 20 }}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.display_setting.showMerchant ? true : false}
                                    onChange={handleChange}
                                    name="showMerchant"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showMerchant`)}</span>}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.display_setting.showDashboard ? true : false}
                                    onChange={handleChange}
                                    name="showDashboard"
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>{t(`wallets.showDashboard`)}</span>}
                        />
                    </div>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/wallets`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default Detail