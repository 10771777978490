import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BackToPrevious from '@layouts/BackToPrevious';
import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const Edit = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({});
    const [statusList, setStatusList] = useState({});
    const [state, setState] = useState({
        plan_id: '',
        plan_history_id: '',
        code: '',
        status: '',
    });
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/redeem-code-add` ? "add" : "edit";

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        getUrl(`/redeem-code/${id}`).then(response => {
            if (response.status) {
                setState({
                    plan_id: response.data.plan_id,
                    plan_history_id: response.data.plan_history_id ? response.data.plan_history_id : '',
                    code: response.data.code,
                    status: response.data.status,
                });
                setStatusList(response.status_list)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            ...state
        }
        putUrl(`/redeem-code/${id}`, updateData).then(response => {
            setLoading(false);
            const { status, errors, message } = response;
            if (status) {
                addAlert('', message || t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.redeemCode${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" component={RouterLink} to={`/redeem-codes`}>
                            {t(`title.redeemCode`)}
                        </Link>
                        <Typography >{t(`title.redeemCode${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`redeemCode.planId`)}
                            variant="outlined"
                            value={state.plan_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.plan_id ? inputErrors.plan_id : ''}
                            error={inputErrors && inputErrors.plan_id ? true : false}
                            name="plan_id"
                            readOnly
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`redeemCode.planHistoryId`)}
                            variant="outlined"
                            value={state.plan_history_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.plan_history_id ? inputErrors.plan_history_id : ''}
                            error={inputErrors && inputErrors.plan_history_id ? true : false}
                            name="plan_history_id"
                            readOnly
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`redeemCode.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 7, marginLeft: 7 }}>
                        <InputLabel id="status-label">{t(`redeemCode.status`)}</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={state.status}
                            label={t(`redeemCode.status`)}
                            onChange={handleChange}
                            name="status"
                            error={inputErrors && inputErrors.status ? true : false}
                        >
                            <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                            {
                                statusList &&
                                _.map(statusList, (status, scode) => {
                                    return (
                                        <MenuItem value={scode} key={scode}>{status[i18n.language]}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                        {
                            inputErrors && inputErrors?.status &&
                            <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                        }
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/redeem-code`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default Edit