import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { MenuItem, Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Search } from '@mui/icons-material';

const INITIAL_STATE = {
    searchMerchant: '',
    merchantId: '',
    profileAction: '',
    mpMerchant: '',
    lockValue: '',
    capLimit: '',
};

const MerchantProfileAdjustment = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [merchant, setMerchant] = useState([]);

    const action = [
        { code: "add", label: t(`wallets.add`) },
        { code: "deduct", label: t(`wallets.deduct`) }
    ];

    const handleChange = ({ target }) => {
        let { name, value } = target;

        if (name === 'mpMerchant' || name === 'lockValue' || name === 'capLimit') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }

        setState({ ...state, [name]: value });
    }

    const handleSearchMerchartFunction = (event) => {
        event.preventDefault();
        setState({ ...state, merchant_id: '' });
        setLoading(true);
        let params = {
            search_merchant: state?.searchMerchant,
        }

        getUrl(`/search-merchant`, params).then(response => {
            setInputErrors([]);
            if (response.status) {
                setMerchant(response.data);
            }
            else {
                addAlert("", response.message, 'error', '');
                setInputErrors(response.errors);
                setMerchant([]);
                addAlert("", t('error.userNotFound'), 'error', '');
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            merchant_id: state.merchantId,
            profile_action: state.profileAction,
            mp_merchant: state.mpMerchant,
            lock_value: state.lockValue,
            cap_limit: state.capLimit,
        }
        postUrl(`/trade-merchant-profile-adjustment`, updateData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState(INITIAL_STATE);
                setMerchant([]);
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', _.size(errors) > 0 ? t('error.createError') : response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.tradeMerchantProfileAdjustment`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/trade-merchant-profile`}>
                            {t(`title.tradeMerchantProfile`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.tradeMerchantProfileAdjustment`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} component="form" onSubmit={handleSearchMerchartFunction} >
                        <TextField
                            label={t(`wallets.searchMerchant`)}
                            variant="outlined"
                            value={state?.searchMerchant}
                            onChange={handleChange}
                            name="searchMerchant"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.search_merchant ? inputErrors.search_merchant : t(`wallets.searchMerchantHelperMsg`)}
                            error={inputErrors && inputErrors.search_merchant ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleSearchMerchartFunction} >
                                        <Search />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.name`)}
                            variant="outlined"
                            value={state?.merchantId}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.merchant_id ? inputErrors.merchant_id : ''}
                            error={inputErrors && inputErrors.merchant_id ? true : false}
                            name="merchantId"
                            onChange={handleChange}
                            select

                            disabled={_.size(merchant) > 0 ? false : true}
                        >
                            <MenuItem key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </MenuItem>
                            {
                                _.size(merchant) > 0 &&
                                _.map(merchant, (value, key) => {
                                    return (
                                        <MenuItem key={key} value={value.id}>
                                            {value.name[i18n.language] ? value.name[i18n.language] : value.name.en} ({value.code})
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state?.profileAction}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profile_action ? inputErrors.profile_action : ''}
                            error={inputErrors && inputErrors.profile_action ? true : false}
                            name="profileAction"
                            select

                            onChange={handleChange}
                        >
                            <MenuItem key='' value='' disabled>{t('general.pleaseSelect')}</MenuItem>
                            {_.map(action, (value, key) => (
                                <MenuItem key={key} value={value.code}>
                                    {value.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.mpMerchant`)}
                            variant="outlined"
                            value={state?.mpMerchant}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mp_merchant ? inputErrors.mp_merchant : ''}
                            error={inputErrors && inputErrors.mp_merchant ? true : false}
                            name="mpMerchant"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.lockValue`)}
                            variant="outlined"
                            value={state?.lockValue}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.lock_value ? inputErrors.lock_value : ''}
                            error={inputErrors && inputErrors.lock_value ? true : false}
                            name="lockValue"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.capLimit`)}
                            variant="outlined"
                            value={state?.capLimit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.cap_limit ? inputErrors.cap_limit : ''}
                            error={inputErrors && inputErrors.cap_limit ? true : false}
                            name="capLimit"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default MerchantProfileAdjustment