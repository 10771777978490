import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, FormHelperText, Grid, Divider, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography, Checkbox, ListItemText, ListItemIcon, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, stepButtonClasses, FormControlLabel, Switch } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Close } from '@mui/icons-material';


const INITIAL_STATE = {
    name: { en: "", cn: "" },
    bv: '',
    price: '',
    profitLimit: '',
    tradeLimit: '',
    image: '',
    description: { en: { title: "", subtitle: "" }, cn: { title: "", subtitle: "" } },
    service_fee: { type: "fixed", amount: 0 },
    rank_id: '',
    mrank_id: '',
    status: 1,
    total_distributions: '',
    percent: '',
    split_times: '',
    redeem_code: 0,
    token_point: '0.00',
    wallet_id: '',
    merchant_id: [],
    pointReward: [],
    pointRewardConfirm: [],
    paymentMethod: [],
    paymentMethodConfirm: [],
    eShareValue:'',
}

export default function Detail() {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [fieldLang, setFieldLang] = useState({ name: 'en', title: 'en', subtitle: 'en' });
    const [changeImg, setChangeImg] = useState(false);
    const [rankList, setRankList] = useState({});
    const [mrankList, setMRankList] = useState({});
    const [merchantList, setMerchantList] = useState({});
    const [walletList, setWalletList] = useState([]);
    const [defaultPayment, setDefaultPayment] = useState([]);
    const [pointRewardDialog, setPointRewardDialog] = useState({open:false, line:0, show:false});
    const [paymentMethodDialog, setPaymentMethodDialog] = useState({open:false, line:0, show:false});
    const [tradeDetail, setTradeDetail] = useState({});

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let { id } = useParams();
    let count = 0;
    const location = useLocation();
    const formType = location.pathname === `/plan-add` ? "add" : "edit";

    useEffect(() => {
        setLoading(true);
        if(id){
            getUrl(`/plans/${id}`).then(response => {
                const { data } = response;
                if (response.status) {
                    // let tempArr = data?.extra_setting?.payment_method;
                    // if (data?.extra_setting?.payment_method) {
                    //     _.map(data?.extra_setting?.payment_method, (details, key) => {
                    //         tempArr[key]['wallet_ids'] = _.map(details?.wallet_ids, id => {
                    //             let parsed = parseInt(id);
                    //             return parsed;
                    //         })
                    //     })
                    // }

                    setState({
                        name: data.name,
                        bv: data?.bv,
                        price: data?.price,
                        profitLimit: data?.profit_limit,
                        tradeLimit: data?.trade_limit,
                        image: data?.plan_logo,
                        description: data?.description,
                        service_fee: data?.service_fee,
                        rank_id: data?.rank_id ? data?.rank_id : '',
                        mrank_id: data?.mrank_id ? data?.mrank_id : '',
                        status: data?.status,
                        total_distributions: data?.roi?.total_distributions,
                        percent: data?.roi?.percent,
                        split_times: data?.extra_setting?.split_times,
                        merchant_id: data?.extra_setting?.merchant_id?.[[0]],
                        redeem_code: data?.extra_setting?.redeem_code?data?.extra_setting?.redeem_code:0,
                        eShareValue: data?.e_share_value,
                        pointReward: data?.extra_setting?.point_reward?data?.extra_setting?.point_reward:[],
                        pointRewardConfirm: data?.extra_setting?.point_reward?data?.extra_setting?.point_reward:[],
                        paymentMethod: data?.extra_setting?.payment_method?data?.extra_setting?.payment_method:[],
                        paymentMethodConfirm: data?.extra_setting?.payment_method?data?.extra_setting?.payment_method:[],
                    });
                    if(_.size(data?.extra_setting?.point_reward) > 0){
                        setPointRewardDialog({...pointRewardDialog,show:true, line :_.size(data?.extra_setting?.point_reward) })
                    }
                    if(_.size(data?.extra_setting?.payment_method) > 0){
                        setPaymentMethodDialog({...paymentMethodDialog,show:true, line :_.size(data?.extra_setting?.payment_method) })
                    }
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                // setLoading(false);
            });
        }
        // else{
        //     setState({
        //         name: { en: "", cn: "" },
        //         bv: '',
        //         price: '',
        //         profitLimit: '',
        //         image: '',
        //         description: { en: { title: "", subtitle: "" }, cn: { title: "", subtitle: "" } },
        //         service_fee: { type: "", amount: 0 },
        //         rank_id: 0,
        //         status: 1,
        //     });
        // }

        getUrl(`/active-ranks`).then(response => {
            const { data } = response;
            if (response.status) {
                setRankList(response.data);
                setMRankList(response.mrank);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        getUrl(`/admin/active-merchants`).then(response => {
            const { data } = response;
            if (response.status) {
                setMerchantList(response.data);
                setWalletList(response.wallets)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        getUrl(`/payment-methods`).then(response => {
            const { data } = response;
            let paymentArray = [] ;
            if (response.status) {
                _.map(response.data?.payment_methods, (payment_method , index)=>{
                    if(payment_method){
                        paymentArray.push({
                            "type" : "percent",
                            "wallet" :payment_method
                        })
                    }
                })
                if(id){
                    if(_.size(state['paymentMethod']) > 0 ||   _.size(state['paymentMethodConfirm']) > 0){
                        // setState({...state, "paymentMethod": state['paymentMethod'] , "paymentMethodConfirm" :state['paymentMethodConfirm']});
                    }else{
                        // setState({...state, "paymentMethod": paymentArray , "paymentMethodConfirm" :paymentArray});
                    }
                }else{
                    setState({...state, "paymentMethod": paymentArray , "paymentMethodConfirm" :paymentArray});
                }
                
                
                let length = state.pointRewardConfirm?Object.keys(response.data?.payment_methods).length:1;
                setPaymentMethodDialog({...paymentMethodDialog,show:true,line:length });
                setDefaultPayment(response.data?.payment_methods);
                setTradeDetail(data.trade_detail);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value, files, checked } = target;
        let split_name = name.split('.');
        if (split_name[0] === 'image') {
            setState({ ...state, image: files[0] });
            setChangeImg(true);
        } else if (split_name[0] === 'type' || split_name[0] === 'amount') {
            setState({ ...state, service_fee: { ...state.service_fee, [split_name[0]]: target.value } });
        } else if (split_name[0] === 'merchant_id') {
            setState({ ...state, [split_name[0]]: value });
        } else if (split_name[0] === 'pointReward') {
            setState({ ...state, [split_name[0]]: {
                ...state[split_name[0]], [split_name[1]]:{
                    ...state[split_name[0]][split_name[1]], [split_name[2]]:value
                }
            } });
        } else if (split_name[0] === 'paymentMethod') {
            if(split_name[2] === 'type'){
                setState({
                    ...state, [split_name[0]]: {
                        ...state[split_name[0]], [split_name[1]]: {
                            ...state[split_name[0]][split_name[1]], [split_name[2]]: value
                        }
                    }
                });
            }else if(split_name[2] === 'wallet'){
                let tempArr = state[split_name[0]][split_name[1]][split_name[2]];
                let keyExis = tempArr.hasOwnProperty(value);
                if(keyExis){
                    addAlert("", t('error.paymentMethodInvalid'), 'error', '');
                }else{
                    tempArr = {...tempArr, [value]: tempArr[split_name[3]]}
                    delete tempArr[split_name[3]]
                    setState({
                        ...state, [split_name[0]]: {
                            ...state[split_name[0]], [split_name[1]]: {
                                ...state[split_name[0]][split_name[1]], [split_name[2]]: tempArr
                            }
                        }
                    });
                }
            }else if(split_name[2] === 'amounts'){
                let tempArr = state[split_name[0]][split_name[1]].wallet;
                tempArr = {...tempArr, [split_name[3]]: value}
                setState({
                    ...state, [split_name[0]]: {
                        ...state[split_name[0]], [split_name[1]]: {
                            ...state[split_name[0]][split_name[1]], 'wallet': tempArr
                        }
                    }
                });
            }
        } else if (split_name[0] === 'redeem_code') {
            setState({ ...state, [split_name[0]]: checked ? 1 : 0 });
        } else {
            setState({ ...state, [split_name[0]]: value });
        }
    }

    {/* Point Rewards */}
    const handlePRDialogOpen = () =>{
        let length = state.pointRewardConfirm?Object.keys(state.pointRewardConfirm).length:1;
        setPointRewardDialog({...pointRewardDialog,open:true,line:length });
    }

    const handlePRDialogClose = (reset = false) =>{
        let length = state.pointRewardConfirm?Object.keys(state.pointRewardConfirm).length:1;
        setState({...state, pointReward : state.pointRewardConfirm});
        if(reset === true){
            if(id){
                setPointRewardDialog({ ...pointRewardDialog, open: false, line: 0, show: true });
                setState({...state, pointReward : [],pointRewardConfirm:[]});
            }else{
                setPointRewardDialog({ ...pointRewardDialog, open: false, line: 0, show: false });
                setState({...state, pointReward : [],pointRewardConfirm:[]});
            }
        }else{
            setPointRewardDialog({ ...pointRewardDialog, open: false, line: length});
        }

    }

    const handlePRNewLine = () =>{
        setPointRewardDialog({...pointRewardDialog,line: pointRewardDialog.line +1});
        setState({...state, pointReward :{
            ...state.pointReward,[pointRewardDialog.line]:{type:'fixed',wallet_id:1, amount:'1'}
        }});
    }

    const handlePRSave = () =>{
        setState({...state, pointRewardConfirm :state.pointReward});
        setPointRewardDialog({...pointRewardDialog,show: true, open: false});
    }

    const pointRewardRender = () => {
        let result = [] ;
        for (let i=0; i < pointRewardDialog.line; i++) {
            let name = `pointReward.`+[i];
            result.push(
                <Grid item xs={12} key={i}>
                    <Grid container spacing={2}>
                        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ color: theme.palette.gray.main }}>{i+1+'.'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.pointRewardType`)}</InputLabel>
                                    <Select
                                        value={state.pointReward[i]?.type? state.pointReward[i].type : "fixed"}
                                        label={t(`plans.pointRewardType`)}
                                        name={name+'.type'}
                                        onChange={handleChange}
                                        // error={inputErrors && inputErrors?.['service_fee.type'] ? true : false}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map([
                                            { 'code': 'fixed', 'label': t(`plans.typeFixed`) },
                                            { 'code': 'percent', 'label': t(`plans.typePercent`) }
                                        ], serviceFeeType => {
                                            return <MenuItem key={serviceFeeType.code} value={serviceFeeType.code}>{serviceFeeType.label}</MenuItem>
                                        })}
                                    </Select>
                                    {/* <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText> */}
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.walletType`)}</InputLabel>
                                    <Select
                                        value={state.pointReward[i]?.wallet_id? parseInt(state.pointReward[i].wallet_id) : 1}
                                        label={t(`plans.walletType`)}
                                        name={name+'.wallet_id'}
                                        onChange={handleChange}
                                        // error={inputErrors && inputErrors?.['service_fee.type'] ? true : false}
                                    >
                                        <MenuItem key={""} value={""} disabled>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(walletList, walletList => {
                                            return <MenuItem key={walletList.id} value={walletList.id}>{walletList.name[i18n.language]}</MenuItem>
                                        })}
                                    </Select>
                                    {/* <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText> */}
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`plans.pointRewardAmount`)}
                                    variant="outlined"
                                    value={state.pointReward[i]?.amount?state.pointReward[i].amount:'0.00'}
                                    InputLabelProps={{ shrink: true }}
                                    // helperText={inputErrors && inputErrors.trade_limit ? inputErrors.trade_limit : ''}
                                    // error={inputErrors && inputErrors.trade_limit ? true : false}
                                    name= {name+'.amount'}
                                    type="number"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return result
    }

    {/* Payment Methods */}
    const handlePMDialogOpen = () =>{
        let length = state.paymentMethodConfirm?Object.keys(state.paymentMethodConfirm).length:1;
        setPaymentMethodDialog({...paymentMethodDialog,open:true,line:length });
    }

    const handlePMDialogClose = (reset = false) =>{
        let length = state.paymentMethodConfirm?Object.keys(state.paymentMethodConfirm).length:1;
        setState({...state, paymentMethod : state.paymentMethodConfirm});
        if(reset === true){
            if(id){
                setPaymentMethodDialog({ ...paymentMethodDialog, open: false, line: 0, show: true });
                setState({...state, paymentMethod : [],paymentMethodConfirm:[]});
            }else{
                setPaymentMethodDialog({ ...paymentMethodDialog, open: false, line: 0, show: false });
                setState({...state, paymentMethod : [],paymentMethodConfirm:[]});
            }
        }else{
            setPaymentMethodDialog({ ...paymentMethodDialog, open: false, line: length});
        }
    }

    const handlePMNewLine = () =>{
        setPaymentMethodDialog({...paymentMethodDialog,line: paymentMethodDialog.line +1});
        setState({...state, paymentMethod :{
            ...state.paymentMethod,[paymentMethodDialog.line]:{type:'fixed',wallet:{1:0}}
        }});
    }

    const handlePMSave = () =>{
        setState({...state, paymentMethodConfirm :state.paymentMethod});
        setPaymentMethodDialog({...paymentMethodDialog,show: true, open: false});
    }

    const handlePMNewWallet = (value) => {
        let wallet_key  = _.filter(walletList, function (w){
            if(_.includes(Object.keys(state.paymentMethod[value].wallet), w.id.toString())){
                return false
            }else{
                return true
            }
        })
        setState({...state, paymentMethod :{
            ...state.paymentMethod, [value]:{
                ...state.paymentMethod?.[value], "wallet" :{
                    ...state.paymentMethod[value].wallet, [wallet_key[0]?wallet_key[0].id : "trade"]:0
                }
            }
        }});
    }

    const paymentMethodRender = () => {
        let result = [] ;
        for (let i=0; i < paymentMethodDialog.line; i++) {
            let name = `paymentMethod.` + [i];
            result.push(
                <Grid item xs={12} key={i}>
                    <Grid container spacing={2}>
                        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <Typography style={{ color: theme.palette.gray.main }}>{i + 1 + '.'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.paymentMethodType`)}</InputLabel>
                                    <Select
                                        value={state.paymentMethod?.[i]?.type ? state.paymentMethod[i].type : "fixed"}
                                        label={t(`plans.paymentMethodType`)}
                                        name={name + '.type'}
                                        onChange={handleChange}
                                        // error={inputErrors && inputErrors?.['payment_method.type'] ? true : false}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map([
                                            { 'code': 'fixed', 'label': t(`plans.typeFixed`) },
                                            { 'code': 'percent', 'label': t(`plans.typePercent`) }
                                        ], paymentMethodType => {
                                            return <MenuItem key={paymentMethodType.code} value={paymentMethodType.code}>{paymentMethodType.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            { _.size(tradeDetail) > 0 &&
                                _.map(state?.paymentMethod?.[i]?.wallet, (key ,wallet_id) => {
                                    let wallet = _.find(walletList, {id: wallet_id});
                                    return (
                                        <Grid container key={wallet_id} spacing={2}>
                                            <Grid item xs={6}>
                                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t(`plans.walletName`)}</InputLabel>
                                                        <Select
                                                            value={wallet_id ? wallet_id : 1}
                                                            label={t(`plans.walletName`)}
                                                            name={name + '.wallet.'+ wallet_id}
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem key={""} value={""} disabled>{t(`general.pleaseSelect`)}</MenuItem>
                                                            <MenuItem key={"trade"} value={"trade"}>{tradeDetail?.name[i18n.language]||''}</MenuItem>
                                                            {_.map(walletList, walletDetail => {
                                                                return <MenuItem key={walletDetail.id} value={walletDetail.id}>{walletDetail.name[i18n.language]}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                    <TextField
                                                        label={t(`plans.paymentMethodAmount`)}
                                                        variant="outlined"
                                                        value={key}
                                                        InputLabelProps={{ shrink: true }}
                                                        name={name + '.amounts.'+ wallet_id}
                                                        type="number"
                                                        onChange={handleChange}
                                                    />
                                                    
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 13 }}>
                            <Button variant='contained' fullWidth onClick={() => handlePMNewWallet(i)} disabled ={Object.keys(state.paymentMethod?.[i]?.wallet || {}).length >= Object.keys(walletList).length +1 ? true:false}>
                                {t('plans.addWallet')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return result
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors([]);
        let postData = {
            ...state,
            redeem_code: state.redeem_code ? 1 : 0,
            profit_limit: state.profitLimit,
            trade_limit: state.tradeLimit,
            point_reward: state.pointReward,
            point_reward_confirm: state.pointRewardConfirm,
            point_reward_save: pointRewardDialog.show,
            payment_method: state.paymentMethod,
            payment_method_confirm: state.paymentMethodConfirm,
            payment_method_save: paymentMethodDialog.show,
            e_share_value: state.eShareValue,
            image: formType === 'edit' ? (changeImg ? state.image : '') : state?.image,
            _method: formType === 'edit' ? 'put' : 'post',
        };
        setLoading(false);
        const formData = new FormData();
        buildFormData(formData, postData);
        if (formType === 'edit' && id) {
            postUrl(`/plans/${id}`, formData).then(response => {
                // setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    setChangeImg(false);
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                // setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            postUrl(`/plans`, formData).then(response => {
                // setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/plan-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                // setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        } 
    }
    
    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.title.main }}><b>{t(`title.plan${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div style={{ paddingBottom: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/plans`}>
                            {t(`title.plan`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.plan${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '2%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, name: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.name === value ? theme.palette.primary.main : '#efefef', color: fieldLang.name === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.name]: target.value } })}
                            value={state.name && state.name[fieldLang.name] ? state.name[fieldLang.name] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.bv`)}
                            variant="outlined"
                            value={state.bv}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bv ? inputErrors.bv : ''}
                            error={inputErrors && inputErrors.bv ? true : false}
                            name="bv"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.price`)}
                            variant="outlined"
                            value={state.price}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                            error={inputErrors && inputErrors.price ? true : false}
                            name="price"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={state.profitLimit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profitLimit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.tradeLimit`)}
                            variant="outlined"
                            value={state.tradeLimit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.trade_limit ? inputErrors.trade_limit : ''}
                            error={inputErrors && inputErrors.trade_limit ? true : false}
                            name="tradeLimit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.eShareValue`)}
                            variant="outlined"
                            value={state.eShareValue||''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.e_share_value ? inputErrors.e_share_value : ''}
                            error={inputErrors && inputErrors.e_share_value ? true : false}
                            name="eShareValue"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.percent`)}
                            variant="outlined"
                            value={state.percent}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.percent ? inputErrors.percent : ''}
                            error={inputErrors && inputErrors.percent ? true : false}
                            name="percent"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.total_distributions`)}
                            variant="outlined"
                            value={state.total_distributions}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.total_distributions ? inputErrors.total_distributions : ''}
                            error={inputErrors && inputErrors.total_distributions ? true : false}
                            name="total_distributions"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.status`)}</InputLabel>
                            <Select
                                value={state.status}
                                label={t(`plans.status`)}
                                name="status"
                                onChange={({ target }) => setState({ ...state, status: target.value })}
                            >
                                {_.map([1, 0], statusType => {
                                    return <MenuItem key={statusType} value={statusType}>{t(`plans.status_${statusType}`)}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.split_times`)}
                            variant="outlined"
                            value={state.split_times}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.split_times ? inputErrors.split_times : ''}
                            error={inputErrors && inputErrors.split_times ? true : false}
                            name="split_times"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.redeem_code == 1 ? true : false}
                                        onChange={handleChange}
                                        name="redeem_code"
                                    />
                                }
                                labelPlacement="start"
                                label={t(`plans.redeemCode`)}
                            />
                            {
                                inputErrors && inputErrors.redeem_code
                                    ?
                                    <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.redeem_code}</Typography>
                                    :
                                    null
                            }
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.imagePreview')}</b></Typography>
                        {
                            state?.image &&
                            <img src={_.size(state?.image?.file_name) > 0 ? state?.image?.file_name : URL.createObjectURL(state.image)} style={{ width: 121, height: 121 }} />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.image`)}
                            variant="outlined"
                            // value={state.image}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.image ? inputErrors.image : ''}
                            error={inputErrors && inputErrors.image ? true : false}
                            name="image"
                            type="file"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.payment')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ '& > :not(style)': { m: 1} }}  style={{cursor: 'pointer'}} onClick={handlePMDialogOpen}>
                        <Typography style={{ color: theme.palette.gray.main }}><b>{t('plans.edit')}</b></Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} margin={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <Typography><b>{t('plans.index')}</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography><b>{t('plans.paymentMethodType')}</b></Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography><b>{t('plans.paymentMethodWallets')}</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography><b>{t('plans.paymentMethodAmount')}</b></Typography>
                        </Grid>
                    </Grid>
                    {
                        _.size(state?.paymentMethodConfirm) > 0
                            ? paymentMethodDialog.show && _.size(walletList) > 0 &&
                            _.map(state.paymentMethodConfirm, (list, index) => {
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={1}>
                                            <Typography>
                                                {parseInt(index) + 1 + ' .'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                {list.type === 'fixed' ? t(`plans.typeFixed`) : list.type === 'percent' ? t(`plans.typePercent`) : 'Error!'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            {
                                                _.map(list?.wallet, (amount, wallet_id) => {
                                                    if (wallet_id === "trade") {
                                                        return (
                                                            <Typography key={wallet_id}>{_.size(tradeDetail) > 0 ? tradeDetail.name[i18n.language] : ''}</Typography>
                                                        )
                                                    } else {
                                                        return (
                                                            <Typography key={wallet_id}>{_.size(walletList) > 0 ? _.filter(walletList, { id: parseInt(wallet_id) })[0].name[i18n.language] : ''}</Typography>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            {
                                                _.map(list?.wallet, (amount, wallet_id) => {
                                                    let wallet = _.find(walletList, {id: list?.wallet_ids?.[wallet_id]});
                                                    if(wallet_id === "trade"){
                                                        return (
                                                            <Typography key={wallet_id}>{parseFloat(amount).toFixed(0)}</Typography>
                                                        )
                                                    }else{
                                                        return (
                                                            <Typography key={wallet_id}>{parseFloat(amount).toFixed(wallet?.decimal)}</Typography>
                                                        )
                                                    }
                                                    
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : <Grid container>
                                <Grid item xs={12}>
                                    <Typography><i>{t('plans.nothingShow')}</i></Typography>
                                </Grid>
                            </Grid>
                    }
                    {/* _.map(defaultPayment, (detail, idx) => {
                                count += 1;
                                return (
                                    <Grid container spacing={2} key={idx}>
                                        <Grid item xs={1}>
                                            <Typography>{parseInt(count) + ' .'}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>{t(`plans.typePercent`)}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            {
                                                _.map(detail, (amount, wallet_id) => {
                                                    if (wallet_id === "trade") {
                                                        return (
                                                            <Typography key={wallet_id}>{_.size(tradeDetail) > 0 ? tradeDetail.name[i18n.language] : ''}</Typography>
                                                        )
                                                    } else {
                                                        return (
                                                            <Typography key={wallet_id}>{_.size(walletList) > 0 ? _.filter(walletList, { id: parseInt(idx) })[0].name[i18n.language] : ''}</Typography>
                                                        )
                                                    }
                                                })}
                                        </Grid>   
                                        <Grid item xs={3}>                 
                                            {
                                                _.map(detail, (amount,wallet_id) => {
                                                    if(wallet_id === "trade"){
                                                        return (<Typography key={wallet_id}>{amount}</Typography>);
                                                    }else{
                                                        return (<Typography key={wallet_id}>{amount}</Typography>)
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }) */}
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.pointReward')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box sx={{ '& > :not(style)': { m: 1} }}  style={{cursor: 'pointer'}} onClick={handlePRDialogOpen}>
                        <Typography style={{ color: theme.palette.gray.main }}><b>{t('plans.edit')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} margin={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <Typography><b>{t('plans.index')}</b></Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography><b>{t('plans.walletName')}</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography><b>{t('plans.pointRewardType')}</b></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography><b>{t('plans.pointRewardAmount')}</b></Typography>
                        </Grid>
                    </Grid>
                    {
                        pointRewardDialog.show && _.size(walletList) > 0 ?
                            _.map(state.pointRewardConfirm, (list, index) => {
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={1}>
                                            <Typography>{parseInt(index) + 1 + ' .'}</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography>{_.size(walletList) > 0 ?_.filter(walletList, { id: parseInt(list.wallet_id) })[0].name[i18n.language]:''}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>{list.type === 'fixed'? t(`plans.typeFixed`) :list.type === 'percent'? t(`plans.typePercent`):'Error!'}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>{list.amount}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : <Grid container>
                                <Grid item xs={12}>
                                    <Typography><i>{t('plans.nothingShow')}</i></Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.description')} & {t('plans.otherSettings')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.title')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, description: { ...state.description, [fieldLang?.title]: { ...state.description?.[fieldLang.title], title: target.value } } })}
                            value={state.description && state.description?.[fieldLang.title]?.title ? state.description?.[fieldLang.title]?.title : ""}
                            helperText={inputErrors && (inputErrors['description'] || inputErrors['description.en.title'] || inputErrors['description.cn.title'])}
                            error={inputErrors && (inputErrors['description'] || inputErrors['description.en.title'] || inputErrors['description.cn.title']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.subtitle')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, subtitle: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.subtitle === value ? theme.palette.primary.main : '#efefef', color: fieldLang.subtitle === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, description: { ...state.description, [fieldLang.subtitle]: { ...state.description?.[fieldLang.subtitle], subtitle: target.value } } })}
                            value={state.description && state.description?.[fieldLang.subtitle]?.subtitle ? state.description?.[fieldLang.subtitle]?.subtitle : ""}
                            helperText={inputErrors && (inputErrors['description'] || inputErrors['description.en.subtitle'] || inputErrors['description.cn.subtitle'])}
                            error={inputErrors && (inputErrors['description'] || inputErrors['description.en.subtitle'] || inputErrors['description.cn.subtitle']) ? true : false}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.type`)}</InputLabel>
                            <Select
                                value={state.service_fee?.type || ""}
                                label={t(`plans.type`)}
                                name="type"
                                onChange={handleChange}
                                error={inputErrors && inputErrors?.['service_fee.type'] ? true : false}
                            >
                                <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                {_.map([
                                    { 'code': 'fixed', 'label': t(`plans.typeFixed`) },
                                    { 'code': 'percent', 'label': t(`plans.typePercent`) }
                                ], serviceFeeType => {
                                    return <MenuItem key={serviceFeeType.code} value={serviceFeeType.code}>{serviceFeeType.label}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.amount`)}
                            variant="outlined"
                            value={state?.service_fee?.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors?.['service_fee.amount'] ? inputErrors?.['service_fee.amount'] : ''}
                            error={inputErrors && inputErrors?.['service_fee.amount'] ? true : false}
                            name="amount"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{ state?.service_fee?.type === 'Percent' ? '%' : '' }</InputAdornment>,
                            }}
                            onChange={handleChange}
                        />
                        {
                            state?.service_fee?.type === 'percent' &&
                            <Typography variant="caption">{t('plans.calculateAmount', {value: (state.price*(state.service_fee?.amount/100) || 0).toFixed(2)})}</Typography>
                        }
                    </Box>
                </Grid>
                {
                    _.size(rankList) > 0
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.rankId`)}</InputLabel>
                                    <Select
                                        value={state.rank_id}
                                        label={t(`plans.rankId`)}
                                        name="rank_id"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(rankList, rank => {
                                            return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                {
                    _.size(mrankList) > 0
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.mrankId`)}</InputLabel>
                                    <Select
                                        value={state.mrank_id}
                                        label={t(`plans.mrankId`)}
                                        name="mrank_id"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(mrankList, rank => {
                                            return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                {
                    // _.size(merchantList) > 0
                    //     ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.merchant`)}</InputLabel>
                                    <Select
                                        value={state?.merchant_id}
                                        label={t(`plans.merchant`)}
                                        name="merchant_id"
                                        onChange={handleChange}
                                        error={inputErrors && inputErrors?.merchant_id ? true : false}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(merchantList, merchant => {
                                            return (
                                                <MenuItem key={merchant.id} value={merchant.id}>
                                                    {merchant?.name ? merchant?.name?.[i18n.language] : merchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}
                                                    &nbsp;({merchant?.code})
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.merchant_id ? inputErrors?.merchant_id : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        // :
                        // null
                }
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/plans`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
            
            {/* Point Rewards */}
            <Dialog
                open={pointRewardDialog.open}
                onClose={() => handlePRDialogClose(false)}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 0px 10px 0px' }}>
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.addPointReward')}</b></Typography>
                        <IconButton onClick={() => handlePRDialogClose(false)}>
                            <Close />
                        </IconButton>
                    </div>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {pointRewardRender()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth onClick={handlePRSave} disabled={state.pointReward && Object.keys(state.pointReward).length > 0? false:true}>
                                {t('button.save')}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='outlined' fullWidth onClick={() => handlePRDialogClose(true)} disabled={state.pointRewardConfirm && Object.keys(state.pointRewardConfirm).length > 0? false:true}>
                                {t('button.reset')}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth onClick={handlePRNewLine}>
                                {t('plans.newLine')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>

            {/* Payment Methods */}
            <Dialog
                open={paymentMethodDialog.open}
                onClose={() => handlePMDialogClose(false)}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 0px 10px 0px' }}>
                        <Typography style={{ color: theme.palette.title.main }}><b>{t('plans.addPaymentMethod')}</b></Typography>
                        <IconButton onClick={() => handlePMDialogClose(false)}>
                            <Close />
                        </IconButton>
                    </div>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {paymentMethodRender()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth onClick={handlePMSave} disabled={state.paymentMethod && Object.keys(state.paymentMethod).length > 0? false:true}>
                                {t('button.save')}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='outlined' fullWidth onClick={() => handlePMDialogClose(true)} disabled={state.paymentMethodConfirm && Object.keys(state.paymentMethodConfirm).length > 0? false:true}>
                                {t('button.reset')}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth onClick={handlePMNewLine}>
                                {t('plans.newLine')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        </>

    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))